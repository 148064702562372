import { getAppInfo } from "./appInfo";
import axios from "axios";

export const createShopifyUrl = async (redirectUrl) => {
  try {
    const {clientId, isProductionApp, env} = await getAppInfo();
    const response = await axios.get("/api/connections");
    const nonceToken = "123456";
    const context = await axios.get("/api/contexts");
    const {shopifyCustomApp} = context.data;
    let  shopIdentifier = response.data.brandSettings.storeShopifyUrl;
    if (!shopIdentifier) {
      throw new Error("Please set your Shopify Store URL first before connecting. You'll find it under >>> Brand Settings");
    }
    if (shopIdentifier) {
      shopIdentifier = shopIdentifier.includes("https://") ? shopIdentifier : `https://${shopIdentifier}`;
    }
    const url = window.location.href;
    const urlObject = new URL(url);
    const urlProtocol = urlObject.protocol;
    const urlHostname = urlObject.host;
    const oAuthRedirectUrlShopify = `${urlProtocol}//${urlHostname}${redirectUrl}`;
    let scope = "read_orders,read_all_orders,read_products,read_customers,read_draft_orders,write_orders,write_draft_orders,write_inventory,write_price_rules,read_price_rules,write_discounts,read_discounts,read_gift_cards,write_gift_cards,read_returns,write_returns";
    // adjust access scope for local development
    // Shopify does not allow access to scope 'read_all_orders' on sandbox apps
    if (env === 'development' || !isProductionApp) {
      scope = "read_orders,read_products,read_customers,read_draft_orders,write_orders,write_draft_orders,write_inventory,write_price_rules,read_price_rules,write_discounts,read_discounts,read_gift_cards,write_gift_cards,read_returns,write_returns";
    }
    if(context && shopifyCustomApp){
      scope = "read_orders,read_all_orders,read_products,read_customers,read_draft_orders,write_orders,write_draft_orders,write_inventory,read_gift_cards,write_gift_cards,write_price_rules,read_price_rules,write_discounts,read_discounts,read_gift_cards,write_gift_cards,read_returns,write_returns";
    }
    const shopifyUrl = shopIdentifier
      + `/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=`
      + oAuthRedirectUrlShopify
      + "&state="
      + nonceToken;
    return shopifyUrl;
  } catch (err) {
    console.log("Error loading your connection settings. Try again.", err)
  }
}