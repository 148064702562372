/**
 * Component for our Report showing all Label Cost
 *
 * @component
 */

import React from "react";
import Report from "../Report";
 
const columns = [
  {
    title: "Carrier",
    dataIndex: "labelRateProvider",
  },
  {
    title: "Rate",
    dataIndex: "labelRateServicelevelName",
  },
  {
    title: "Total Amount",
    dataIndex: "totalamount",
  },
  {
    title: "Average Amount",
    dataIndex: "averageamount",
  },
  {
    title: "Total Count",
    dataIndex: "totalcount",
  },
];
 
const exportHeaders = [
  {
    label: "Carrier",
    value: "labelRateProvider",
  },
  {
    label: "Rate",
    value: "labelRateServicelevelName",
  },
  {
    label: "Total Amount",
    value: "totalamount",
  },
  {
    label: "Average Amount",
    value: "averageamount",
  },
  {
    label: "Total Count",
    value: "totalcount",
  },
];
 
class LabelCost extends React.Component {
  render() {
    return (
      <Report
        url="/api/reports/label/cost"
        title="Label Cost of Returns"
        tooltip="This report shows label cost of Returns."
        columns={columns}
        reportName="label-cost"
        exportHeaders={exportHeaders}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        updateDate={this.props.updateDate}
      />
    );
  }
}
 
export default LabelCost;
 