/**
 * Status: beta / will be deprecated
 *
 * Component for our Refunds and Store-Credit
 * screen that is available in BETA to a few
 * customers.
 *
 * @component
 */

import {
  Alert,
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Icon,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
  message,
} from 'antd';
import axios from 'axios';
import React from 'react';

const { Text } = Typography;
const { Option } = Select;

class ReturnDetailsRefund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoading: true,
      return: undefined,
      products: undefined,
      refundTotal: undefined,
      refundTotalBeforeFees: undefined,
      refundTotalAfterFees: undefined,
      refundTotalAfterExchange: undefined,
      listOfGatewayNames: undefined,
      refundMaximumRefundable: undefined,
      refundCurrency: undefined,
      refundAmount: undefined,
      shippingCosts: undefined,
      currency: undefined,
      // store credit related
      multiCurrency: undefined,
      storeCurrency: undefined,
      exchangeRate: undefined,
      refundTotalPresentmentCurrency: undefined,
      refundLineItems: undefined,
      lineItemsOriginal: undefined,
      lineItems: undefined,
      lineItemAmount: undefined,
      lineItemTax: undefined,
      lineItemDiscount: undefined,
      lineItemsQuantity: undefined,
      lineItemsEmpty: undefined,
      shippingRefundFull: false,
      shippingAmount: 0.0,
      shippingCurrency: undefined,
      restockEnabled: false,
      restockType: 'no_restock',
      restockLocationId: undefined,
      note: null,
      notify: true,
      locations: undefined,
      shippingCostsKey: Date.now(),
      refundTotalKey: Date.now(),
      customRefundTotal: undefined,
      totalAfterFees: undefined,
      refundRequest: undefined,
      showStoreCreditDialog: false,
      showRefundDialog: true,
      errorCalculation: false,
      errorRefund: false,
      errorStoreCredit: false,
      successRefund: false,
      successStoreCredit: false,
      labelFee: undefined,
      restockingFee: undefined,
      disableRefundButton: false,
      submissionActive: false,
      restockingFeeType: 'fixed',
      totalBonusCredits: 0.0,
      totalBonusDiscount: 0.0,
      exchangeTotal: 0.0,
      exchangedQuantity: 0,
    };
  }

  async componentDidMount() {
    const showStoreCreditDialog = this.props.showStoreCreditDialog;
    if (showStoreCreditDialog) {
      this.toggleCreditAndRefundDialog();
    }

    try {
      const { returnId } = this.props;
      const response = await axios.post('/api/refunds', {
        returnId,
        isStoreCredit: this.props.showStoreCreditDialog,
        isRefund: this.props.showRefundDialog,
      });

      if (response.data.status === 'error') {
        message.error(
          'Error preparing refund, there seems to be an issue with the order. Please try again or contact us.',
          8
        );
        return this.props.handleCancel.bind(this);
      }

      const {
        refundSettingsRefundShippingCosts,
        refundSettingsRestock,
        refundSettingsRestockLocationId,
        refundSettingsRestockLocationName,
        refundSettingsNotificationsCheck,
      } = response.data.refundSettings;

      this.setState(
        {
          return: response.data.return,
          products: response.data.return.Products,
          lineItems: response.data.lineItems,
          lineItemsOriginal: response.data.lineItemsOriginal,
          currency: response.data.currency,
          labelFee: response.data.labelFee,
          restockingFee: response.data.restockingFee,
          restockingFeeType: response.data.return.restockingFeeType,
          shippingRefundFull: refundSettingsRefundShippingCosts,
          restockEnabled: refundSettingsRestock,
          restockType: refundSettingsRestock ? 'return' : 'no_restock',
          restockLocationId: refundSettingsRestock
            ? parseInt(refundSettingsRestockLocationId)
            : undefined,
          restockLocationName: refundSettingsRestock
            ? refundSettingsRestockLocationName
            : undefined,
          locations: refundSettingsRestock
            ? [
              {
                id: parseInt(refundSettingsRestockLocationId),
                name: refundSettingsRestockLocationName,
              },
            ]
            : undefined,
          notify: refundSettingsNotificationsCheck,
        },
        () => {
          this.calculateRefund();
        }
      );
    } catch (err) {
      message.error('Error finding return. Please try again.', 5);
    }
  }

  async calculateRefund() {
    const returnId = this.state.return.id;
    const requestedLineItems = this.state.lineItems;
    const {
      shippingAmount,
      shippingRefundFull,
      shippingCurrency,
      refundTotal,
    } = this.state;

    // calculate refund
    try {
      const response = await axios.post('/api/refunds/calculate', {
        returnId,
        isStoreCredit: this.props.showStoreCreditDialog,
        storeCreditType: this.props.storeCreditType,
        requestedLineItems,
        shippingAmount,
        shippingRefundFull,
        shippingCurrency,
        refundTotal,
      });

      if (response.data.status === 'error') {
        const errorDetails = response.data.error;
        message.error(errorDetails, 10);
      }

      let disableRefundButton = false;
      let invalidRefundTotal =
        response.data.refundTotal == null ||
        parseFloat(response.data?.refundTotal) === 0.0;

      if (invalidRefundTotal) {
        disableRefundButton = true;

        this.setState({
          ...response.data,
          restockEnabled: false,
          restockType: 'no_restock',
          restockLocationId: null,
          initialLoading: false,
          disableRefundButton,
          customRefundTotal: undefined,
          shippingCostsKey: Date.now(),
          refundTotalKey: Date.now(),
          exchangeTotal: response.data?.totalexchangeOrderPrice,
        });
      } else {
        if (
          parseFloat(response.data?.refundTotal) <=
          parseFloat(response.data?.totalexchangeOrderPrice)
        ) {
          disableRefundButton = true;
        }
        this.setState({
          ...response.data,
          initialLoading: false,
          disableRefundButton,
          customRefundTotal: undefined,
          shippingCostsKey: Date.now(),
          refundTotalKey: Date.now(),
          exchangeTotal: response.data.totalexchangeOrderPrice,
        });
      }
    } catch (err) {
      message.error('Error finding return. Please try again.', 5);
    }
  }

  async issueGiftCardOrDiscountCode() {
    const storeCreditType = this.props.storeCreditType;
    const returnId = this.state.return.id;
    const {
      lineItems,
      shippingRefundFull,
      shippingAmount,
      shippingCurrency,
      restockType,
      restockLocationId,
      note,
      notify,
      currency,
      refundRequest,
      customRefundTotal,
      refundTotal,
      refundTotalBeforeFees,
      refundTotalAfterExchange,
    } = this.state;

    const isStoreCredit = this.props.showStoreCreditDialog;

    // check for missing data
    const missingRestockLocation =
      restockType === 'return' && restockLocationId == null;
    if (missingRestockLocation) {
      return message.error(
        'Please select a restock location first. You can also reload restock locations or disable restocking.',
        8
      );
    }

    this.setState({
      submissionActive: true,
    });

    try {
      const response = await axios.post('/api/refunds/issueStoreCredit', {
        isStoreCredit,
        returnId,
        lineItems,
        shippingAmount,
        shippingRefundFull,
        shippingCurrency,
        customRefundTotal,
        refundTotal,
        refundTotalBeforeFees,
        restockType,
        restockLocationId,
        note,
        notify,
        currency,
        refundRequest,
        storeCreditType,
        refundTotalAfterExchange,
      });

      if (response.data.status === 'error') {
        this.setState({
          errorRefund: true,
          submissionActive: false,
        });
        return message.error(
          `Error issuing ${storeCreditType} . Please try again.`,
          5
        );
      }

      this.setState(
        {
          submissionActive: false,
          successRefund: true,
        },
        () => {
          message.success(`${storeCreditType} issued successfully.`, 5);

          this.props.handleGetReturn();
          this.props.handleCancel();
        }
      );
    } catch (err) {
      this.setState({
        submissionActive: false,
      });
      message.error('Error issuing refund. Please try again.', 5);
    }
  }

  async issueRefund() {
    const returnId = this.state.return.id;
    const {
      lineItems,
      shippingRefundFull,
      shippingAmount,
      shippingCurrency,
      restockType,
      restockLocationId,
      note,
      notify,
      currency,
      refundRequest,
      customRefundTotal,
      refundTotal,
      refundTotalBeforeFees,
      refundTotalAfterExchange,
    } = this.state;

    const isStoreCredit = this.props.showStoreCreditDialog;

    // check for missing data
    const missingRestockLocation =
      restockType === 'return' && restockLocationId == null;
    if (missingRestockLocation) {
      return message.error(
        'Please select a restock location first. You can also reload restock locations or disable restocking.',
        8
      );
    }

    this.setState({
      submissionActive: true,
    });

    // calculate refund
    try {
      const response = await axios.post('/api/refunds/issue', {
        isStoreCredit,
        returnId,
        lineItems,
        shippingAmount,
        shippingRefundFull,
        shippingCurrency,
        customRefundTotal,
        refundTotal,
        refundTotalBeforeFees,
        restockType,
        restockLocationId,
        note,
        notify,
        currency,
        refundRequest,
        refundTotalAfterExchange,
      });

      if (response.data.status === 'error') {
        this.setState({
          errorRefund: true,
          submissionActive: false,
        });
        return message.error('Error issuing refund. Please try again.', 5);
      }

      this.setState(
        {
          submissionActive: false,
          successRefund: true,
        },
        () => {
          if (isStoreCredit) {
            message.success('Store-Credit issued successfully.', 5);
          } else {
            message.success('Refund issued successfully.', 5);
          }

          this.props.handleGetReturn();
          this.props.handleCancel();
        }
      );
    } catch (err) {
      this.setState({
        submissionActive: false,
      });
      message.error('Error issuing refund. Please try again.', 5);
    }
  }

  updateLineItemQuantity = async (value, product) => {
    const lineItemId = product.lineItemId;
    const lineItemsEmpty = this.state.lineItemsEmpty;
    const availableQuantity = this.state.lineItemsOriginal[lineItemId]
      ? this.state.lineItemsOriginal[lineItemId].quantity
      : undefined;
    const invalidRequest = availableQuantity == null && value === 0;

    if (invalidRequest) {
      // save a query
      return true;
    }

    if (availableQuantity && value > availableQuantity) {
      return message.error('Please enter a valid quantity.', 6);
    }

    if (lineItemsEmpty) {
      // eslint-disable-next-line no-unused-vars
      this.setState(
        // eslint-disable-next-line no-unused-vars
        (prevState) => ({
          lineItems: {
            [lineItemId]: {
              line_item_id: lineItemId,
              quantity: value,
              bonusCredits: product.bonusCredits,
              bonusDiscount: product.bonusDiscount,
            },
          },
          restockEnabled: false,
          restockType: 'no_restock',
          restockLocationId: null,
        }),
        () => {
          this.calculateRefund();
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          lineItems: {
            ...prevState.lineItems,
            [lineItemId]: {
              ...prevState.lineItems[lineItemId],
              line_item_id: lineItemId,
              quantity: value,
              bonusCredits: product.bonusCredits,
              bonusDiscount: product.bonusDiscount,
            },
          },
        }),
        () => {
          this.calculateRefund();
        }
      );
    }
  };

  toggleShippingRefundFull = (e) => {
    const shippingRefundFull = e.target.checked;
    let shippingAmount;

    if (shippingRefundFull) {
      shippingAmount = this.state.shippingCosts.maximum_refundable;

      this.setState(
        {
          shippingRefundFull,
          shippingAmount,
        },
        () => {
          this.calculateRefund();
        }
      );
    } else {
      this.setState(
        {
          shippingRefundFull,
          shippingAmount: 0.0,
        },
        () => {
          this.calculateRefund();
        }
      );
    }
  };

  updateShippingCosts = (e) => {
    const shippingAmount = e;
    const shippingMaximumRefundable =
      this.state.shippingCosts.maximum_refundable;

    if (shippingAmount > shippingMaximumRefundable) {
      return message.error(
        'Please enter a valid amount. Make sure the amount is smaller than the maximum refundable shippin cost.',
        5
      );
    }

    this.setState(
      {
        shippingAmount: e,
      },
      () => {
        this.calculateRefund();
      }
    );
  };

  getTotalInStoreCurrency = async (updatedAmount) => {
    const { returnId } = this.props;

    try {
      const response = await axios.post('/api/refunds/calculate/currency', {
        returnId,
        updatedAmount,
      });

      const { refundTotal } = response.data;

      return refundTotal;
    } catch (err) {
      message.error(
        'There was an error updating the refund total for the Gift-Card.'
      );
    }
  };

  updateRefundTotal = async (updatedAmount, index) => {
    const matchingTransaction = this.state.refundRequest.transactions[index];
    // eslint-disable-next-line no-unused-vars
    const { gateway, currency, amount, maximum_refundable } =
      matchingTransaction;
    let disableRefundButton = false;
    let customRefundTotal = 0.0;
    // eslint-disable-next-line no-unused-vars
    let refundTotalAfterExchange;

    let sanitizedAmount = updatedAmount;

    // #1 - check for invalid amounts: exceeds max. refund amount
    const amountExceedsMaximumRefundable =
      parseFloat(updatedAmount) > parseFloat(maximum_refundable);
    if (amountExceedsMaximumRefundable) {
      sanitizedAmount = parseFloat(maximum_refundable);
      customRefundTotal = 0.0;
      disableRefundButton = true;
      message.error(
        'The amount cannot exceed the maximum refundable amount.',
        5
      );
    }

    // #2 - check for negative amounts
    const amountIsNegative = parseFloat(updatedAmount) < 0;
    if (amountIsNegative) {
      sanitizedAmount = 0.0;
      customRefundTotal = 0.0;
      disableRefundButton = true;
      message.error('Amount cannot be negative.', 5);
    }

    // #3 - empty amounts
    const amountIsEmpty = updatedAmount === '' || updatedAmount == null;
    if (amountIsEmpty) {
      sanitizedAmount = 0.0;
      customRefundTotal = 0.0;
      disableRefundButton = true;
      message.error('Enter a valid amount.', 5);
    }

    // #4 - invalid amounts
    const amountNotANumber = isNaN(updatedAmount);
    if (amountNotANumber) {
      sanitizedAmount = 0.0;
      // eslint-disable-next-line no-unused-vars
      customRefundTotal = 0.0;
      disableRefundButton = true;
      message.error('Enter a valid amount.', 5);
    }

    let updatedRefundRequest = { ...this.state.refundRequest };
    updatedRefundRequest.transactions[index].amount = sanitizedAmount;

    // update custom entered total
    let updatedRefundTotal = 0.0;
    updatedRefundRequest.transactions.map((transaction) => {
      updatedRefundTotal = updatedRefundTotal + parseFloat(transaction.amount);
    });

    const amountIsZero = updatedRefundTotal === 0;
    if (amountIsZero) {
      disableRefundButton = true;
    }

    if (!disableRefundButton) {
      // check for store credit amount
      const isStoreCredit = this.state.showStoreCreditDialog;
      const isMultiCurrency = this.state.multiCurrency;
      const calculateTotalInStoreCurrency = isStoreCredit && isMultiCurrency;

      if (calculateTotalInStoreCurrency) {
        const refundTotal = await this.getTotalInStoreCurrency(updatedAmount);

        this.setState({
          refundTotal,
          refundTotalAfterExchange: refundTotal,
        });
      } else if (isStoreCredit) {
        this.setState({
          refundTotal: updatedAmount,
          refundTotalAfterExchange: updatedAmount,
        });
      } else {
        this.setState({
          refundTotal: updatedAmount,
          refundTotalAfterExchange: updatedAmount,
        });
      }
    }

    this.setState({
      refundRequest: updatedRefundRequest,
      customRefundTotal: updatedRefundTotal,
      disableRefundButton,
    });
  };

  updateStoreCreditTotal = async (updatedAmount) => {
    const { refundMaximumRefundable } = this.state;
    let disableRefundButton = false;
    let refundTotal;
    let refundTotalAfterExchange;

    // #1 - check for invalid amounts: exceeds max. refund amount
    const amountExceedsMaximumRefundable =
      parseFloat(updatedAmount) > parseFloat(refundMaximumRefundable);
    if (amountExceedsMaximumRefundable) {
      disableRefundButton = true;
      message.error(
        'The amount cannot exceed the maximum refundable amount.',
        5
      );
    }

    // #2 - check for negative amounts
    const amountIsNegative = parseFloat(updatedAmount) < 0;
    if (amountIsNegative) {
      disableRefundButton = true;
      message.error('Amount cannot be negative.', 5);
    }

    // #3 - empty amounts
    const amountIsEmpty = updatedAmount === '' || updatedAmount == null;
    if (amountIsEmpty) {
      disableRefundButton = true;
      message.error('Enter a valid amount.', 5);
    }

    // #4 - invalid amounts
    const amountNotANumber = isNaN(updatedAmount);
    if (amountNotANumber) {
      disableRefundButton = true;
      message.error('Enter a valid amount.', 5);
    }

    if (!disableRefundButton) {
      const isMultiCurrency = this.state.multiCurrency;
      if (isMultiCurrency) {
        refundTotal = await this.getTotalInStoreCurrency(updatedAmount);
        refundTotalAfterExchange = refundTotal;

        this.setState({
          refundTotal,
          refundTotalAfterExchange,
        });
      } else {
        refundTotal = updatedAmount;
        refundTotalAfterExchange = updatedAmount;
      }
    }

    this.setState({
      refundTotal,
      refundTotalPresentmentCurrency: updatedAmount,
      disableRefundButton,
      refundTotalAfterExchange,
    });
  };

  toggleRestockItems = (e) => {
    const restockItems = e.target.checked;

    if (restockItems) {
      this.setState({
        restockEnabled: restockItems,
        restockType: 'return',
      });
      this.queryLocations();
    } else {
      this.setState({
        restockEnabled: restockItems,
        restockType: 'no_restock',
        restockLocationId: null,
      });
    }
  };

  toggleNotifyCustomer = (e) => {
    const notify = e.target.checked;

    this.setState({
      notify,
    });
  };

  queryLocations = async () => {
    try {
      const response = await axios.get('/api/refunds/locations');

      if (response.data.status === 'error') {
        return message.error('Error finding locations. Please try again.', 5);
      }

      const { locations } = response.data;
      let restockLocationId = undefined;
      if (this.state.restockLocationId) {
        restockLocationId = this.state.restockLocationId;
      } else if (locations != null && locations.length === 1) {
        restockLocationId = locations[0].id;
      }

      return this.setState({
        ...response.data,
        restockLocationId,
      });
    } catch (err) {
      message.error('Error finding locations. Please try again.', 5);
    }
  };

  updateLocationId = (e) => {
    const restockLocationId = e;

    this.setState({
      restockLocationId,
    });
  };

  updateRefundNote = (e) => {
    const note = e.target.value;

    this.setState({
      note,
    });
  };

  toggleCreditAndRefundDialog = () => {
    this.setState((prevState) => ({
      showStoreCreditDialog: !prevState.showStoreCreditDialog,
      showRefundDialog: !prevState.showRefundDialog,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="top">
          <Col span={24}>
            {this.state.successRefund ? (
              <React.Fragment>
                <Alert
                  message="Amount refunded successfully."
                  type="success"
                  showIcon
                  closeText={
                    <Button
                      onClick={this.props.handleCancel.bind(this)}
                      size="small"
                    >
                      Close
                    </Button>
                  }
                />
              </React.Fragment>
            ) : null}
            {this.state.successStoreCredit ? (
              <React.Fragment>
                <Alert
                  message="Store-Credit issued successfully."
                  type="success"
                  showIcon
                  closeText={
                    <Button
                      onClick={this.props.handleCancel.bind(this)}
                      size="small"
                    >
                      Close
                    </Button>
                  }
                />
              </React.Fragment>
            ) : null}
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="top" gutter={15}>
          {this.state.initialLoading ? (
            <Spin />
          ) : (
            <React.Fragment>
              <Spin
                spinning={this.state.submissionActive}
                size="large"
                tip="Issuing..."
              >
                <Col span={24}>
                  <Row type="flex" justify="space-around" align="top">
                    <Col span={24}>
                      <Card
                        title={
                          this.state.showRefundDialog
                            ? 'Refund'
                            : this.props.storeCreditType
                              ? this.props.storeCreditType === 'GIFT_CARD'
                                ? 'Gift Card'
                                : 'Discount Code'
                              : 'Store-Credit'
                        }
                        style={{ width: '100%' }}
                        // extra={
                        //   this.state.showRefundDialog
                        //   ? (
                        //       <Button size="small" icon="gift" onClick={this.toggleCreditAndRefundDialog.bind(this)}>Switch to Store-Credit</Button>
                        //     )
                        //   : (
                        //       <Button size="small" icon="dollar" onClick={this.toggleCreditAndRefundDialog.bind(this)}>Switch to Refund</Button>
                        //     )
                        // }
                      >
                        {this.state.products &&
                          this.state.products.map((product) => {
                            return (
                              <React.Fragment key={product.lineItemId}>
                                <Row
                                  type="flex"
                                  justify="space-around"
                                  align="top"
                                  key={product.lineItemId}
                                  className="u-rowGap--sm"
                                >
                                  <Col xs={24} sm={4}>
                                    <Avatar
                                      shape="square"
                                      size="large"
                                      src={product.imageUrl}
                                    />
                                  </Col>
                                  <Col xs={24} sm={12}>
                                    <p style={{ marginBottom: 0 }}>
                                      <strong>{product.productName}</strong>
                                    </p>
                                    <Text type="secondary">
                                      {product.variantName}
                                    </Text>
                                    <p style={{ marginBottom: 0 }}>
                                      {this.state.lineItems &&
                                      this.state.lineItems[
                                        product.lineItemId
                                      ] ? (
                                          this.state.lineItems[product.lineItemId]
                                            .discounted_price <
                                        this.state.lineItems[product.lineItemId]
                                          .price ? (
                                              <React.Fragment>
                                                <Text delete>
                                                  {
                                                    this.state.lineItems[
                                                      product.lineItemId
                                                    ].price
                                                  }{' '}
                                                  {this.state.currency}
                                                </Text>
                                                <span>
                                                  {
                                                    this.state.lineItems[
                                                      product.lineItemId
                                                    ].discounted_price
                                                  }{' '}
                                                  {this.state.currency}
                                                </span>
                                              </React.Fragment>
                                            ) : this.state.lineItems[
                                              product.lineItemId
                                            ].total_cart_discount_amount > 0 ? (
                                                <React.Fragment>
                                                  <span>
                                                    {(
                                                      this.state.lineItems[
                                                        product.lineItemId
                                                      ].price -
                                                this.state.lineItems[
                                                  product.lineItemId
                                                ].total_cart_discount_amount /
                                                  this.state.lineItems[
                                                    product.lineItemId
                                                  ].quantity
                                                    ).toFixed(2)}{' '}
                                                    {this.state.currency}
                                                  </span>
                                                  <span>
                                              (
                                                    <Text delete>
                                                      {
                                                        this.state.lineItems[
                                                          product.lineItemId
                                                        ].price
                                                      }{' '}
                                                      {this.state.currency}
                                                    </Text>
                                              )
                                                  </span>
                                                </React.Fragment>
                                              ) : (
                                          `${
                                            this.state.lineItems[
                                              product.lineItemId
                                            ].price
                                              ? this.state.lineItems[
                                                product.lineItemId
                                              ].price
                                              : '-'
                                          } ${this.state.currency}`
                                              )
                                        ) : (
                                        `${product.price.toFixed(2)} ${
                                          this.state.currency
                                        }`
                                        )}
                                    </p>
                                  </Col>
                                  <Col xs={24} sm={8}>
                                    <Row
                                      type="flex"
                                      align="middle"
                                      className="u-columnGap--sm"
                                    >
                                      <Col span={12}>
                                        <InputNumber
                                          defaultValue={
                                            this.state.lineItems &&
                                            this.state.lineItems[
                                              product.lineItemId
                                            ]
                                              ? this.state.lineItems[
                                                product.lineItemId
                                              ].quantity
                                              : 0
                                          }
                                          min={0}
                                          max={product.quantity}
                                          formatter={(value) =>
                                            `${value.replace(/\D/g, '')} / ${
                                              product.quantity
                                            }`
                                          }
                                          parser={(value) =>
                                            value
                                              .replace(
                                                ` / ${product.quantity}`,
                                                ''
                                              )
                                              .replace(/\D/g, '')
                                          }
                                          onChange={(value) =>
                                            this.updateLineItemQuantity(
                                              value,
                                              product
                                            )
                                          }
                                          style={{ width: '100%' }}
                                        />
                                      </Col>
                                      <Col span={8}>
                                        {this.state.lineItems &&
                                        this.state.lineItems[
                                          product.lineItemId
                                        ] ? (
                                            this.state.lineItems[
                                              product.lineItemId
                                            ].total_cart_discount_amount > 0 ? (
                                                <React.Fragment>
                                                  <p>
                                                    {
                                                      this.state.lineItems[
                                                        product.lineItemId
                                                      ].subtotal
                                                    }{' '}
                                                    {this.state.currency}
                                                  </p>
                                                </React.Fragment>
                                              ) : (
                                                <Text>
                                                  {
                                                    this.state.lineItems[
                                                      product.lineItemId
                                                    ].subtotal
                                                  }{' '}
                                                  {this.state.currency}
                                                </Text>
                                              )
                                          ) : (
                                          `0.00 ${this.state.currency}`
                                          )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>

                                {!product.exchanged &&
                                  product.resolution?.includes('Exchange') &&
                                  this.state.lineItems[product.lineItemId]
                                    ?.quantity > 0 && (
                                  <Row
                                    type="flex"
                                    justify="space-around"
                                    align="top"
                                    style={{ marginTop: '10px' }}
                                    key={product.lineItemId}
                                  >
                                    <Col span={2}>
                                      <Icon
                                        type="warning"
                                        style={{
                                          fontSize: '20px',
                                          color: '#F8D147',
                                        }}
                                      />
                                    </Col>
                                    <Col span={22}>
                                      <span>
                                          Please note that the exchange item
                                        {product.price -
                                            product.exchangeItemPrice ===
                                          0
                                          ? ' is same priced. '
                                          : product.price -
                                                product.exchangeItemPrice >
                                              0
                                            ? ` costs ${parseFloat(
                                              product.price -
                                                  product.exchangeItemPrice
                                            ).toFixed(2)} ${
                                                this.state.currency
                                              } less than this item.`
                                            : ` costs ${parseFloat(
                                              product.exchangeItemPrice -
                                                  product.price
                                            ).toFixed(2)} ${
                                                this.state.currency
                                              } more than this item.`}
                                        {product.price -
                                            product.exchangeItemPrice ===
                                          0
                                          ? ''
                                          : ` Once the exchange order gets created, the price here will be adjusted and your customer will ${
                                                product.price >
                                                product.exchangeItemPrice
                                                  ? `be owed ${
                                                      product.price -
                                                      product.exchangeItemPrice
                                                    }`
                                                  : `need to pay ${
                                                      product.exchangeItemPrice -
                                                      product.price
                                                    }`
                                              } ${this.state.currency}. `}
                                          We recommend to{' '}
                                        {this.props.storeCreditType
                                          ? this.props.storeCreditType ===
                                              'DISCOUNT_CODE'
                                            ? 'offer Discount Code'
                                            : 'offer Gift Card'
                                          : this.props.showRefundDialog
                                            ? 'Refund'
                                            : 'offer Store Credit'}{' '}
                                          after creating exchange order except
                                          if you're planning to{' '}
                                        {this.props.storeCreditType
                                          ? this.props.storeCreditType ===
                                              'DISCOUNT_CODE'
                                            ? 'offer Discount Code of'
                                            : 'offer Gift Card of'
                                          : this.props.showRefundDialog
                                            ? 'Refund'
                                            : 'offer Store Credit of'}{' '}
                                          the whole amount.
                                      </span>
                                    </Col>
                                  </Row>
                                )}

                                <Divider />
                              </React.Fragment>
                            );
                          })}

                        {this.state.lineItemsQuantity > 0 ? (
                          <Checkbox
                            checked={this.state.restockEnabled}
                            onChange={this.toggleRestockItems.bind(this)}
                          >
                            Restock{' '}
                            {this.state.lineItemsQuantity > 1
                              ? `${this.state.lineItemsQuantity} items`
                              : `${
                                  this.state.lineItemsQuantity
                                    ? this.state.lineItemsQuantity
                                    : '-'
                                } item`}
                          </Checkbox>
                        ) : null}

                        {this.state.lineItemsQuantity > 0 &&
                        this.state.restockType != null &&
                        this.state.restockType !== 'no_restock' &&
                        this.state.locations != null &&
                        this.state.locations.length > 0 ? (
                            <Select
                              defaultValue={
                                this.state && this.state.restockLocationId != null
                                  ? this.state.restockLocationId
                                  : undefined
                              }
                              style={{ width: '100%' }}
                              className="u-marginTop--sm"
                              onChange={this.updateLocationId.bind(this)}
                              placeholder="Select restock location"
                            >
                              {this.state.locations.map((location) => {
                                return (
                                  <Option value={location.id}>
                                    {location.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          ) : null}

                        {this.state.lineItemsQuantity > 0 ? (
                          <Tooltip title="Reload Locations">
                            <Button
                              shape="circle"
                              icon="reload"
                              size="small"
                              onClick={this.queryLocations.bind(this)}
                              className="u-marginTop--sm"
                            />
                          </Tooltip>
                        ) : null}
                      </Card>
                    </Col>
                  </Row>

                  {this.state.shippingCosts &&
                  this.state.shippingCosts.maximum_refundable > 0 ? (
                      <Row
                        type="flex"
                        justify="space-around"
                        align="top"
                        style={{ marginTop: 15 }}
                      >
                        <Col span={24}>
                          <Card title="Refund shipping" style={{ width: 600 }}>
                            <p>
                            Shipping rate:{' '}
                              <strong>
                                {this.state.shippingCosts
                                  ? `${this.state.shippingCosts.maximum_refundable} ${this.state.currency}`
                                  : '-'}
                              </strong>
                            </p>
                            <p>Refund amount</p>

                            <React.Fragment>
                              <InputNumber
                                key={this.state.shippingCostsKey}
                                defaultValue={
                                  this.state.shippingCosts
                                    ? this.state.shippingCosts.amount
                                    : 0.0
                                }
                                precision={2}
                                step={0.01}
                                min={0}
                                max={
                                  this.state.shippingCosts
                                    ? this.state.shippingCosts.maximum_refundable
                                    : 0.0
                                }
                                onChange={this.updateShippingCosts.bind(this)}
                              />
                              <Checkbox
                                style={{ marginLeft: 15 }}
                                checked={this.state.shippingRefundFull}
                                onChange={this.toggleShippingRefundFull.bind(
                                  this
                                )}
                              >
                              Refund full shipping cost
                              </Checkbox>
                            </React.Fragment>
                          </Card>
                        </Col>
                      </Row>
                    ) : null}

                  <Row
                    type="flex"
                    justify="space-around"
                    align="top"
                    style={{ marginTop: 15 }}
                  >
                    <Col span={24}>
                      <Card
                        title={
                          this.state.showRefundDialog
                            ? 'Reason for refund'
                            : 'Note for Store-Credit'
                        }
                        style={{ width: '100%' }}
                      >
                        <React.Fragment>
                          <Input onChange={this.updateRefundNote.bind(this)} />
                          <p>
                            <Text type="secondary">
                              Only you and other staff can see this reason.
                            </Text>
                          </p>
                        </React.Fragment>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col span={24} className="u-marginTop--md">
                  <Card title="Summary" style={{ width: '100%' }}>
                    {this.state.lineItemsEmpty ? (
                      <Row type="flex" justify="space-around" align="top">
                        <Col span={24}>
                          <p>No items selected.</p>
                        </Col>
                      </Row>
                    ) : (
                      <React.Fragment>
                        <Row type="flex" justify="space-around" align="top">
                          <Col span={16}>
                            <p style={{ marginBottom: 0 }}>Items subtotal</p>
                            <p>
                              <Text type="secondary">
                                {this.state.lineItemsQuantity > 1
                                  ? `${this.state.lineItemsQuantity} items`
                                  : `${
                                      this.state.lineItemsQuantity
                                        ? this.state.lineItemsQuantity
                                        : '-'
                                    } item`}
                              </Text>
                            </p>
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                            <p>
                              {this.state.lineItemAmount &&
                                this.state.lineItemAmount.toFixed(2)}{' '}
                              {this.state.currency}
                            </p>
                          </Col>
                        </Row>

                        {this.state.lineItemDiscount > 0 ? (
                          <Row type="flex" justify="space-around" align="top">
                            <Col span={16}>
                              <p style={{ marginBottom: 0 }}>Discount</p>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                              <p>
                                -
                                {this.state.lineItemDiscount &&
                                  this.state.lineItemDiscount.toFixed(2)}{' '}
                                {this.state.currency}
                              </p>
                            </Col>
                          </Row>
                        ) : null}

                        <Row type="flex" justify="space-around" align="top">
                          <Col span={16}>
                            <p>Tax (included)</p>
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                            <p>
                              {this.state.lineItemTax &&
                                this.state.lineItemTax.toFixed(2)}{' '}
                              {this.state.currency}
                            </p>
                          </Col>
                        </Row>

                        <Row type="flex" justify="space-around" align="top">
                          <Col span={16}>
                            <p>Shipping</p>
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                            <p>
                              {this.state.shippingCosts
                                ? `${this.state.shippingCosts.amount} ${this.state.currency}`
                                : '-'}
                            </p>
                          </Col>
                        </Row>

                        {this.props.storeCreditType === 'DISCOUNT_CODE' && (
                          <Row type="flex" justify="space-around" align="top">
                            <Col span={16}>
                              <p>Bonus Discount</p>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                              <p>
                                {this.state.totalBonusDiscount
                                  ? `${this.state.totalBonusDiscount.toFixed(
                                    2
                                  )} ${this.state.currency}`
                                  : `0.00 ${this.state.currency}`}
                              </p>
                            </Col>
                          </Row>
                        )}
                        {!this.props.storeCreditType &&
                          this.props.showStoreCreditDialog &&
                          !this.props.showRefundDialog && (
                          <Row type="flex" justify="space-around" align="top">
                            <Col span={16}>
                              <p>Bonus Credit</p>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                              <p>
                                {this.state.totalBonusCredits
                                  ? `${this.state.totalBonusCredits.toFixed(
                                    2
                                  )} ${this.state.currency}`
                                  : `0.00 ${this.state.currency}`}
                              </p>
                            </Col>
                          </Row>
                        )}
                        <Row type="flex" justify="space-around" align="top">
                          <Col span={16}>
                            {this.state.showRefundDialog ? (
                              <p>
                                <strong>Refund total</strong>
                              </p>
                            ) : (
                              <p>
                                <strong>Total</strong>
                              </p>
                            )}
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                            <p>
                              {this.state.refundTotalBeforeFees
                                ? `${parseFloat(
                                  this.state.refundTotalBeforeFees
                                ).toFixed(2)} ${this.state.refundCurrency}`
                                : '-'}
                            </p>
                          </Col>
                        </Row>

                        {this.state.labelFee ? (
                          <Row type="flex" justify="space-around" align="top">
                            <Col span={16}>
                              <p>Label fee:</p>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                              <p>
                                {this.state.labelFee
                                  ? `${parseFloat(this.state.labelFee).toFixed(
                                    2
                                  )} ${
                                      this.state.refundCurrency
                                        ? this.state.refundCurrency
                                        : ''
                                    }`
                                  : '-'}
                              </p>
                            </Col>
                          </Row>
                        ) : null}

                        {this.state.restockingFee ? (
                          <Row type="flex" justify="space-around" align="top">
                            <Col span={16}>
                              <p>
                                <Icon type="minus-circle" /> Restocking fee:
                              </p>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                              <p>
                                {this.state.restockingFee
                                  ? `${parseFloat(
                                    this.state.restockingFee
                                  ).toFixed(2)} ${
                                      this.state.restockingFeeType === 'fixed'
                                        ? this.state.refundCurrency
                                        : '%'
                                    }`
                                  : '-'}
                              </p>
                            </Col>
                          </Row>
                        ) : null}

                        {this.state.labelFee || this.state.restockingFee ? (
                          <Row type="flex" justify="space-around" align="top">
                            <Col span={16}>
                              {this.state.showRefundDialog ? (
                                <p>
                                  <strong>Refund total after fees</strong>
                                </p>
                              ) : (
                                <p>
                                  <strong>Total after fees</strong>
                                </p>
                              )}
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                              <p>
                                {this.state.refundTotal
                                  ? `${parseFloat(
                                    this.state.refundTotalAfterFees
                                  ).toFixed(2)} ${this.state.refundCurrency}`
                                  : '-'}
                              </p>
                            </Col>
                          </Row>
                        ) : null}
                      </React.Fragment>
                    )}

                    <Divider />

                    {this.state.exchangeTotal > 0 && (
                      <React.Fragment>
                        <Row>
                          <Col>
                            <p>
                              <strong>Exchange Adjustment</strong>
                            </p>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-around" align="top">
                          <Col span={16}>
                            <p style={{ marginBottom: 0 }}>Exchange Total</p>
                            <p>
                              <Text type="secondary">
                                {this.state.exchangedQuantity > 1
                                  ? `${this.state.exchangedQuantity} items`
                                  : `${
                                      this.state.exchangedQuantity
                                        ? this.state.exchangedQuantity
                                        : '-'
                                    } item`}
                              </Text>
                            </p>
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                            <p>
                              {this.state.exchangeTotal &&
                                this.state.exchangeTotal}{' '}
                              {this.state.currency}
                            </p>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-around" align="top">
                          <Col span={12}>
                            <p>
                              <strong>Remaining Total</strong>
                            </p>
                          </Col>
                          <Col span={12} style={{ textAlign: 'right' }}>
                            <p>
                              {this.state.refundTotalAfterExchange &&
                                this.state.refundTotalAfterExchange}{' '}
                              {this.state.currency}
                            </p>
                          </Col>
                        </Row>

                        {this.state.exchangedQuantity > 0 &&
                        parseFloat(this.state.refundTotal) <=
                          parseFloat(this.state.exchangeTotal) ? (
                            <Alert
                              message="The refund amount should be greater than the exchange total."
                              type="info"
                              showIcon
                            />
                          ) : null}
                      </React.Fragment>
                    )}

                    {this.state.exchangeTotal > 0 && (
                      <React.Fragment>
                        <Divider />
                      </React.Fragment>
                    )}

                    {this.state.showRefundDialog &&
                      this.state.refundRequest &&
                      this.state.refundRequest.transactions.length > 0 &&
                      this.state.refundRequest.transactions.map(
                        (transaction, index) => {
                          const {
                            gateway,
                            currency,
                            // eslint-disable-next-line no-unused-vars
                            amount,
                            maximum_refundable,
                          } = transaction;

                          return (
                            <React.Fragment>
                              <p>
                                <strong>Refund amount</strong>
                              </p>
                              <p>
                                {this.state.listOfGatewayNames
                                  ? this.state.listOfGatewayNames[gateway]
                                  : gateway}
                              </p>
                              <p>
                                <span>{currency} </span>
                                <InputNumber
                                  key={this.state.refundTotalKey}
                                  defaultValue={
                                    this.state.refundTotalAfterExchange
                                  }
                                  precision={2}
                                  step={0.01}
                                  min={0}
                                  max={
                                    maximum_refundable
                                      ? maximum_refundable
                                      : 0.0
                                  }
                                  onChange={(updatedAmount) =>
                                    this.updateRefundTotal(updatedAmount, index)
                                  }
                                  style={{ width: 200 }}
                                />
                              </p>
                              <p>
                                <Text type="secondary">
                                  {maximum_refundable
                                    ? `${parseFloat(maximum_refundable).toFixed(
                                      2
                                    )} ${currency}`
                                    : '-'}{' '}
                                  available for refund
                                </Text>
                              </p>
                            </React.Fragment>
                          );
                        }
                      )}

                    {this.state.showStoreCreditDialog &&
                    this.state.refundRequest &&
                    this.state.refundRequest.transactions.length > 0 ? (
                        <React.Fragment>
                          <p>
                            <strong>Store-Credit amount</strong>
                          </p>
                          <p>
                          Type:{' '}
                            {this.props.storeCreditType
                              ? this.props.storeCreditType === 'GIFT_CARD'
                                ? 'Gift Card'
                                : 'Discount Code'
                              : 'Gift Card'}
                          </p>
                          <p>
                            <span>{this.state.currency} </span>
                            <InputNumber
                              key={this.state.refundTotalKey}
                              defaultValue={this.state.refundTotalAfterExchange}
                              precision={2}
                              step={0.01}
                              min={0}
                              max={
                                this.state.refundMaximumRefundable
                                  ? this.state.refundMaximumRefundable
                                  : 0.0
                              }
                              onChange={(updatedAmount) =>
                                this.updateStoreCreditTotal(updatedAmount)
                              }
                              style={{ width: 200 }}
                            />
                          </p>
                          <p>
                            <Text type="secondary">
                              {this.state.refundMaximumRefundable
                                ? `${parseFloat(
                                  this.state.refundMaximumRefundable
                                ).toFixed(2)} ${this.state.currency}`
                                : '-'}{' '}
                            available
                            </Text>
                          </p>
                        </React.Fragment>
                      ) : null}

                    <Divider />

                    {this.state.customRefundTotal != null &&
                    parseFloat(this.state.customRefundTotal) !==
                      parseFloat(this.state.refundTotal) ? (
                        <Alert
                          message="The amount you’re refunding is different from the suggested refund total."
                          type="info"
                          showIcon
                        />
                      ) : null}

                    {this.state.showStoreCreditDialog &&
                    this.state.multiCurrency ? (
                        <React.Fragment>
                          <Row
                            type="flex"
                            justify="space-around"
                            align="top"
                            style={{ marginTop: 15, textAlign: 'center' }}
                          >
                            <Col span={24}>
                              <Alert
                                message={
                                  <span>
                                  Multi-Currency Order. Shopify issues
                                  Gift-Cards only in your store's home currency
                                  ({this.state.storeCurrency}).
                                  </span>
                                }
                                type="info"
                                showIcon
                              />
                            </Col>
                          </Row>
                          <Row
                            type="flex"
                            justify="space-around"
                            align="top"
                            style={{ marginTop: 15, textAlign: 'center' }}
                          >
                            <Col span={24}>
                              <Text type="secondary">
                              Exchange rate {this.state.currency}/
                                {this.state.storeCurrency}:{' '}
                                {this.state.exchangeRate}
                              </Text>
                            </Col>
                          </Row>
                        </React.Fragment>
                      ) : null}

                    <Row
                      type="flex"
                      justify="space-around"
                      align="top"
                      style={{ marginTop: 15, textAlign: 'center' }}
                    >
                      <Col span={24}>
                        {this.state.showRefundDialog ? (
                          <Button
                            type="primary"
                            onClick={this.issueRefund.bind(this)}
                            disabled={
                              this.state.disableRefundButton ||
                              this.state.submissionActive
                            }
                            block
                          >
                            {this.state.customRefundTotal != null ? (
                              <span>
                                Refund{' '}
                                {isNaN(this.state.customRefundTotal)
                                  ? ''
                                  : parseFloat(
                                    this.state.customRefundTotal
                                  ).toFixed(2)}{' '}
                                {this.state.refundCurrency}
                              </span>
                            ) : (
                              <span>
                                Refund{' '}
                                {isNaN(this.state.refundTotalAfterExchange)
                                  ? ''
                                  : parseFloat(
                                    this.state.refundTotalAfterExchange
                                  )}{' '}
                                {this.state.refundCurrency}
                              </span>
                            )}
                          </Button>
                        ) : this.props.storeCreditType ? (
                          this.props.storeCreditType === 'GIFT_CARD' ? (
                            <Button
                              type="primary"
                              onClick={this.issueGiftCardOrDiscountCode.bind(
                                this
                              )}
                              disabled={
                                this.state.disableRefundButton ||
                                this.state.submissionActive
                              }
                              block
                            >
                              Issue{' '}
                              {isNaN(this.state.refundTotalAfterExchange)
                                ? ''
                                : parseFloat(
                                  this.state.refundTotalAfterExchange
                                ).toFixed(2)}{' '}
                              {this.state.storeCurrency} as Gift Card
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              onClick={this.issueGiftCardOrDiscountCode.bind(
                                this
                              )}
                              disabled={
                                this.state.disableRefundButton ||
                                this.state.submissionActive
                              }
                              block
                            >
                              Issue{' '}
                              {isNaN(this.state.refundTotalAfterExchange)
                                ? ''
                                : parseFloat(
                                  this.state.refundTotalAfterExchange
                                ).toFixed(2)}{' '}
                              {this.state.storeCurrency} as Discount Code
                            </Button>
                          )
                        ) : (
                          <Button
                            type="primary"
                            onClick={this.issueRefund.bind(this)}
                            disabled={
                              this.state.disableRefundButton ||
                              this.state.submissionActive
                            }
                            block
                          >
                            Issue{' '}
                            {isNaN(this.state.refundTotalAfterExchange)
                              ? ''
                              : parseFloat(
                                this.state.refundTotalAfterExchange
                              ).toFixed(2)}{' '}
                            {this.state.storeCurrency} as Store-Credit
                          </Button>
                        )}
                      </Col>
                    </Row>

                    {this.state.showRefundDialog ? (
                      <Row
                        type="flex"
                        justify="space-around"
                        align="top"
                        style={{ marginTop: 15, textAlign: 'center' }}
                      >
                        <Col span={24}>
                          <p>
                            <Text type="secondary">
                              Refunded items will no longer be fulfillable.
                            </Text>
                          </p>
                        </Col>
                      </Row>
                    ) : null}

                    <Row
                      type="flex"
                      justify="space-around"
                      align="top"
                      style={{ marginTop: 15 }}
                    >
                      <Col span={24}>
                        {this.state.showRefundDialog ? (
                          <Checkbox
                            defaultChecked={this.state.notify}
                            disabled={this.state.disableRefundButton}
                            onChange={this.toggleNotifyCustomer.bind(this)}
                          >
                            Send a notification to the customer
                          </Checkbox>
                        ) : (
                          <p>
                            <Text type="secondary">
                              A notification is sent to the customer with the
                              Store-Credit code and amount.
                            </Text>
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Spin>
            </React.Fragment>
          )}
        </Row>
      </React.Fragment>
    );
  }
}

export default ReturnDetailsRefund;
