import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import { Helmet } from 'react-helmet';

import AuthRouter from '../../routes/Auth';
//styles
import '../../styles/App.css';
import Logo from '../../assets/Logo.png';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="LoginPage">
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-129175672-1"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-129175672-1', { 'anonymize_ip': true });
          `}
          </script>
        </Helmet>
        <div className="LoginPage__panel">
          <img
            className="LoginPage__blurShape LoginPage__blurShape--one"
            src="https://assets.reviews.io/img/richreturns/dashboard/login/bg-glow-1.png"
          />
          <img
            className="LoginPage__blurShape LoginPage__blurShape--two"
            src="https://assets.reviews.io/img/richreturns/dashboard/login/bg-glow-2.png"
          />
          <div className="panel__inner">
            <Link to="/" className='panel__logo'>
              <img src={Logo} className='logo__image' />
            </Link>
            <AuthRouter />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                flexDirection: 'row',
                columnGap: '10px',
                justifyContent: 'center',
              }}
            >
              <div>
                <a
                  href="https://www.richreturns.io/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy
                </a>
              </div>
              <div>
                <Divider type="vertical" />
              </div>
              <div>
                <a
                  href="https://www.richreturns.io/legal-imprint"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Imprint
                </a>
              </div>
              <div>
                <Divider type="vertical" />
              </div>
              <div>
                <a
                  href="https://www.richreturns.io/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms
                </a>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div className="LoginPage__graphic">
          <div
            className="graphic__bg"
            style={{
              backgroundImage:
                'url(https://assets.reviews.io/img/richreturns/dashboard/login/graphic-login-bg.jpg)',
            }}
          ></div>
          <div className="graphic__inner">
            <img
              className="graphic__mockup graphic__mockup--laptop"
              src="https://assets.reviews.io/img/richreturns/dashboard/login/graphic-rich-returns-dashboard.webp"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Auth;
