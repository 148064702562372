import React, { Component } from "react";
import SubscriptionContextProvider from "../contexts/SubscriptionContext";
import Dashboard from "../pages/dashboard/index";

class UserWrappedContext extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <SubscriptionContextProvider>
        <Dashboard />
      </SubscriptionContextProvider>
    );
  }
}

export default UserWrappedContext;
