/**
 * Component to build a redirect URL for the Shopify permission dialog.
 * This includes things like access-scopes we require. Then redirects
 * the user to that URL. If the user has already given this permission
 * they are immediately redirected back to use.
 * 
 * Implementation applies to our Shopify Public-App in the App-Store.
 * 
 * @component
 */

import React from "react";
import AuthenticationLoadingScreen from "../../../../../components/AuthenticationLoadingScreen"
import { getAppInfo } from "../../../../../helpers/appInfo";
class ShopifyStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  async componentDidMount() {
    const nonceToken = "123456";
    const url = window.location.href;
    const urlObject = new URL(url);
    const shopIdentifier = urlObject.searchParams.get("shop") || urlObject.searchParams.get("store");
    const urlProtocol = urlObject.protocol;
    const urlHostname = urlObject.host;
    const oAuthRedirectUrlShopify = `${urlProtocol}//${urlHostname}/dashboard/connect/shopifyappstore/confirm`;

    // Default to Public-App
    let clientId = "efdd8904c3999b79312362f88fc31059";
    let isProductionApp = false;
    let env = null;

    try {
      const info = await getAppInfo();
      env = info.env,
      isProductionApp = info.isProductionApp
      clientId = info.clientId
    } catch (err) {
      message.error("error fetching clientId of application");
    }

    let scope = "read_orders,read_all_orders,read_products,read_customers,read_draft_orders,write_orders,write_draft_orders,write_inventory,write_price_rules,read_price_rules,write_discounts,read_discounts,read_gift_cards,write_gift_cards,read_returns,write_returns";

    // adjust access scope for local development
    // Shopify does not allow access to scope 'read_all_orders' on sandbox apps
    if (env === 'development' || !isProductionApp) {
      scope = "read_orders,read_products,read_customers,read_draft_orders,write_orders,write_draft_orders,write_inventory,read_returns,write_returns";
    }

    const shopifyUrl =
      "https://"
      + shopIdentifier
      + `/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=`
      + oAuthRedirectUrlShopify
      + "&state="
      + nonceToken

    return window.location.href = shopifyUrl;
  }

  render() {
    return (
      <AuthenticationLoadingScreen />
    );
  }
}

export default ShopifyStore;
