/**
 * Component for the OAauth part of our Intercom.com integration.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import AuthenticationLoadingScreen from "../../../../../components/AuthenticationLoadingScreen"

class Intercom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);
    // Intercom
    const code = urlObject.searchParams.get("code");
    const connectionPartner = "intercom";
    const nonceToken = urlObject.searchParams.get("state");

    if (code == null) {
      return;
    }

    try {
      await axios.post("/api/integrations/intercom/auth", {
        code,
        connectionPartner,
        nonceToken,
      });
      this.setState({
        redirect: true,
      });
    } catch (err) {
      message.error("Error connecting your Intercom account.");
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard/connect/platform" />;
    }
    return(
      <AuthenticationLoadingScreen />
    );
  }
}

export default Intercom;
