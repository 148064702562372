import React, { Component } from "react";
import axios from "axios";
import { Form, Input, Row, Col, Select, Modal, Button, message } from "antd";
import { frequentlyUsedCountries, nonFrequentlyUsedCountries } from "../../../../../constants/constants";
const FormItem = Form.Item;
const Option = Select.Option

class BuyerAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buyerName: undefined,
      buyerCompany: undefined,
      buyerStreet1: undefined,
      buyerStreet2: undefined,
      buyerCountry: undefined,
      buyerCity: undefined,
      buyerState: undefined,
      buyerZip: undefined,
      buyerEmail: undefined,
      buyerPhone: undefined,
      buyerModalText: "Add Buyer's Address",
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get("/api/returnSettings");
      const data = response.data;

      if (!data) {
        return;
      }
      this.setState({
        ...response.data,
      });
      if (data.buyerEmail) {
        this.setState({
          buyerModalText: "Update Buyer's Address"
        });
      }
    } catch (err) {
      message.error("Error retrieving your return settings. Try again.", 5);
    }
  }

  getCountriesList = () => {
    let countriesList = [];
    frequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option value={country.code} key={`frequentlyUsedCountries${country.code}`}>{country.name}</Option>);
    });

    const disabledoption = <Option value="disabled" key={'disabled'} disabled={true}>---------</Option>;
    countriesList.push(disabledoption)
    nonFrequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option value={country.code} key={`nonFrequentlyUsedCountries${country.code}`}>{country.name}</Option>);
    });
    return countriesList;
  }

  handleCancel = () => {
    this.props.showAddressModal();
  };


  handleSubmitForm = async (e) => {
    try {
      e.preventDefault();
      this.props.form.validateFields(async (err, values) => {
        if (err) {
          this.props.buyerAddressStatus(false)
          return message.error("Please add the required fields and try again.", 5);
        }
        const response = await axios.post("/api/returnSettings/updateBuyerAddress", values);
        if (!response) {
          return message.error("Error, Unable to update buyer address, Please try again.", 5);
        }
        if (response.status === 200 && response.data.message === "Success") {
          this.props.buyerAddressStatus(true)
          this.setState({
            buyerModalText: "Update Buyer's Address"
          });
          return message.success("Buyer Address Updated Successfully.", 5)
        }
      });
    } catch (err) {
      console.log("Error Occured while updating buyer Address", err)
      message.error("Error, Unable to update buyer address, Please try again.", 5)
    }
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        {this.props.showBuyerAddressModal &&
          <Modal
            title={this.state.buyerModalText}
            visible={true}
            onCancel={this.handleCancel}
            footer={[
              <Button key="back" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={this.state.loading}
                onClick={this.handleSubmitForm}
              >
                Save
              </Button>
            ]}
          >
            <Form>
              <Row>
                <Col span={24}>
                  <FormItem>
                    {getFieldDecorator('buyerCountry', {
                      initialValue: this.state.buyerCountry,
                      rules: [{ required: true, message: "Please add the buyer's country" }]
                    })(
                      <Select
                        placeholder="Select country"
                      >
                        {this.getCountriesList()}
                      </Select>
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('buyerName', {
                      initialValue: this.state.buyerName,
                      rules: [{ required: true, message: "Please add the buyer's full name" }]
                    })(
                      <Input addonBefore="Full name" placeholder="Full name" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('buyerCompany', {
                      initialValue: this.state.buyerCompany,
                    })(
                      <Input addonBefore="Company name" placeholder="Company" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('buyerStreet1', {
                      initialValue: this.state.buyerStreet1,
                      rules: [{ required: true, message: "Please add the buyer's street" }]
                    })(
                      <Input addonBefore="Street1" placeholder="Street, Apartment, suite, floor, etc." />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('buyerStreet2', {
                      initialValue: this.state.buyerStreet2,
                    })(
                      <Input addonBefore="Street2" placeholder="(Optional)" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('buyerCity', {
                      initialValue: this.state.buyerCity,
                      rules: [{ required: true, message: "Please add the buyer's city" }]
                    })(
                      <Input addonBefore="City" placeholder="City" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('buyerState', {
                      initialValue: this.state.buyerState,
                      rules: [{ required: true, message: "Please add the buyer's state" }]
                    })(
                      <Input addonBefore="State" placeholder="State / Province / Region" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('buyerZip', {
                      initialValue: this.state.buyerZip,
                      rules: [{ required: true, message: "Please add the buyer's zip code" }]
                    })(
                      <Input addonBefore="Zip Code" placeholder="Zip Code" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('buyerPhone', {
                      initialValue: this.state.buyerPhone,
                    })(
                      <Input addonBefore="Phone number" placeholder="May be used to assist delivery" />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('buyerEmail', {
                      initialValue: this.state.buyerEmail,
                      rules: [{ required: true, message: "Please add the buyer's email" }]
                    })(
                      <Input addonBefore="E-Mail" placeholder="E-mail" />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Modal>
        }
      </React.Fragment>

    );

  }
};

const WrappedBuyerAddressForm = Form.create()(BuyerAddress);
export default WrappedBuyerAddressForm;