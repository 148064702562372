/**
 * Component for our Graph on the volume of Refunds.
 * 
 * @component
 */

import React from "react";
import VolumeGraph from "../VolumeGraph";
class RefundVolume extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <VolumeGraph
        url="/api/analytics/refund-volume-by-date"
        title="Refund Volume"
        tooltip="Shows the number of Refund items requested per day."
        config={{
          xField: "date",
          yField: "volume",
          xAxis: { tickCount: 5 },
          yAxis: { tickInterval: 1 },
          smooth: true,
        }}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        updateDate={this.props.updateDate}
      />
    );
  }
}
export default RefundVolume;
