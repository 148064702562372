/**
 * Component that is used in cases where a Shopify order
 * does not have an associated email address but only a 
 * phone number. In this case we detect the missing email
 * address and ask the customer to provide it.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Row, Col, Input, Card, Divider, Button, Icon, message } from "antd";
import { FormattedMessage } from "react-intl";


class AdditionalCustomerInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
      submitButtonDisabled: false,
    };
  }

  async componentDidMount() {
    // scroll to top of page
    window.scrollTo(0, 0);
  }

  async handleSubmit() {
    const { companyIdentifier, returnNumber } = this.props;

    this.setState({
      submitButtonDisabled: true,
    });

    if (this.state.email == null || this.state.email === "" || !this.state.email.includes("@")) {
      
      this.setState({
        submitButtonDisabled: false,
      });
  
      return message.error("Please enter a valid email address. Otherwise we'll not be able to process your return.", 5);
    }

    // clean email
    const email = this.state.email.trim();

    const response = await axios.post("/api/visitors/return/info", {
      companyIdentifier,
      returnNumber,   
      email,
    });

    if (response.data.status === "error") {
      return message.error("There was an error saving the email for your return. Please try again.", 5);
    }
    
    this.setState({
      submitButtonDisabled: false,
    });

    this.props.updateBrandSettings({
      ...response.data,
    });

    return this.props.history.push('/return/method');
  }

  updateEmail = (e) => {

    this.setState({
      email: e.target.value,
    });
  }

  render() {

    return(
      <React.Fragment>
        <Card
          className="textCenter"
          title={
            <FormattedMessage
              id="app.visitor.additionalCustomerInput.headline"
              description="Title of the optional step of the customer portal process ('Additional information')"
              defaultMessage="Complete your return"
              values={{
              }}
            />
          }
        >
          <Row type="flex" justify="center" align="middle">
            <Col>
              <Icon type="mail" style={{ fontSize: 50 }} />
            </Col>
          </Row>

          <Row type="flex" justify="center" align="middle">
            <Col xs={24} md={10}>
              <h4>Your original order is missing an email address</h4>
              <span>Where do we sent updates on the status of your return? Make sure to enter a valid email address. Otherwise we'll not be able to process your return.</span>
            </Col>
          </Row>

          <Row type="flex" justify="center" align="middle" style={{ marginTop: 20 }} >
            <Col xs={24} md={10}>
              <Input
                defaultValue={this.state.email}
                onChange={ this.updateEmail.bind(this) }
                addonBefore="My email: "
                placeholder="chris@gmail.com"
              />
            </Col>
          </Row>

          <Divider />

          <Row type="flex" justify="center" align="top">
            <Col>
              <Button
                onClick={this.handleSubmit.bind(this)}
                type="primary"
                disabled={this.state.submitButtonDisabled}
                style={{ backgroundColor: this.props.brandColorButton, borderColor: this.props.brandColorButton }}
              >
                <span style={{ color: this.props.brandColorButtonText, fontStyle: this.props.brandFontStyle }}>
                  <FormattedMessage
                    id="app.visitor.order.buttonSubmit"
                    description="Button to continue from step 'order' to 'resolution'"
                    defaultMessage="Continue"
                    values={{
                    }}
                  />
                </span>
              </Button>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}

export default AdditionalCustomerInput;
