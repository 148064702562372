/**
 * Component to display our Return Reason  Table.
 *
 * @component
 */

import { Button, Popconfirm, Row, Table, Tag } from "antd";
import React, { Component } from "react";
import { IconTray } from "../../../../../components/CustomReturnsIcons";

class ReturnReasonTable extends Component {
  constructor(props) {
    super(props);
  }

  getColumns = () => {
    const { featureMultilangReturnReasons } = this.props;
    const columns = [];

    if (featureMultilangReturnReasons) {
      columns.push(
        {
          title: "Return Reason",
          dataIndex: "reason",
          key: "reason",
        },
        {
          title: "Language",
          dataIndex: "defaultLanguage",
          key: "defaultLanguage",
          render: (defaultLanguage) => <Tag color="cyan">{defaultLanguage}</Tag>,
        },
        {
          title: "Translations",
          dataIndex: "translations",
          key: "translations",
          render: (translations) => translations.map(translation => <><Tag color="cyan">{translation.language}</Tag></>
          ),
        },
        {
          title: <span style={{marginLeft:15}}>Action</span>,
          key: "operation",
          render: (_, data) => this.renderActions(data),
        },
      );
    } else {
      columns.push(
        {
          title: "Return Reason",
          dataIndex: "reason",
          key: "reason",
        },
        {
          width: 200,
          title: <span>Action</span>,
          key: "operation",
          render: (_, data) => this.renderActions(data),
        },
      );
    }

    return columns;
  };

  renderActions = (data) => (
    <Row type="flex" className="u-rowGap--sm u-columnGap--sm">
      {this.props.featureMultilangReturnReasons &&
        <Button
          type="ghost" 
          onClick={() =>
            this.props.toggleReturnReasonInputModal("addTranslation", data)
          }
        >
          Add translation
        </Button>
      }
      <Button type="ghost" onClick={() => this.props.toggleReturnReasonInputModal("editReturnReason", data)}>
        Edit
      </Button>{" "}
      <Popconfirm
        title="Are you sure you want to delete this?"
        onConfirm={() => this.props.handleDelete("mainTableRowDeletion", data)}
        okText="Yes"
        cancelText="Cancel"
      >
        <Button type="ghost">Delete</Button>
      </Popconfirm>
    </Row>
  );

  render() {
    const expandedRowRender = (row) => {
      const nestedColumns = [
        {
          title: "Language",
          dataIndex: "language",
          key: "language",
          render: (language) => <Tag color="cyan">{language}</Tag>,
        },
        {
          title: "Translation",
          dataIndex: "translation",
          key: "translation",
        },
        {
          title: "Action",
          key: "operation",
          render: (value, data) => (
            <React.Fragment>
              <Button
                type="ghost"
                onClick={() =>
                  this.props.toggleReturnReasonInputModal(
                    "editTranslation",
                    data
                  )
                }
              >
                Edit
              </Button>{" "}
              <Popconfirm
                title="Are you sure you want to delete this ?"
                onConfirm={() =>
                  this.props.handleDelete("nestedTableRowDeletion", data)
                }
                okText="Yes"
                cancelText="Cancel"
              >
                <Button type="ghost">Delete</Button>
              </Popconfirm>
            </React.Fragment>
          ),
        },
      ];
      const filterSelectedRowData = this.props.returnReason.find(
        (data) => data.id === row.key
      );
      const nestedTableData = filterSelectedRowData.translations.map(
        (translation, index) => ({
          key: index,
          language: translation.language,
          translation: translation.translation,
          id: row.key,
        })
      );
      return (
        <div style={{ overflowX: 'auto' }}>
          <div style={{ minWidth: 600}}>
            <Table
              columns={nestedColumns}
              dataSource={nestedTableData}
              pagination={{ defaultPageSize: 5 }}
              locale={{
                emptyText: (
                  <div className="flex-row flex-middle-xxs flex-center-xxs">
                    <div className="flex-col-xxs-12">
                      <IconTray size="md" />
                    </div>
                    <div>
                      <p className="TextBody TextBody--xxxs">No Data</p>
                    </div>
                  </div>
                ),
              }}
            />
          </div>
        </div>
      );
    };

    const mainTableData = this.props.returnReason.map((data) => ({
      key: data.id,
      defaultLanguage: data.defaultLanguage,
      reason: data.reason,
      translations: data.translations,
      id: data.id,
    }));

    return (
      <div style={{ overflowX: 'auto' }}>
        <div style={{ minWidth: 600}}>
          <Table
            className="components-table-demo-nested"
            columns={this.getColumns()}
            expandedRowRender={this.props.featureMultilangReturnReasons && expandedRowRender}
            dataSource={mainTableData}
            pagination={{ defaultPageSize: 10 }}
            locale={{
              emptyText: (
                <div className="flex-row flex-middle-xxs flex-center-xxs">
                  <div className="flex-col-xxs-12">
                    <IconTray size="md" />
                  </div>
                  <div>
                    <p className="TextBody TextBody--xxxs">No Data</p>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </div>
    );
  }
}

export default ReturnReasonTable;