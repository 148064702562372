/**
 * Component for overview settings page of our Exchanges in the Dashboard.
 *
 * @component
 */

import { Tabs, Tag } from 'antd';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import Page from '../../../../components/Page';
import AdvancedExchanges from './AdvancedExchanges';
import ExchangeOrders from './ExchangeOrders';
import StoreWideExchanges from './StoreWideExchange';
import VariantExchanges from './VariantExchanges';
const { TabPane } = Tabs;

class ExchangeSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <PageButtonHeader headingTitle={'Exchanges'}></PageButtonHeader>
        <div className="ContentPanel  u-paddingTop--sm">
          <Tabs 
            defaultActiveKey={this.props.defaultActiveKey || '1'}
            animated={false}
            style={{ minHeight: 500 }}
          >
            <TabPane tab="Variant Exchanges" key="1">
              <Page component={VariantExchanges} title="Variant Exchanges" />
            </TabPane>
            <TabPane
              tab={
                <span>
                  Advanced Exchanges
                </span>
              }
              key="2"
            >
              <Page component={AdvancedExchanges} title="Advanced Exchanges" />
            </TabPane>
            <TabPane tab="Exchange Orders" key="3">
              <Page component={ExchangeOrders} title="Exchange Orders" />
            </TabPane>
            <TabPane
              tab={
                <span>
                  Store-Wide Exchanges
                </span>
              }
              key="4"
            >
              <Page
                component={StoreWideExchanges}
                title="Store-Wide Exchanges"
              />
            </TabPane>
          </Tabs>
        </div>
      </React.Fragment>
    );
  }
}

export default ExchangeSettings;
