/**
 * Component for the main page of our Return Portals
 * that lets a customer enter e.g. order-number and email
 * to start a return.
 * 
 * @component
 */

import { Button, Card, Col, Divider, Form, Input, Row, Spin, message } from "antd";
import axios from "axios";
import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import PoweredByButton from "../../components/PoweredByButton";
import "../../styles/App.css";

const FormItem = Form.Item;

class NewReturn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      redirect: false,
      orderSearchError: undefined,
      loading: false,
      translationId: undefined,
      translationDescription: undefined,
      translationDefaultMessage: undefined,
      enableReturnPolicy: undefined,
      returnPolicy: undefined,
      returnPolicyUrl: undefined
    };
  }

  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);
    const companyIdentifier = urlObject.searchParams.get("c");
    const workspace = urlObject.searchParams.get("workspace");
    const orderNumber = urlObject.searchParams.get("orderNumber");
    const orderEmail = urlObject.searchParams.get("orderEmail");
    const orderId = urlObject.searchParams.get("orderId");
    // Connection from Intercom-widget
    if (workspace != null) {
      return this.handleIntercom(urlObject);
    }

    if (companyIdentifier == null) {
      return;
      // TODO: redirect back?
    }

    try {
      const response = await axios.post("/api/visitors/settings/brand", {
        companyIdentifier,
      });
      if(response.data.error){
        return this.props.history.push('/error-404');
      }
      this.setState({
        ...response.data
      });
      if (response.data.brandFontStyle) {
        this.setState({
          brandClassName: response.data.brandFontStyle
        })
      }
      this.props.updateBrandSettings({
        ...response.data,
        companyIdentifier,
      });
    } catch (err) {
      message.error("Error loading return settings. Please try again.");
    }

    // deep linking of returns data in url
    if ((orderNumber != null || orderId != null) && orderEmail != null) {
      this.setState({
        loading: true,
      });

      await this.handleSubmit({
        orderNumber,
        orderEmail,
        orderId,
      });

      this.setState({
        loading: false,
      });
    }
  }

  // /return/new?workspace=vw6vp6l2&orderNumber=1151&orderEmail=Dimitri.Mante@developer-tools.shopifyapps.com
  async handleIntercom(urlObject) {
    const workspace = urlObject.searchParams.get("workspace");
    const orderNumber = urlObject.searchParams.get("orderNumber");
    const orderEmail = urlObject.searchParams.get("orderEmail");
    const referrer = urlObject.searchParams.get("referrer");
    this.setState({
      loading: true,
    });
    try {
      const response = await axios.post("/api/visitors/return/products", {
        workspace,
        orderNumber,
        orderEmail,
        referrer,
      })

      if (response.data.status === "error") {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 4);
      }
      this.props.updateBrandSettings({
        ...response.data,
        ...response.data.brandSettings,
      });
      this.setState({
        loading: false,
      });
      return this.props.history.push('/return/order');
    } catch (err) {
      return message.error("Error processing your request.", 4);
    }
  }

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      await this.handleSubmit(values)
    });
  }

  async handleSubmit(values) {
    try {
      this.setState({
        buttonLoading: true,
        orderSearchError: undefined
      });

      const companyIdentifier = this.props.companyIdentifier;
      let { orderNumber, orderEmail, orderId } = values;
      orderNumber = orderNumber?.trim();
      orderEmail = orderEmail?.trim();
      orderId = orderId?.trim();
      const response = await axios.post("/api/visitors/return/products", {
        orderNumber,
        orderEmail,
        companyIdentifier,
        orderId,
      });
      if (response?.data?.status === "error") {
        const { intl } = this.props;
        const errorMessage = response.data.error;
        const productDeleted = response.data.productDeleted;
        let translationId;
        let translationDescription;
        let translationDefaultMessage;
        if (errorMessage === "orderNotFound") {
          translationId = "app.visitor.newReturn.errorOrderNotFound";
          translationDescription = "Error when order is not found in Shopify.";
          translationDefaultMessage = "Cannot find your order.";
        } else if (errorMessage === "returnExists") {
          translationId = "app.visitor.newReturn.errorReturnExists";
          translationDescription = "Error when there is an existing return in the system.";
          translationDefaultMessage = "Return already exists.";
        } else if (errorMessage === "returnTooLate") {
          translationId = "app.visitor.newReturn.errorReturnTooLate";
          translationDescription = "Error when outside of allowed timeframe.";
          translationDefaultMessage = "Sorry this return is outside of our allowed timeframe for returning products.";
        } else if (errorMessage === "returnExcluded") {
          translationId = "app.visitor.newReturn.errorReturnExcluded";
          translationDescription = "Error when excluded from being returned by merchant.";
          translationDefaultMessage = "Sorry we're unable to offer you a return as your item(s) were on sale.";

          // new

        } else if (errorMessage === "returnUnfulfilled") {
          translationId = "app.visitor.newReturn.errorReturnUnfulfilled";
          translationDescription = "Error when excluded because unfulfilled order.";
          translationDefaultMessage = "It looks like your order has not shipped, yet. Therefore we cannot process a return for it.";
        } else if (errorMessage === "returnUnpaid") {
          translationId = "app.visitor.newReturn.errorReturnUnpaid";
          translationDescription = "Error when excluded because unpaid order.";
          translationDefaultMessage = "It looks like your order has not been paid, yet, or has already been refunded. Therefore we cannot process a return for it.";
        } else if (errorMessage === "returnCancelled") {
          translationId = "app.visitor.newReturn.errorReturnCancelled";
          translationDescription = "Error when excluded because cancelled order.";
          translationDefaultMessage = "It looks like your order has been cancelled. Therefore we cannot process a return for it.";
        } else if (errorMessage === "orderInvalid" && productDeleted) {
          translationId = "app.visitor.newReturn.errorProductDeleted";
          translationDescription = "Error when excluded because e.g. order contains deleted products.";
          translationDefaultMessage = `All of the products in your order have been removed from the store and cannot be returned through the portal. Contact the store’s support email for more details.`;
        } else if (errorMessage === "orderInvalid") {
          translationId = "app.visitor.newReturn.errorOrderInvalid";
          translationDescription = "Error when excluded because e.g. order contains deleted products.";
          translationDefaultMessage = "It looks like there was an issue requesting details for your order. Please contact customer support.";
        }
        message.error(
          intl.formatMessage({
            id: translationId,
            description: translationDescription,
            defaultMessage: translationDefaultMessage,
          })
          , 15);
        return this.setState({
          translationId,
          translationDescription,
          translationDefaultMessage,
          orderSearchError: errorMessage,
          buttonLoading: false,
        });
      }
      this.props.updateBrandSettings({
        ...response.data,
      });
      this.setState({
        orderSearchError: undefined
      });

      return this.props.history.push('/return/order');

    } catch(err) {
      message.error("Unable to search your order at the moment, Please confirm products are available in store.", 4);
      this.setState({
        buttonLoading: false,
      });
    }
  }

  render() {

    if (this.state.loading) {
      return <React.Fragment>
        <div style={{ textAlign: "center" }}>
          <Spin />
        </div>
      </React.Fragment>
    }
    const { getFieldDecorator } = this.props.form;

    const placeholderInput = defineMessages({
      orderNumber: {
        id: "app.visitor.newReturn.orderNumber",
        description: "Order number field",
        defaultMessage: "Order number",
      },
      email: {
        id: "app.visitor.newReturn.email",
        description: "Email address field",
        defaultMessage: "Email",
      },
    });

    const { formatMessage } = this.props.intl;
    const portalStyles = {
      color: this.props.brandColorText,
      fontStyle: this.props.brandFontStyle,
      fontSize: parseInt(this.props.brandFontSize) || 10
    }

    return (
      <React.Fragment>
        <Row type="flex" justify="center" align="top">
          <Col>
            <div>
              <img src={this.props.logoUrl} style={{ maxHeight: 60, marginBottom: 20 }}></img>
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle">
          <Col>
            {
              this.props.loadingSettings
                ? (
                  <Card
                    style={{ width: 280, height: 348, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
                    title=""
                  >
                    <Row type="flex" justify="center" align="middle">
                      <Col>
                        <Spin />
                      </Col>
                    </Row>
                  </Card>
                )
                : (
                  <Card
                    style={{ width: 280 }}
                    title={
                      this.props.companyIdentifier === "crimsonandcloverstudio" ?
                        "Returns" :
                        <span style={portalStyles}>
                          <FormattedMessage
                            id="app.visitor.newReturn.headline"
                            description="Title of the welcome dialog on the return-portal main page"
                            defaultMessage="Returns & Exchanges"
                          />
                        </span>
                    }
                    className="textCenter"
                  >
                    <Form onSubmit={this.handleFormSubmit.bind(this)}>
                      <FormItem>
                        {
                          getFieldDecorator("orderNumber", {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: <FormattedMessage
                                  id="app.visitor.newReturn.orderNumber.warning"
                                  description="Warning if user does not enter the order number"
                                  defaultMessage="Please enter your order number"
                                  values={{
                                  }}
                                />
                              },
                            ]
                          })(
                            <Input placeholder={formatMessage(placeholderInput.orderNumber)} className={`${this.state.brandClassName}`}/>
                          )
                        }
                      </FormItem>
                      <Divider />
                      <FormItem>
                        {
                          getFieldDecorator("orderEmail", {
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: <FormattedMessage
                                  id="app.visitor.newReturn.email.warning"
                                  description="Warning if user does not enter the email address"
                                  defaultMessage="Please enter your email address"
                                  values={{
                                  }}
                                />
                              },
                            ]
                          })(
                            <Input placeholder={formatMessage(placeholderInput.email)} className={`${this.state.brandClassName}`}/>
                          )
                        }
                      </FormItem>
                      <Divider />
                      <FormItem>
                        <Button type="primary" id="new-return" htmlType="submit" loading={this.state.buttonLoading} style={{ width: "100%", backgroundColor: this.props.brandColorButton, borderColor: this.props.brandColorButton }}>
                          <span style={{ color: this.props.brandColorButtonText, fontStyle: this.props.brandFontStyle }}>
                            <FormattedMessage
                              id="app.visitor.newReturn.buttonStartReturn"
                              description="Button to start a return"
                              defaultMessage="Start a return"
                              values={{
                              }}
                            />
                          </span>
                        </Button>
                      </FormItem>
                      <div className="u-marginTop--md">{
                        this.state.orderSearchError
                          ? (
                            <FormattedMessage
                              id={this.state.translationId}
                              description={this.state.translationDescription}
                              defaultMessage={this.state.translationDefaultMessage}
                              values={{
                              }}
                            />
                          )
                          : null
                      }
                      </div>
                    </Form>
                  </Card>
                )
            }

          </Col>
        </Row>
        {
          this.props.loadingSettings
            ? null
            : (
              <Row type="flex" justify="center" align="top" style={{ marginTop: 25 }}>
                <Col className="textCenter whiteFont">
                  <div style={portalStyles}>
                    <strong>
                      <FormattedMessage
                        id="app.visitor.newReturn.needHelpInfoText"
                        description="Infotext asking a customer if he/she needs additional help"
                        defaultMessage="Need help with a return?"
                        values={{

                        }}
                      />
                    </strong>
                  </div>
                  <div>
                    {
                      this.props.enableContactUrl
                        ? (
                          <React.Fragment>
                            <span style={portalStyles}>
                              <a
                                className="whiteLink"
                                style={{ textDecoration: "underline", ...portalStyles }}
                                href={this.props.contactUrl}
                                target="_blank" rel="noopener noreferrer"
                              >
                                <FormattedMessage
                                  id="app.visitor.newReturn.needHelpEmailText"
                                  description="Hint to contact customer service"
                                  defaultMessage="Contact us at"
                                  values={{
                                  }}
                                />
                              </a>
                            </span>
                          </React.Fragment>
                        )
                        : (
                          <React.Fragment>
                            <span style={portalStyles}>
                              <FormattedMessage
                                id="app.visitor.newReturn.needHelpEmailText"
                                description="Hint to contact customer service by email if necessary"
                                defaultMessage="Contact us at"
                                values={{
                                }}
                              /></span> <a className="whiteLink" style={{ textDecoration: "underline", ...portalStyles }} href={"mailto:" + this.props.contactEmail}>{this.props.contactEmail}</a>
                          </React.Fragment>
                        )
                    }
                  </div>
                </Col>
              </Row>
            )
        }
        {
          this.state.enableReturnPolicy ?
            (
              <Row type="flex" justify="center" align="top" style={{ marginTop: 25 }}>
                <Col className="textCenter whiteFont">
                  <div style={{ fontSize: 16, color: this.props.brandColorText }}></div>
                  <React.Fragment>
                    <span style={{ color: this.props.brandColorText }}>
                      <FormattedMessage
                        id="app.visitor.newReturn.banner"
                        description="Hint to enable/disable return policy banner"
                        defaultMessage=" "
                      />
                    </span><a
                      className="whiteLink"
                      style={{ textDecoration: "underline", color: this.props.brandColorText }}
                      href={this.state.returnPolicyUrl}
                      target="_blank" rel="noopener noreferrer"
                    >{this.state.returnPolicy}
                    </a>
                  </React.Fragment>
                </Col>
              </Row>
            )
            :
            null
        }
        <Row type="flex" justify="center" align="top">
          <Col>
            <PoweredByButton
              storeUrl={this.props.storeUrl}
              poweredByButtonEnabled={this.props.poweredByButtonEnabled}
            />
          </Col>
        </Row>

      </React.Fragment>
    );
  }
}

const WrappedNewReturn = Form.create()(NewReturn);
export default injectIntl(WrappedNewReturn);
