/**
 * Component to Feature-Gate certain parts of the Application.
 * Allows for embedding a YouTube video for better demonstration
 * of the feature in question.
 *
 * @component
 */

import { Alert, Button, Col, Row } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export class Upgrade extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        {this.props.videoId ? (
          <Row type="flex" align="top" style={{ textAlign: 'left', justifyContent: "center" }}>
            <Col>
              <div className='TextHeading TextHeading--sm'>See the feature in action.</div>
              {this.props.faqLink ? (
                <p>
                  Click{' '}
                  <a
                    href={this.props.faqLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>{' '}
                  for the most recent list of all available rules
                </p>
              ) : null}
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${this.props.videoId}?showinfo=0&autoplay=0&rel=0`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen="allowFullScreen"
              ></iframe>
            </Col>
          </Row>
        ) : this.props.devDocs ? (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <h1 className="u-PageHeader">Documentation</h1>

              <Alert
                message="Wanting to learn more about our API?"
                description={
                  <span>
                    Refer to our{' '}
                    <a
                      href="https://support.apphub.com/en/articles/6367662-introduction-to-our-api"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      developer documentation
                    </a>{' '}
                    to get up and running using our API.
                  </span>
                }
                type="info"
                showIcon
              />
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <h1 style={{ fontSize: 20 }}>{this.props.title}</h1>
              <div style={{ marginBottom: 0 }}>{this.props.description}</div>
              <Button
                type="ghost"
                style={{ width: 'fit-content', margin: "10px 0px" }}
              >
                <Link to="/dashboard/account/profile">Manage subscription</Link>
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Upgrade;
