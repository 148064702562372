/**
 * Component for the OAauth part of our Intercom.com integration.
 * 
 * @component
 */

import React, { Component } from 'react';
import axios from "axios";

class oauthRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
      worked: "NO"
    };
  }

  componentDidMount = async () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    const code = urlObject.searchParams.get("code");

    try {
      const response = await axios.post("/api/integrations/intercom/auth", {
        code,
      });
      this.setState({
        code,
        worked: response.data.status,
      });
    } catch (err) {
      // handle error
    }
  }

  render() {
    return (
      <div></div>
    )
  }
}

export default oauthRedirect;
