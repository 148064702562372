/**
 * Component for Exchange-Orders settings page in the Dashboard.
 *
 * @component
 */

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { IconTray } from '../../../../components/CustomReturnsIcons';
import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import InventoryStock from './components/InventoryStock';

const { Option } = Select;

const FormItem = Form.Item;
class SettingsExchanges extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      exchangesEnableVariants: undefined,
      exchangesEnableSamePrice: undefined,
      exchangesEnableOverselling: undefined,
      autoExchange: false,
      exchangeType: 'draftOrder',
      autoExchangeStatus: 'Pending',
      inventoryStock: false,
      inventoryStockModal: false,
      productTagNames: [],
      productTagList: [],
      reserveStockFields: [],
      indexOfField: undefined,
      loading: true,
      oldReturnSettings: {},
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data == null) {
        this.setState({ loading: false });
        return;
      }
      this.setState({
        ...response.data,
        loading: false,
      });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error retrieving your return settings. Try again.', 5);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      try {
        const response = await axios.post('/api/returnSettings/update', values);
        if (response.data.status === 'error') {
          return message.error(
            'Error updating your exchange settings. Please try again.',
            5
          );
        }
        trackFSEvent('Edit Exchange Orders', {
          feature: 'Exchanges',
          attachANoteToTheExchangeOrder:
            values.exchangeOrderNote || this.state.exchangeOrderNote || false,
          createAFreeExchangeOrder:
            values.exchangeOrderFreeExchange ||
            this.state.exchangeOrderFreeExchange ||
            false,
          restockOriginalItems:
            values.exchangeOrderRestock ||
            this.state.exchangeOrderRestock ||
            false,
          orderConfirmation:
            values.exchangeOrderSendConfirmationReceipt ||
            this.state.exchangeOrderSendConfirmationReceipt ||
            false,
          useTitleOfOriginalShippingMethod:
            values.exchangeOrderUseOriginalShippingTitle ||
            this.state.exchangeOrderUseOriginalShippingTitle ||
            false,
        });
        this.setState({
          ...response.data,
        });
        return message.success('Return settings successfully updated.', 5);
      } catch (err) {
        return message.error(
          'Error updating your exchange settings. Please try again.',
          5
        );
      }
    });
  };

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  };

  handleExchangeOrderType = (value) => {
    this.setState({
      exchangeType: value,
    });
  };

  handleAutoExchangeStatus = (value) => {
    this.setState({
      autoExchangeStatus: value,
    });
  };

  inventoryStockModalOpen = () => {
    this.setState({
      inventoryStockModal: true,
    });
  };

  inventoryStockModalClose = (oldReturnSettings) => {
    this.setState({
      ...oldReturnSettings,
      inventoryStockModal: false,
      indexOfField: undefined,
    });
  };

  updateState = (payload) => {
    this.setState({
      ...payload,
    });
  };

  deleteReserverField = async (field) => {
    let fields = this.state.reserveStockFields;
    let productList = this.state.productTagList;
    if (field?.variantId) {
      fields = fields.filter((value) => value.variantId !== field.variantId);
    } else {
      fields = fields.filter(
        (value) =>
          value.type !== 'Tag' ||
          !value.title.every((tag) => field.title.includes(tag))
      );
      productList = productList.filter((tag) => !field.title.includes(tag));
    }
    const payload = {
      reserveStockFields: fields,
      productTagList: productList,
    };
    try {
      const response = await axios.post('/api/returnSettings/update', payload);
      if (response.data.status === 'error') {
        return message.error(
          'Error updating your exchange settings. Please try again.',
          5
        );
      }
      this.setState({
        reserveStockFields: fields,
        productTagList: productList,
      });
      return message.success('Successfully deleted.', 5);
    } catch (err) {
      return message.error(
        `Error deleting the ${
          field.type === 'tag' ? 'tag' : 'product'
        }. Please try again.`,
        5
      );
    }
  };

  handleReserveEdit = (index) => {
    this.setState({
      indexOfField: index,
      inventoryStockModal: true,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    if (this.context.featureExchangeOrders === undefined) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    const columns = [
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Tags/Products',
        dataIndex: 'title',
        render: (title, payload) => {
          if (payload.type === 'Product') {
            return (
              <Tag
                color="gold"
                key={
                  payload.variantTitle
                    ? `${title}(${payload.variantTitle})`
                    : title
                }
                style={{ marginBottom: 3 }}
              >
                {payload.variantTitle
                  ? `${title}(${payload.variantTitle})`
                  : title}
              </Tag>
            );
          } else {
            return (
              <div
                style={{ display: 'flex', flexWrap: 'wrap', columnGap: '5px' }}
              >
                {title.map((value) => {
                  return (
                    <Tag color="gold" key={value} style={{ marginBottom: 10 }}>
                      {value}
                    </Tag>
                  );
                })}
              </div>
            );
          }
        },
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        align: 'center',
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        key: (text, record) => {
          if (record.variantId) {
            return `edit-${record.variantId}`;
          }
          return `edit-${text}`;
        },
        render: (text, record, index) => {
          return (
            <Button size="small" onClick={(e) => this.handleReserveEdit(index)}>
              Edit
            </Button>
          );
        },
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        key: (text, record) => {
          if (record.variantId) {
            return `delete-${record.variantId}`;
          }
          return `delete-${text}`;
        },
        render: (text, record) => {
          return (
            <Popconfirm
              title="Are you sure you want to delete.?"
              onConfirm={() => this.deleteReserverField(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small">Delete</Button>
            </Popconfirm>
          );
        },
      },
    ];

    const oldReturnSettings = {
      productTagList: this.state.productTagList,
      reserveStockFields: this.state.reserveStockFields,
    };

    return (
      <React.Fragment>
        {this.context.featureExchangeOrders ? (
          <React.Fragment>
            <Row
              type="flex"
              justify="space-around"
              align="top"
              className="u-marginBottom--lg u-marginTop--md"
            >
              <Col span={22}>
                <p>
                  Create Orders and Draft-Orders for Exchanges directly from Rich
                  Returns.
                </p>
              </Col>
            </Row>
            <Form onSubmit={this.handleSubmit}>
              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-rowGap--sm"
              >
                <Col xs={22} sm={22} md={22} lg={22}>
                  <h4>Attach a note to the Exchange Order</h4>
                  <span>
                    Adds or appends a note to the order. You can use ORDER_NAME,
                    RMA_NUMBER and RETURN_METHOD as placeholders to insert
                    dynamic values.
                  </span>
                </Col>
                <Col xs={22} sm={22} md={22} lg={22}>
                  <FormItem>
                    {getFieldDecorator(
                      'exchangeOrderNote',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.exchangeOrderNote}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
                {this.state.exchangeOrderNote && (
                  <React.Fragment>
                    <Col xs={22} sm={22} md={22} lg={22}>
                      <span>
                        This will attach a note to all exchange-orders.
                      </span>
                    </Col>
                    <Col xs={22} sm={22} md={22} lg={22} style={{display:'flex', justifyContent:'flex-start'}}>
                      
                      <FormItem>
                        {getFieldDecorator('exchangeOrderNoteValue', {
                          initialValue: this.state.exchangeOrderNoteValue,
                        })(
                          <Input
                            placeholder="Exchange for order ORDER_NAME / Return RMA_NUMBER."
                            disabled={!this.state.exchangeOrderNote}
                            style={{maxWidth:'440px'}}
                          />
                        )}
                      </FormItem>
                    </Col>
                  </React.Fragment>
                )}
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-rowGap--sm"
              >
                <Col xs={22} sm={22} md={22} lg={22}>
                  <h4>Create a Free Exchange Order</h4>
                  <span>
                    This will create an exchange-order with prices of line-items
                    set to '0.00'. For Draft-Orders we apply a 100% discount to
                    all line-items due to a limitation by Shopify. If you
                    disable this setting we will create exchange orders &
                    draft-orders with full price line-items, note that in this
                    case you will have to collect any outstanding balance
                    manually e.g. through an invoice of a draft-order.
                  </span>
                </Col>
                <Col xs={22} sm={22} md={22} lg={22}>
                  <FormItem>
                    {getFieldDecorator(
                      'exchangeOrderFreeExchange',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.exchangeOrderFreeExchange}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-rowGap--sm"
              >
                <Col xs={22} sm={22} md={22} lg={22}>
                  <h4>Restock original items</h4>
                  <span>
                    This will enable the checkbox in the dialog where the
                    exchange-order is created. Can be changed manually for each
                    exchange-order later.
                  </span>
                </Col>
                <Col xs={22} sm={22} md={22} lg={22}>
                  <FormItem>
                    {getFieldDecorator(
                      'exchangeOrderRestock',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.exchangeOrderRestock}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-rowGap--sm"
              >
                <Col xs={22} sm={22} md={22} lg={22}>
                  <h4>Order Confirmation</h4>
                  <span>
                    This will send your Shopify store's default order
                    confirmation email to the customer for the exchange order.
                    Note: this does not apply to draft-orders as
                    order-confirmations for draft-orders are sent out
                    automatically from Shopify when the actual order is created
                    later.
                  </span>
                </Col>
                <Col xs={22} sm={22} md={22} lg={22}>
                  <FormItem>
                    {getFieldDecorator(
                      'exchangeOrderSendConfirmationReceipt',
                      {}
                    )(
                      <Checkbox
                        checked={
                          this.state.exchangeOrderSendConfirmationReceipt
                        }
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>

              {/* <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>

                <Row type="flex" justify="space-around" align="middle" className="u-rowGap--sm">
                  <Col xs={22} sm={22} md={22} lg={22}>
                    <h4>Use Shopify's native restocking functionality</h4>
                    <p>Any items selected to be restocked will be restocked in the original order just like you would restock them through your Shopify admin dashboard. Restocked items are visible in the timeline of the order and also marked as restocked in any Shopify reports.</p>
                    <p>If this option is disabled we'll restock items by increasing your inventory directly and leave the original order untouched.</p>
                  </Col>
                  <Col xs={22} sm={22} md={22} lg={22}>
                    <FormItem>
                      {getFieldDecorator('exchangeOrderRestockViaRefund', {
                      })(
                      <Checkbox checked={ this.state.exchangeOrderRestockViaRefund } onChange={ (e) => this.handleChange(e) }>Enable</Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </Row> */}

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-rowGap--sm"
              >
                <Col xs={22} sm={22} md={22} lg={22}>
                  <h4>Use Title of Original Shipping Method</h4>
                  <span>
                    This will use the title of the shipping method from the
                    original order for the exchange order. If disabled the
                    default shipping title "Free Shipping" is applied.
                  </span>
                </Col>
                <Col xs={22} sm={22} md={22} lg={22}>
                  <FormItem>
                    {getFieldDecorator(
                      'exchangeOrderUseOriginalShippingTitle',
                      {}
                    )(
                      <Checkbox
                        checked={
                          this.state.exchangeOrderUseOriginalShippingTitle
                        }
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <React.Fragment>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-around"
                  align="middle"
                  className="u-rowGap--sm"
                >
                  <Col xs={22} sm={22} md={22} lg={22}>
                    <h4>Enable AutoExchange</h4>
                    <span>
                      Check this box if you want to automatically create an
                      exchange order for the return initiated by a customer.
                    </span>
                  </Col>
                  <Col xs={22} sm={22} md={22} lg={22}>
                    <FormItem>
                      {getFieldDecorator(
                        'autoExchange',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.autoExchange}
                          onChange={(e) => this.handleChange(e)}
                        >
                          Enable
                        </Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </React.Fragment>

              <React.Fragment>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-around"
                  align="middle"
                  className="u-rowGap--sm"
                >
                  <Col xs={22} sm={22} md={22} lg={22}>
                    <h4>Select order type</h4>
                    <span>
                      Select the order type while creating auto exchange for the
                      return initiated by a customer. i.e, Exchange Order, Draft
                      Order.
                    </span>
                  </Col>
                  <Col xs={22} sm={22} md={22} lg={22}>
                    <FormItem style={{maxWidth:'440px'}} label="Exchange type">
                      {getFieldDecorator('exchangeType', {
                        initialValue: this.state.exchangeType,
                      })(
                        <Select
                          onChange={this.handleExchangeOrderType.bind(this)}
                          disabled={!this.state.autoExchange}
                        >
                          <Option value="exchangeOrder" key="exchangeOrder">Exchange Order</Option>
                          <Option value="draftOrder" key="draftOrder">Draft Order</Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </React.Fragment>

              <Row
                type="flex"
                justify="space-around"
                align="middle"
                className="u-rowGap--sm u-marginTop--md"
              >
                <Col xs={22} sm={22} md={22} lg={22}>
                  <span>
                    Choose a return status for which the exchange order should
                    be created automatically.
                  </span>
                </Col>
                <Col xs={22} sm={22} md={22} lg={22}>
                  <FormItem style={{maxWidth:'440px'}} label="Select Status">
                    {getFieldDecorator('autoExchangeStatus', {
                      initialValue: this.state.autoExchangeStatus,
                    })(
                      <Select
                        disabled={!this.state.autoExchange}
                        onChange={this.handleAutoExchangeStatus.bind(this)}
                      >
                        <Option value="Pending" key="Pending">Pending</Option>
                        <Option value="Approved" key="Approved">Approved</Option>
                        <Option value="Shipped" key="Shipped">Shipped</Option>
                        <Option value="Received" key="Received">Received</Option>
                        <Option value="Resolved" key="Resolved">Resolved</Option>
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <React.Fragment>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-around"
                  align="middle"
                  className="u-rowGap--sm"
                >
                  <Col xs={22} sm={22} md={22} lg={22}>
                    <h4>Out of Stock</h4>
                    <span>
                      Mark an item from exchange menu as 'out of stock', if it's
                      quantity is less than the quantity of current item in the
                      return.
                    </span>
                  </Col>
                  <Col
                    xs={22}
                    sm={22}
                    md={22}
                    lg={22}
                    style={{
                      display: 'flex',
                      columnGap: '20px',
                      alignItems: 'center',
                    }}
                  >
                    <FormItem style={{ marginBottom: 'unset' }}>
                      {getFieldDecorator(
                        'inventoryStock',
                        {}
                      )(
                        <Checkbox
                          checked={this.state.inventoryStock}
                          onChange={(e) => this.handleChange(e)}
                        >
                          Enable
                        </Checkbox>
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </React.Fragment>

              {this.state.inventoryStock && (
                <Row
                  type="flex"
                  justify="space-around"
                  align="middle"
                  className="u-rowGap--sm"
                  style={{ marginTop: '20px' }}
                >
                  <Col span={22} className="u-marginBottom--sm">
                    <span>
                      Add the product by selecting the product tag or add
                      manually to mark the product as 'out of stock'. Enter the
                      product quantity at which the product should be marked as
                      'out of stock'.
                    </span>
                  </Col>
                  <Col span={22} className="u-marginBottom--md">
                    <Button
                      type="ghost"
                      style={{ width: '75px' }}
                      disabled={!this.state.inventoryStock}
                      onClick={this.inventoryStockModalOpen}
                    >
                      Add
                    </Button>
                  </Col>
                  <Col span={22}>
                    {this.state.reserveStockFields?.length > 0 && (
                      <div style={{ overflowX: 'auto' }}>
                        <div style={{ minWidth: 768 }}>
                          <Table
                            rowKey={(_, index) => index}
                            columns={columns}
                            dataSource={this.state.reserveStockFields}
                            locale={{
                              emptyText: (
                                <div className="flex-row flex-middle-xxs flex-center-xxs">
                                  <div className="flex-col-xxs-12">
                                    <IconTray size="md" />
                                  </div>
                                  <div>
                                    <p className="TextBody TextBody--xxxs">No Data</p>
                                  </div>
                                </div>
                              ),
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              )}

              {this.state.inventoryStockModal && (
                <InventoryStock
                  visible={this.state.inventoryStockModal}
                  onCancel={this.inventoryStockModalClose}
                  productTagNames={this.state.productTagNames}
                  productTagList={this.state.productTagList}
                  reserveStockFields={this.state.reserveStockFields}
                  updateState={this.updateState}
                  position={this.state.indexOfField}
                  oldReturnSettings={oldReturnSettings}
                />
              )}

              <Row
                type="flex"
                justify="space-around"
                align="top"
                className="u-marginTop--xlg"
              >
                <Col span={22}>
                  <FormItem>
                    <Row type="flex" justify="end" align="top">
                      <Button type="primary" htmlType="submit">
                        Save changes
                      </Button>
                    </Row>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </React.Fragment>
        ) : (
          <Upgrade
            title="A faster way to process Exchanges."
            description="Upgrade to the Premium Plan to create Exchange-Orders directly from your Rich Returns dashboard."
          />
        )}
      </React.Fragment>
    );
  }
}

const WrappedSettingsExchanges = Form.create()(SettingsExchanges);
export default WrappedSettingsExchanges;
