/**
 * Component for our Analytics section displaying customer-related Analytics data.
 *
 * @component
 */

import {
  Card,
  Col,
  Divider,
  Icon,
  message,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { IconTray } from '../../../../components/CustomReturnsIcons';
import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';

const { Option } = Select;

const { Text } = Typography;

class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      numberOfTotalReturns: '-',
      numberOfTotalProducts: '-',
      averageProductsPerReturn: '-',
      totalReturnValue: '-',
      averageReturnValue: '-',
      numberOfOrders: '-',
      returnRate: '-',
      currency: '-',
      numberOfCompletedReturns: '-',
      valueOfCompletedReturns: '-',
      numberOfRefundToOriginal: '-',
      valueOfRefundToOriginal: '-',
      rateOfRefundToOriginal: '-',
      numberOfRefundToExchange: '-',
      valueOfRefundToExchange: '-',
      rateOfRefundToExchange: '-',
      numberOfRefundToCredit: '-',
      valueOfRefundToCredit: '-',
      rateOfRefundToCredit: '-',
    };
  }

  static contextType = SubscriptionContext;

  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);
    const customerId = urlObject.searchParams.get('customerId');
    if (this.props.customerId || customerId) {
      try {
        const response = await axios.get(
          `/api/analytics/customers/${
            customerId ? customerId : this.props.customerId
          }`
        );

        if (customerId) {
          const { shippingNameEmailAddress } = response.data;
          this.props.updateCustomerDetails(
            customerId,
            shippingNameEmailAddress
          );
        }
        return this.setState({
          ...response.data,
          loading: false,
        });
      } catch (err) {
        return message.error(
          'something went wrong in getting the customer details',
          5
        );
      }
    }
  }

  render() {
    const onChange = async (value) => {
      this.setState({
        loading: true,
      });
      try {
        const response = await axios.get(`/api/analytics/customers/${value}`);
        this.props.updateCustomerDetails(value);
        return this.setState({
          ...response.data,
          loading: false,
        });
      } catch (err) {
        this.setState({
          loading: false,
        });
        return message.error(
          'something went wrong in getting the customer details',
          5
        );
      }
    };

    if(this.state.loading || this.context.featureAnalyticsAdvanced === undefined) {
      return null;
    }
    return (
      <React.Fragment>
        {this.context.featureAnalyticsAdvanced ? (
          <React.Fragment>
            <h1 className="TextHeading TextHeading--sm u-marginBottom--md">Customers</h1>

            {/* <Row type="flex" justify="start" align="top">
              <Col>
                <Tooltip
                  placement="top"
                  title="Shows Returns-Analytics for a specific customer."
                >
                  <Icon style={{ paddingTop: "13px" }} type="info-circle" />
                </Tooltip>
              </Col>
              <Col>
                &nbsp;
                <span className="TextHeading TextHeading--sm">Customer Analytics</span>
              </Col>
            </Row> */}

            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{ rowGap: '15px' }}
              gutter={16}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 16 }}
              >
                <Text style={{ fontSize: 14 }}>
                  Search for a customer by entering their first name, last name
                  or email address to view insights.
                </Text>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
              >
                <Tooltip
                  placement="top"
                  title="Search for a customer. Only customers with at least one Return in Rich Returns will show up as a result."
                >
                  {/* <Icon type="info-circle" /> */}
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    value={this.props.customerNameEmail}
                    placeholder="Search for a customer"
                    onChange={onChange}
                    onSearch={(value) => this.props.onSearch(value)}
                    filterOption={false}
                    notFoundContent={
                      <div className="flex-row flex-middle-xxs flex-center-xxs">
                        <div className="flex-col-xxs-12">
                          <IconTray size="md" />
                        </div>
                        <div>
                          <p className="TextBody TextBody--xxxs">
                                  No Data
                          </p>
                        </div>
                      </div>
                    }
                  >
                    {this.props.matchingCustomers &&
                    this.props.matchingCustomers.length > 0
                      ? this.props.matchingCustomers.map((value) => {
                        return (
                          <Option value={value.customerId}>
                            {`${value.shippingName} (${value.customerEmail})`}
                          </Option>
                        );
                      })
                      : null}
                  </Select>
                </Tooltip>
              </Col>
            </Row>

            <Spin
              spinning={this.state.loading}
              indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}
            >
              <Row
                type="flex"
                gutter={10}
                style={{
                  marginTop: '20px',
                  textAlign: 'center',
                  rowGap: '15px',
                }}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Number of orders with a subsequent return divided by the number of orders."
                  >
                    <Card
                      title="Return Rate"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.returnRate}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip placement="top" title="Number of Returns created.">
                    <Card
                      title="Return Volume"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.numberOfTotalReturns}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip placement="top" title="Number of Orders received.">
                    <Card
                      title="Order Volume"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.numberOfOrders}
                    </Card>
                  </Tooltip>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip placement="top" title="Value of returned items.">
                    <Card
                      title="Return Value"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.totalReturnValue} {this.state.currency}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Value of returned items divided by the number of returns."
                  >
                    <Card
                      title="Average return value"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.averageReturnValue} {this.state.currency}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Value of returned items that have been delivered or resolved. Applicable to return-status 'Received' and 'Resolved'."
                  >
                    <Card
                      title="Completed Return Value"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.valueOfCompletedReturns} {this.state.currency}
                    </Card>
                  </Tooltip>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip placement="top" title="Number of items returned.">
                    <Card
                      title="Returned Items"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.numberOfTotalProducts}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Number of items returned divided by the number of returns."
                  >
                    <Card
                      title="Average items per return"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.averageProductsPerReturn}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Number of returns that have been delivered or resolved. Applicable to return-status 'Received' and 'Resolved'."
                  >
                    <Card
                      title="Completed Return Volume"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.numberOfCompletedReturns}
                    </Card>
                  </Tooltip>
                </Col>
              </Row>

              <div className="u-marginTop--lg">
                <div className='TextHeading TextHeading--xs u-marginBottom--md'>Item based metrics</div>
              </div>

              {/* <Divider style={{ marginTop: 20, marginBottom: 20 }}>
                Item based Metrics
              </Divider> */}

              <Row
                type="flex"
                gutter={10}
                style={{
                  marginTop: '20px',
                  textAlign: 'center',
                  rowGap: '15px',
                }}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Number of items selected for Refund divided by number of items returned."
                  >
                    <Card
                      title="Refund Rate"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.rateOfRefundToOriginal}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Number of items selected for Refund."
                  >
                    <Card
                      title="Refund Volume"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.numberOfRefundToOriginal}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Value of items selected for Refund."
                  >
                    <Card
                      title="Refund Value"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.valueOfRefundToOriginal} {this.state.currency}
                    </Card>
                  </Tooltip>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Number of items selected for Exchange divided by number of items returned."
                  >
                    <Card
                      title="Exchange Rate"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.rateOfRefundToExchange}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Number of items selected for Exchange."
                  >
                    <Card
                      title="Exchange Volume"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.numberOfRefundToExchange}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Value of items selected for Exchange."
                  >
                    <Card
                      title="Exchange Value"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.valueOfRefundToExchange} {this.state.currency}
                    </Card>
                  </Tooltip>
                </Col>

                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Number of items selected for Store-Credit divided by number of items returned."
                  >
                    <Card
                      title="Store Credit Rate"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.rateOfRefundToCredit}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Number of items selected for Store-Credit."
                  >
                    <Card
                      title="Store Credit Volume"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.numberOfRefundToCredit}
                    </Card>
                  </Tooltip>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Tooltip
                    placement="top"
                    title="Value of items selected for Store-Credit."
                  >
                    <Card
                      title="Store Credit Value"
                      bordered={true}
                      hoverable={false}
                      extra={<React.Fragment></React.Fragment>}
                    >
                      {this.state.valueOfRefundToCredit} {this.state.currency}
                    </Card>
                  </Tooltip>
                </Col>
              </Row>
            </Spin>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Divider />
              </Col>
              <Upgrade
                title="Business Intelligence at your fingertips."
                description="Choose a plan with access to Reports. Explore insights into why customers return products and make smarter business decisions."
                videoId="VjbdXeknVMM"
              />
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default Stats;
