import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

// screens
import LoginScreen from "../components/Login";
import oAuthRedirect from "../pages/dashboard/integrations/authentication/intercom/oAuthRedirect";
import ShopifyStore from "../pages/dashboard/integrations/authentication/shopifyPublicApp/ShopifyStore";
import ShopifyStoreConfirm from "../pages/dashboard/integrations/authentication/shopifyPublicApp/ShopifyStoreConfirm";
//styles
import "../styles/App.css";
import ErrorRedirect from "./ErrorRedirect";

class AuthRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route exact={true} path="/" component={LoginScreen} />
        <Route exact={true} path="/login" component={LoginScreen} />
        <Route exact={true} path="/login/oauth" component={oAuthRedirect} />
        <Route exact={true} path="/auth/shopify" component={ShopifyStore} />
        <Route
          exact={true}
          path="/auth/shopify/confirm"
          component={ShopifyStoreConfirm}
        />
        <Route path="*" component={ErrorRedirect} /> 
      </Switch>
    );
  }
}

export default AuthRouter;
