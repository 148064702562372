/**
 * Component to retrieve a redirect URL for the Shopify Billing dialog.
 * Then redirects the user to that URL.
 * 
 * Implementation applies to our Shopify Public-App in the App-Store.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { message } from "antd";
import AuthenticationLoadingScreen from "../../../../components/AuthenticationLoadingScreen"

class ShopifyBilling extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    const response = await axios.post("/api/shop/shopify/billing", {});
    if (response.data.status === "error") {
      return message.error("Error connecting to the Shopify billing service. Please try again.");
    }
    return window.location.href = response.data.redirectUrl;
  }

  render() {
    return(
      <AuthenticationLoadingScreen />
    );
  }
}

export default ShopifyBilling;
