/**
 * Component to display a YouTube video embed as a modal popup.
 * 
 * Used whenever we want to show a quick explainer video
 * right in the App.
 * 
 * @component
 */

import React from "react";
import { Button, Modal, Tooltip, Icon } from "antd";

class VideoPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return(
      <React.Fragment>
        <Tooltip
          title="Click here to watch a video showing this feature in action."
          placement="top"
        >
          <Button onClick={this.showModal} size="small" type="ghost" shape="round" style={{width:'35px',height:'25px', display:'inline-flex',justifyContent:'center',alignItems:'center',padding:'4px 2px 2px'}}><Icon type="play-square" theme="filled"/></Button>
        </Tooltip>
        {
          this.state.visible
            ? (
              <Modal
                closable={true}
                title="See it in action"
                visible={this.state.visible}
                onCancel={this.handleCancel.bind(this)}
                footer={[
                  <Button onClick={this.handleCancel.bind(this)} key="close" type="primary">
                  Close
                  </Button>,
                ]}
              >
                <p><iframe width="100%" height="315" src={`https://www.youtube.com/embed/${this.props.videoId}?showinfo=0&autoplay=0&rel=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe></p>
              </Modal>
            )
            : null
        }
      </React.Fragment>
    )
  }
};

export default VideoPopup;
