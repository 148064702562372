/**
 * Component for a Powered-By button on the Return-Portals.
 *
 * @component
 */

import { Button } from "antd";
import React, { Component } from "react";

class PoweredByButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render() {
    const { poweredByButtonEnabled, storeUrl } = this.props;
    const innerWidthOfWindow = window.innerWidth;
    const isMobile = innerWidthOfWindow && innerWidthOfWindow <= 480 ? true : false;

    let positionOfButtonConfig;
    if (isMobile) {
      positionOfButtonConfig = {
        marginTop: '100px',
      }
    } else {
      positionOfButtonConfig = {
        position: 'fixed',
        bottom: '24px',
        left: '24px',
      }
    }

    return(
      <React.Fragment>
        {
          poweredByButtonEnabled
            ? (
              <Button type="link" style={{
                background: 'rgba(0,0,0,0.5)',
                color: '#FFFFFF',
                ...positionOfButtonConfig    
              }}
              href={`https://www.richcommerce.co?utm_medium=customer_portal&utm_source=${storeUrl}&utm_campaign=powered_by_rich_returns`}
              target="_blank"
              rel="noopener noreferrer"
              >
                Powered by <strong> Rich Returns</strong>
              </Button>
            )
            : null
        }
      </React.Fragment>
    );
  }
};

export default PoweredByButton;