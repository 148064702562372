/**
 * Component to display Stats along with
 * Pie charts of Products/Variants
 *
 * @component
 */

import React from 'react'
import { Modal, Button, Table, message } from 'antd'
import { Pie } from '@ant-design/charts'
import axios from 'axios'

class Stats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalRecord: {},
      showModal: false,
      currentPage: 1,
      pageSize: 10,
      returnReasonsData: [],
    }
  }

  componentDidMount = () => {
    this.handleSubmit()
  }
  handleSubmit = async () => {
    if (!this.props.startDate || !this.props.endDate) {
      return message.error('Select a start and end date.', 4)
    }
    const { currentPage, pageSize } = this.state
    try {
      const response = await axios.post(this.props.url, {
        selectedItem:
          this.props.reportName === 'most-returned-products'
            ? this.props.modalRecord.productId
            : this.props.modalRecord.variantId,
        currentPage,
        pageSize,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      })
      if (response.data.error) {
        return message.error('Error fetching report data from server.', 4)
      }

      this.setState({
        data: response.data.data,
        totalRecords: response.data.totalRecords,
        returnReasonsData: response.data.returnReasonsData,
      })

      return
    } catch (err) {
      message.error('Error fetching report data.', 4)
    }
  }

  triggerPagination = async (paginationObject) => {
    const currentPage = paginationObject.current
    const { pageSize } = paginationObject
    this.setState(
      {
        currentPage,
        pageSize,
      },
      () => {
        this.handleSubmit()
      }
    )
  }

  getData = () => {
    let totalCount = 0
    this.state.returnReasonsData?.forEach(reasonObject => {
      totalCount += parseInt(reasonObject.returnReasonCount);
    })
    const data = this.state.returnReasonsData?.map((returReasonObject) => {
      return {
        category: returReasonObject.returnReason,
        value: ((returReasonObject.returnReasonCount / totalCount) * 100),
      }
    })
    return data;
  }

  columns = [
    {
      title: 'Return',
      dataIndex: 'returnNumber',
      width: 200,
    },
    {
      title: 'Date',
      width: 130,
      dataIndex: 'returnDate',
      render: (dateString) => {
        const date = new Date(dateString)
        const day = date.getDate().toString().padStart(2, '0')
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const year = date.getFullYear()
        return `${month}-${day}-${year}`
      },
    },
    {
      title: 'Return Reason',
      dataIndex: 'returnReason',
    },
    {
      title: 'Customer Notes',
      dataIndex: 'notes',
    },
  ]
  render() {
    const { currentPage, totalRecords, data } = this.state
    const { modalRecord, reportName, visible } = this.props
    const config = {
      data: this.getData(),
      height: 400,
      autoFit: true,
      angleField: 'value',
      colorField: 'category',
      radius: 0.6,
      label: {
        type: 'outer',
        content: '{name}\n{percentage}',
      },
      legend: {
        position: 'bottom', // Adjust the legend position to 'right' or 'bottom'
        layout: 'vertical', // Adjust the layout to 'vertical' or 'horizontal'
      },
      interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
    }
    return (
      <React.Fragment>
        {visible && (
          <Modal
            width="50%"
            title={
              reportName === 'most-returned-products'
                ? modalRecord.productName
                : `${modalRecord.productName} ${modalRecord.variantName ? `(${modalRecord.variantName})` : ""}`
            }
            visible={visible}
            onCancel={this.props.handleCancel}
            okText="Save"
            cancelText="Close"
            footer={[
              <Button key="back" onClick={this.props.handleCancel}>
                Close
              </Button>,
            ]}
          >
            <Table
              columns={this.columns}
              dataSource={data}
              rowKey={(_, index) => index}
              pagination={{
                simple: true,
                defaultCurrent: currentPage,
                total: parseInt(totalRecords),
              }}
              onChange={(e) => this.triggerPagination(e)}
            />
            {this.state.returnReasonsData?.length > 0 && (
              <Pie {...config} />
            )}
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

export default Stats
