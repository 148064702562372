/**
 * component to get the details of Return Reason
 * @component
 */

import React from 'react';
import { Modal, Form, Row, Col, Select, Divider, Input, message } from 'antd';
import { allLanguages } from '../../../../../constants/constants';
import '../../../../../styles/App.css';
const FormItem = Form.Item;
const { Option } = Select;

class ReturnReasonForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCancel() {
    this.props.showConfigureModal(false);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error('please fill all the fields', 2);
      }
      if (values?.language?.length > 2) {
        const initialLanguageValue = allLanguages.find(
          (language) => language.name === values.language
        );
        const newValues = {
          language: initialLanguageValue.code,
          translation: values.translation,
        };
        this.props.onOk(newValues);
      } else {
        this.props.onOk(values);
      }
    });
  };

  componentWillUnmount() {
    this.props.form.resetFields();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const filteredLanguages = this.props.filteredLanguages;
    const filteredLanguageCodes = allLanguages.filter(function (languageData) {
      return (
        filteredLanguages.filter(function (languageCode) {
          return languageCode == languageData.code;
        }).length == 0
      );
    });

    const initialLanguageValue = allLanguages.find(
      (language) => language.code === this.props.language
    );
    const remainingLanguages = filteredLanguageCodes.map((language, index) => {
      return (
        <Option value={language.code} key={language.code}>
          {language.name}
        </Option>
      );
    });

    return (
      <React.Fragment>
        <Modal
          width="50%"
          title="Return Reason"
          onOk={this.handleSubmit.bind(this)}
          okText="Save"
          cancelText="Close"
          visible={this.props.visible}
          onCancel={this.props.onCancel}
        >
          <Form
            onSubmit={this.handleSubmit.bind(this)}
            className="removeFormMargin"
          >
            {this.props?.featureMultilangReturnReasons && (
              <React.Fragment>
                <Row type="flex" justify="space-around" align="middle">
                  <Col span={10}>
                    <h4>Select a language</h4>
                  </Col>
                  <Col span={10}>
                    <FormItem>
                      {getFieldDecorator('language', {
                        initialValue: initialLanguageValue
                          ? initialLanguageValue.name
                          : '',
                        rules: [{ required: true }],
                      })(
                        <Select style={{ width: '100%' }}>
                          {remainingLanguages}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row type="flex" justify="space-around" align="top">
                  <Col span={22}>
                    <Divider />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            <Row type="flex" justify="space-around" align="middle">
              <Col span={10}>
                <h4>Enter the Reason</h4>
              </Col>
              <Col span={10}>
                <FormItem>
                  {getFieldDecorator('translation', {
                    initialValue: this.props.translation,
                    rules: [{ required: true }],
                  })(<Input />)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

const WrappedReturnReasonForm = Form.create()(ReturnReasonForm);
export default WrappedReturnReasonForm;
