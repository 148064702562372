/**
 * Component for Webhook setting page in the Dashboard.
 *
 * @component
 */

import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Table
} from 'antd';
import axios from 'axios';
import React from 'react';
import { IconTray } from '../../../../components/CustomReturnsIcons';

class WebhooksTable extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      id: '',
      name: '',
      event: '',
      url: '',
      secret: '',
      active: false,
      webHooks: [],
      editWebhook: '',
      secretToken: undefined,
      closeAlert: false,
    };
  }
  componentDidMount = () => {
    this.getWebhooks();
  };

  async getWebhooks() {
    try {
      const response = await axios.get('/returns/webhook/webhooks');
      if (response.data && response.data.status === 'error') {
        const errorMessage = response.data.error;
        return message.error(errorMessage, 6);
      }
      this.setState({
        webHooks: response.data.response,
      });
    } catch (err) {
      message.error('Error retrieving your Webhooks . Try again.', 5);
    }
  }

  handleSubmit = async () => {
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered, Please Check again.',
          5
        );
      }
      try {
        const response = await axios.post('/returns/webhook/webhooks', {
          ...values,
        });

        const { secret } = response.data.response;
        this.setState({
          closeAlert: true,
          secretToken: secret,
        });
        this.getWebhooks();
        return message.success('Webhook Created Successfully.', 5);
      } catch (err) {
        return message.error('Error Creating Webhook. Please try again.', 5);
      }
    });
  };

  deleteWebHook = async (id) => {
    try {
      await axios.delete(`/returns/webhook/webhooks/${id}`, {});
      this.getWebhooks();
      return message.success('Webhook Deleted Successfully.', 5);
    } catch (err) {
      return message.error('Error deleting the webhook. please try again.', 5);
    }
  };

  editWebHook = async (id) => {
    this.showModal();
    try {
      const findWebHook = this.state.webHooks.filter(
        (webhook) => webhook.id === id
      );
      const webHookData = findWebHook[0];
      this.setState({
        id: webHookData.id,
        editWebhook: webHookData,
        active: webHookData.active,
      });
      this.props.form.setFieldsValue({
        name: webHookData.name,
        event: webHookData.event,
        url: webHookData.url,
        secret: webHookData.secret,
        active: webHookData.active,
      });
    } catch (err) {
      return message.error('Error Editing the WebHook, Please try again');
    }
  };

  handleEdit = async () => {
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered, Please Check again.',
          5
        );
      }
      try {
        await axios.put(`/returns/webhook/webhooks/${this.state.id}`, {
          ...values,
        });
        this.getWebhooks();
        this.setState({
          editWebhook: '',
          visible: false,
        });
        return message.success('Webhook updated successfully.', 5);
      } catch (err) {
        return message.error(
          'Error Updating the Webhook, Please try again.',
          5
        );
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    if (this.state.editWebhook) {
      this.handleEdit();
    } else {
      this.handleSubmit();
    }
    this.props.form.resetFields();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      closeAlert: false,
      secretToken: '',
    });
    this.state.editWebhook = '';
    this.props.form.resetFields();
  };

  confirm = () => {
    message.success('Click on Yes');
  };

  cancel = () => {
    message.error('Click on No');
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Event',
        dataIndex: 'event',
        key: 'event',
      },
      {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
      },
      {
        title: 'Secret',
        dataIndex: 'secret',
        key: 'secret',
        render: (text) => {
          const last4 = text.slice(-4);
          return <span>****************{last4}</span>;
        },
      },
      {
        title: 'Enabled',
        dataIndex: 'enabled',
        key: 'enabled',
        render: (text, record) => (
          <span>
            <Switch size="small" checked={record.active} />
          </span>
        ),
      },
      {
        title: 'Edit',
        dataIndex: 'Edit',
        key: 'Edit',
        render: (text, record) => (
          <span>
            <Button
              size="small"
              type="primary"
              onClick={() => this.editWebHook(record.id)}
            >
              Edit
            </Button>
          </span>
        ),
      },
      {
        title: 'Delete',
        key: 'Delete',
        render: (text, record) => (
          <Popconfirm
            title="Are you sure delete this Webhook?"
            onConfirm={() => this.deleteWebHook(record.id)}
            onCancel={this.cancel}
            okText="Yes"
            cancelText="No"
          >
            <span>
              <Button type="danger" size="small">
                Delete
              </Button>
            </span>
          </Popconfirm>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div style={{ overflowX: 'auto' }}>
          <Table
            columns={columns}
            dataSource={this.state.webHooks}
            style={{ minWidth: '768px' }}
            bordered={false}
            locale={{
              emptyText: (
                <div className="flex-row flex-middle-xxs flex-center-xxs">
                  <div className="flex-col-xxs-12">
                    <IconTray size="md" />
                  </div>
                  <div>
                    <p className="TextBody TextBody--xxxs">No Data</p>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <Row type="flex" justify="end" align="top">
          <Button
            type="primary"
            onClick={() => this.showModal()}
            style={{ marginTop: '30px' }}
          >
            Create Webhook
          </Button>
        </Row>
        <Modal
          title="Create new Webhook"
          visible={this.state.visible}
          okButtonProps={
            this.state.secretToken ? { disabled: true } : { disabled: false }
          }
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={350}
        >
          {this.state.closeAlert ? (
            <Alert
              message="Secret key created successfully"
              description={
                <React.Fragment>
                  <p>
                    Write the token down in a secure place. You will not be able
                    to access it later.
                  </p>
                  <p>API Token:{this.state.secretToken}</p>
                </React.Fragment>
              }
              type="success"
              showIcon
            />
          ) : (
            <Form className="login-form">
              <h4>Webhook name</h4>
              <Form.Item>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: 'Name!' }],
                })(
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Name"
                    name="name"
                    className="u-marginBottom--sm"
                  />
                )}
              </Form.Item>
              <h4>Event</h4>
              <Form.Item>
                {getFieldDecorator('event', {
                  rules: [{ required: true, message: 'Event!' }],
                })(
                  <Input placeholder="Event" className="u-marginBottom--sm" />
                )}
              </Form.Item>
              <h4>URL</h4>
              <Form.Item>
                {getFieldDecorator('url', {
                  rules: [{ required: true, message: 'url!' }],
                })(<Input placeholder="Url" className="u-marginBottom--md" />)}
              </Form.Item>
              <h4>Webhook active?</h4>
              <Form.Item>
                {getFieldDecorator('active', {
                  valuePropName: 'active',
                  initialValue: this.state.active,
                })(
                  <Switch
                    checked={this.state.active}
                    onChange={() =>
                      this.setState({ active: !this.state.active })
                    }
                    size="small"
                    // defaultChecked={this.state.active}
                  />
                )}
              </Form.Item>
            </Form>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default Form.create()(WebhooksTable);
