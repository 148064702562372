/**
 * Component for our Manual-Returns feature
 * ("Multichannel Return") that is only
 * available to selected accounts. Allows adding
 * the Return details for a new Manual-Return.
 * 
 * @component
 */

import React, { Component } from "react";
import axios from "axios";
import { Button, Form, Input, Row, Col, Select, DatePicker, Divider, List, Checkbox, message } from "antd";
import ManualReturnProduct from "./ManualReturnProduct";
import { nonFrequentlyUsedCountries, frequentlyUsedCountries, USStateNames } from "../../../../constants/constants"

const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

class ManualReturnForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingCountryCode: undefined,
      vendorList: undefined,
      returnReasons: undefined,
      products: undefined,
      productsSelected: [],
      modalAddProductVisible: false,
      loading: false,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get("/api/shop/manual");
            
      const errorStatus = response.data.error;
      if (errorStatus) {
        return message.error("Error loading your Multichannel-RMA settings.", 4)
      }

      const responseData = response.data;
      return this.setState({
        ...responseData
      })

    } catch(err) {
      return message.error("Error loading your Multichannel-RMA settings.", 4);
    }
  }

    handleCountryChange = (countryCode) => {
      this.setState({
        shippingCountryCode: countryCode,
      });
    };

    handleSubmit = (e) => {
      e.preventDefault();

      const productsSelected = this.state.productsSelected;
      if (productsSelected.length === 0) {
        return message.error("Please add at least 1 product to the return.", 5);
      }

      this.props.form.validateFields( async (err, values) => {
        if (err) {
          return message.error("There is something wrong with the data you entered. Please check again.", 5);
        }

        this.setState({
          loading: true,
        });
        try {
          const response = await axios.post("/api/shop/manual/add", {
            ...values,
            productsSelected,
          });
          if (response.data.status === "error") {
            this.setState({
              loading: false,
            });
            return message.error("Error adding a manual Multichannel-RMA. Please try again.", 5);
          } 
          this.setState({
            loading: false,
          });
          const { returns, productsOfReturns } = response.data;
          this.props.updateData({ returns, productsOfReturns });
          this.props.setVisibilityOfResetSearchButton(true);
          this.props.hideDrawer();
          return message.success("Manual Multichannel-RMA added successfully.", 5);
        } catch (err) {
          this.setState({
            loading: false,
          });
          return message.error("Error adding a manual Multichannel-RMA. Please try again.", 5);
        }
      });
    }

    addProduct = (returnData) => {
      const products = this.state.products;
      const selectedVariantId = returnData.selectedVariantId;
      const selectedReturnReason = returnData.selectedReturnReason;
      const selectedResolution = returnData.selectedResolution;
      const notes = returnData.notes;

      let newProduct;
      products.map( (product) => {
        if (product.variantId === selectedVariantId) {
                
          newProduct = {...product};
          newProduct.returnReason = selectedReturnReason;
          newProduct.resolution = selectedResolution;
          newProduct.notes = notes;
          newProduct.id = Math.floor(Math.random() * 1000);
        }
      });
        
      return this.setState(prevState => ({
        productsSelected: [...prevState.productsSelected, newProduct],
        modalAddProductVisible: false
      }));
    }

    setModalAddProductVisible = (stateOfVisibility) => {
      this.setState({
        modalAddProductVisible: stateOfVisibility
      });
    }

    getCountriesList = () => {
      let countriesList = [];
      frequentlyUsedCountries.forEach((country) => {
        countriesList.push(<Option value={country.code}>{country.name}</Option>);
      });

      const disabledoption = <Option value="disabled" disabled={true}>---------</Option>;
      countriesList.push(disabledoption)
      nonFrequentlyUsedCountries.forEach((country) => {
        countriesList.push(<Option value={country.code}>{country.name}</Option>);
      });
        
      return countriesList;
    }

    getUSStateList = () => {
      const USStateList = USStateNames.map((state) => {
        return (
          <Option value={state.code}>{state.name}</Option>
        );
      });
        
      return USStateList;
    };

    render() {

      const { getFieldDecorator } = this.props.form;

      let returnReasonsElements = [];
      if (this.state.returnReasons) {
        const returnReasons = this.state.returnReasons;
        returnReasonsElements = returnReasons.map((reason) => {
          return <Option value={ reason } key={ reason }>{ reason }</Option>
        });
      }

      let arrayOfVendors = [];
      let vendorElements = [];
      if (this.state.vendorList) {
        const vendorList = this.state.vendorList;
        arrayOfVendors = vendorList.split(/\r?\n/);
        vendorElements = arrayOfVendors.map((reason) => {
          return <Option value={ reason } key={ reason }>{ reason }</Option>
        });
      }

      let products;
      if (this.state.products) {
        const productArray = this.state.products;
        products = productArray.map( (product) => {
          return <Option value={ product.variantId } key={ product.variantId }>{ product.productAndVariantName }</Option>
        });
      }

      return(
        <Form onSubmit={this.handleSubmit.bind(this)}>
                
          <Divider>Order information</Divider>
                
          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Return status" {...formItemLayout}>
                {
                  getFieldDecorator("returnStatus", {
                    initialValue: this.state.returnStatus,
                    rules: [
                      { required: true, message: 'Please add a return status'  }
                    ]
                  })(
                    <Select>
                      <Option value="Pending">Pending</Option>
                      <Option value="Approved">Approved</Option>
                      <Option value="Shipped">Shipped</Option>
                      <Option value="In Transit">In Transit</Option>
                      <Option value="Received">Received</Option>
                      <Option value="Resolved">Resolved</Option>
                      <Option value="Rejected">Rejected</Option>
                    </Select>
                  )
                }
              </FormItem>
            </Col>
          </Row>
                
          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Vendor" {...formItemLayout}>
                {
                  getFieldDecorator("vendor", {
                    initialValue: this.state.vendor,
                    rules: [
                      { required: true, message: 'Please choose a vendor'  }
                    ]
                  })(
                    <Select>
                      { vendorElements }
                    </Select>
                  )
                }
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Order number" {...formItemLayout}>
                {
                  getFieldDecorator("orderNumber", {
                    initialValue: this.state.orderNumber,
                    rules: [
                      { required: true, message: 'Please add an order number'  }
                    ]
                  })(
                    <Input placeholder="Order number" />
                  )
                }
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Order date" {...formItemLayout}>
                {
                  getFieldDecorator("orderDate", {
                    initialValue: this.state.orderDate,
                    rules: [
                      { required: true }
                    ]
                  })(
                    <DatePicker/>
                  )
                }
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Date received" {...formItemLayout}>
                {
                  getFieldDecorator("dateReceived", {
                    initialValue: this.state.dateReceived
                  })(
                    <DatePicker 
                      placeholder="Select date (leave empty if not received)"
                    />
                  )
                }
              </FormItem>
            </Col>
          </Row>

          <Divider>Product information</Divider>

          <Row type="flex" justify="start" align="top" style={{ textAlign: "center", marginBottom: 20 }}>
            <Col span={24}>
              <Button type="primary" onClick={() => this.setModalAddProductVisible(true)}>Add product</Button>
              {
                this.state.modalAddProductVisible
                  ? (
                    <ManualReturnProduct
                      products={products}
                      returnReasonsElements={returnReasonsElements}
                      modalAddProductVisible={this.state.modalAddProductVisible}
                      addProduct={this.addProduct.bind(this)}
                      setModalAddProductVisible={this.setModalAddProductVisible.bind(this)}
                    />
                  )
                  : null
              }
            </Col>
          </Row>
                            
          {
            this.state.productsSelected && this.state.productsSelected.length > 0
              ? (
                <Row type="flex" justify="start" align="top">
                  <Col span={24}>
                    <List
                      bordered
                      size="small"
                      dataSource={this.state.productsSelected}
                      renderItem={product => (
                        <List.Item key={product.id} value={product.id}>
                          { product.productAndVariantName }
                        </List.Item>
                      )}
                    />
                  </Col>
                </Row>
              )
              : null
          }

          <Divider>Customer information</Divider>

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col span={24}>
              <FormItem  label="Country" {...formItemLayout}>
                {getFieldDecorator('shippingCountryCode', {
                  initialValue: this.state.shippingCountryCode,
                  rules: [
                    { required: true, message: 'Please choose the country of the customer'  }
                  ]
                })(
                  <Select
                    placeholder="Select country"
                    onChange={ this.handleCountryChange.bind(this) }
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    { this.getCountriesList() }
                  </Select>
                )}
              </FormItem>
              {
                this.state.shippingCountryCode !== "US"
                  ? null
                  : (
                    <FormItem label="State" {...formItemLayout}>
                      {getFieldDecorator('shippingProvinceCode', {
                        initialValue: this.state.shippingProvinceCode,
                        rules: [
                          { required: true, message: 'Please choose a state'  }
                        ]
                      })(
                        this.state.shippingCountryCode === "US"
                          ? (
                            <Select
                              placeholder="State / Province / Region"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              { this.getUSStateList() }
                            </Select>
                          )
                          : (
                            <Select placeholder="State / Province / Region">
                              <Option value="" key="disabled" disabled>State only needed for US based customers.</Option>
                            </Select>
                          )
                      )}
                    </FormItem>
                  )
              }
              <FormItem>
                {
                  getFieldDecorator("shippingFirstName", {
                    initialValue: this.state.shippingFirstName,
                    rules: [
                      { required: true, message: "Please add the customer's first name" }
                    ]
                  })(
                    <Input addonBefore="First name" />
                  )
                }
              </FormItem>
              <FormItem>
                {
                  getFieldDecorator("shippingLastName", {
                    initialValue: this.state.shippingLastName,
                    rules: [
                      { required: true, message: "Please add the customer's last name"  }
                    ]
                  })(
                    <Input addonBefore="Last name" />
                  )
                }
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingAddress1', {
                  initialValue: this.state.shippingAddress1,
                  rules: [
                    { required: true, message: "Please add the customer's street address"  }
                  ]
                })(
                  <Input addonBefore="Street address" placeholder="Street and number, P.O. box, c/o." />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingAddress2', {
                  initialValue: this.state.shippingAddress2,
                })(
                  <Input addonBefore="(optional)" placeholder="Apartment, suite, unit, building, floor, etc." />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingCity', {
                  initialValue: this.state.shippingCity,
                  rules: [
                    { required: true, message: "Please add the customer's city"  }
                  ]
                })(
                  <Input addonBefore="City" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingZip', {
                  initialValue: this.state.shippingZip,
                  rules: [
                    { required: true, message: "Please add the customer's zip code"  }
                  ]
                })(
                  <Input addonBefore="Zip Code" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('customerEmail', {
                  initialValue: this.state.customerEmail,
                  rules: [
                    { required: true, message: "Please add the customer's email"  }
                  ]
                })(
                  <Input addonBefore="E-Mail" placeholder="For delivering instructions via email" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('shippingPhone', {
                  initialValue: this.state.shippingPhone,
                })(
                  <Input addonBefore="Phone number" placeholder="(optional) May be used to assist delivery" />
                )}
              </FormItem>
            </Col>
          </Row>

          <Divider>Actions</Divider>

          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Label" {...formItemLayout}>
                {
                  getFieldDecorator("createLabel", {
                    initialValue: false
                  })(
                    <Checkbox>
                                        Create a prepaid-label and send it to the customer
                    </Checkbox>
                  )
                }
              </FormItem>
            </Col>
          </Row>

          <Divider>Notes</Divider>

          <Row type="flex" justify="start" align="top">
            <Col span={24}>
              <FormItem label="Notes" {...formItemLayout}>
                {
                  getFieldDecorator("staffNotes", {
                    initialValue: this.state.staffNotes
                  })(
                    <TextArea rows={4} placeholder="Internal notes. Not visible to the customer." />
                  )
                }
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="middle" className="dashboard-content">
            <Col>
              <FormItem>
                <Button type="primary" htmlType="submit" loading={this.state.loading} disabled={this.state.loading}>
                  {
                    this.state.loading
                      ? <span>Creating return</span>
                      : <span>Save</span>
                  }
                </Button>
              </FormItem>
            </Col>
          </Row>

          <Row type="flex" justify="middle" align="top" style={{ textAlign: "center" }}>
            <Col span={24}>
              <span>This will add a return and send the associated email to the customer.</span>
            </Col>
          </Row>
        </Form>
      )
    }
}
const WrappedManualReturnForm = Form.create()(ManualReturnForm);
export default WrappedManualReturnForm;