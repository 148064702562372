/**
 * Component for our Manual Shipping-Labels
 * for users to upload them.
 *
 * @component
 */

import {
  Button,
  Icon,
  Popconfirm,
  Tooltip,
  Upload,
  message
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';

const ButtonGroup = Button.Group;

class ReturnDetailsLabelManual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customShippingLabelUrl: undefined,
    };
  }

  // image related functions
  async checkLabel(file) {
    const allowedFileTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    if (!allowedFileTypes.includes(file.type)) {
      message.error(
        'Only .jpg, .jpeg, .png and .pdf files smaller than 3 MB allowed.',
        5
      );
      return false;
    }
    if (file.size / 1024 / 1024 > 3) {
      message.error('Only files smaller than 3 MB allowed.', 5);
      return false;
    }
    return true;
  }

  async deleteLabel(options, firstOrSecondFile) {
    try {
      const response = await axios.delete('/api/returns/label', {
        data: {
          returnId: this.props.returnId,
          firstOrSecondFile,
        },
      });
      if (response.data.error) {
        return message.error('Error deleting file. Try again.', 4);
      }
      this.props.handleGetReturn();
      return message.success('File deleted successfully', 4);
    } catch (err) {
      message.error('Error deleting file. Try again.', 4);
    }
  }

  async handleLabelUpload(options, firstOrSecondFile) {
    options.onProgress({ percent: 20 });
    let signedUrl = '';
    let bucketUrl = '';
    let fileName = '';
    try {
      let response = await axios.post('/api/returns/uploadLabel', {
        fileType: options.file.type,
        fileSize: options.file.size / 1024 / 1024,
      });
      if (response.data.error === 'wrong-size') {
        return message.error('Only files smaller than 3 MB allowed.', 5);
      }
      if (response.data.error === 'wrong-format') {
        return message.error(
          'Only .jpg, .jpeg, .png and .pdf files smaller than 3 MB allowed.',
          5
        );
      }
      signedUrl = response.data.signedRequest;
      bucketUrl = response.data.url;
      // eslint-disable-next-line no-unused-vars
      fileName = response.data.fileName;
      options.onProgress({ percent: 50 });
    } catch (err) {
      return message.error(
        'Error preparing shipping label upload. Try again.',
        4
      );
    }

    try {
      await axios.put(signedUrl, options.file, {
        headers: { 'Content-Type': options.file.type },
      });
      options.onProgress({ percent: 75 });
      const responseLabel = await axios.post('/api/returns/storeLabel', {
        returnId: this.props.returnId,
        customShippingLabelUrl: bucketUrl,
        customShippingLabelSecondUrl: bucketUrl,
        firstOrSecondFile,
      });
      if (responseLabel.data.error) {
        return message.error('Error saving shipping label. Try again.', 4);
      }
      this.props.handleGetReturn();
      options.onSuccess({ percent: 100 });
      return message.success('Shipping label uploaded successfully.', 5);
    } catch (err) {
      options.onError({ percent: 0 });
      return message.error('Error saving shipping label. Try again.', 4);
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
          <div className="flex-col-xxs-12">
            <div
              className="flex-row flex-row--noMargin flex-middle-xxs"
              style={{ gap: 5 }}
            >
              <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Shipping label</strong>
              <Tooltip
                placement="bottom"
                title="This will send out the following email template if enabled: 'Approved Return (Manually Uploaded Prepaid Label)'. A variable for the first label is already included in the template. If you use the upload for the second document, make sure to include the variable {MANUAL_LABEL_SECOND_DOWNLOAD_LINK} in the template as well."
              >
                <Icon type="info-circle" style={{fontSize:'11px'}} />
              </Tooltip>
            </div>
          </div>
          <div className="flex-col-xxs-12" style={{padding: 0}}>
            <div className="flex-row flex-row--noMargin" style={{ rowGap: 10 }}>
              {this.props.returnObject.customShippingLabelUrl == null &&
              this.state.customShippingLabelUrl == null ? (
                  <div className="flex-col-sm-6 flex-col-xxs-12">
                    <Upload
                      beforeUpload={(file) => {
                        return this.checkLabel(file);
                      }}
                      customRequest={(options) => {
                        this.handleLabelUpload(options, 'first');
                      }}
                    >
                      <Button
                        type="ghost"
                        style={{ width: '100%' }}
                      >
                        {/* <Icon type="upload" /> */}
                        Upload
                      </Button>
                    </Upload>
                  </div>
                ) : (
                  <div className="flex-col-sm-6 flex-col-xxs-12">
                    <ButtonGroup>
                      <Button
                        type="ghost"
                        size="small"
                        href={
                          this.props.returnObject.customShippingLabelUrl != null
                            ? this.props.returnObject.customShippingLabelUrl
                            : this.state.customShippingLabelUrl
                        }
                        target="_blank"
                        style={{ padding: 'var(--input-padding)', width: '100%' }}
                      >
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />
                      View label
                      </Button>
                      <Popconfirm
                        title="Delete this file?"
                        onConfirm={(options) =>
                          this.deleteLabel(options, 'first')
                        }
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                      >
                        <Button
                          type="ghost"
                          size="small"
                          icon="delete"
                          style={{
                            padding: 'var(--input-padding)',
                            paddingLeft: 15,
                            paddingRight: 15,
                            height: '100%',
                          }}
                        />
                      </Popconfirm>
                    </ButtonGroup>
                  </div>
                )}
              {this.props.returnObject.customShippingLabelSecondUrl == null &&
              this.props.customShippingLabelSecondUrl == null ? (
                  <div className="flex-col-sm-6 flex-col-xxs-12">
                    <Upload
                      beforeUpload={(file) => {
                        return this.checkLabel(file);
                      }}
                      customRequest={(options) => {
                        this.handleLabelUpload(options, 'second');
                      }}
                    >
                      <Button
                        type="ghost"
                        style={{ width: '100%' }}
                      >
                        {/* <Icon type="upload" /> */}
                        Upload 2nd label 
                      </Button>
                    </Upload>
                  </div>
                ) : (
                  <div className="flex-col-sm-6 flex-col-xxs-12">
                    <ButtonGroup>
                      <Button
                        type="default"
                        size="small"
                        href={
                          this.props.returnObject.customShippingLabelSecondUrl !=
                        null
                            ? this.props.returnObject.customShippingLabelSecondUrl
                            : this.props.customShippingLabelSecondUrl
                        }
                        target="_blank"
                        style={{ padding: 'var(--input-padding)', width: '100%' }}
                      >
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />
                      View
                      </Button>
                      <Popconfirm
                        title="Delete this file?"
                        onConfirm={(options) =>
                          this.deleteLabel(options, 'second')
                        }
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                      >
                        <Button
                          type="default"
                          size="small"
                          icon="delete"
                          style={{
                            padding: 'var(--input-padding)',
                            paddingLeft: 15,
                            paddingRight: 15,
                            height: '100%',
                          }}
                        />
                      </Popconfirm>
                    </ButtonGroup>
                  </div>
                )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReturnDetailsLabelManual;
