/**
 * Component to welcome the user after they granted us
 * access in Shopify's permission dialog. We then try to
 * initiate OAuth/authentication process and redirect
 * the user to the main Dashboard if successful.
 * 
 * Implementation applies to our Shopify Public-App in the App-Store.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import AuthenticationLoadingScreen from "../../../../../components/AuthenticationLoadingScreen"

class Shopify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectAppStore: false,
    };
  }

  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);
    const code = urlObject.searchParams.get("code");
    const state = urlObject.searchParams.get("state");
    // Shopify only
    const hmac = urlObject.searchParams.get("hmac");
    const shop = urlObject.searchParams.get("shop");
    const timestamp = urlObject.searchParams.get("timestamp");
    const host = urlObject.searchParams.get("host");
    if (code == null) {
      return;
    }

    try {
      await axios.post("/api/shop/shopify/callback", {
        code,
        hmac,
        host,
        shop,
        state,
        timestamp,
      });

      this.setState({
        redirectAppStore: true,
      });
      
    } catch (err) {
      message.error("Error connecting your Shopify account.", err);
    }
  }

  render() {
    if (this.state.redirectAppStore) {
      return <Redirect to="/dashboard" />;
    }

    return(
      <AuthenticationLoadingScreen />
    );
  }
}

export default Shopify;
