import { Component } from 'react';

class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [...document.querySelectorAll('.carousel-item')],
      currentIndex: 0,
      isTransitioning: false,
      totalItems: 8,
    };
  }

  stopAutoplay() {
    if (window.autoplayInterval !== null) {
      clearInterval(window.autoplayInterval);
      window.autoplayInterval = null;
    }
  }

  goToPrev = () => {
    this.stopAutoplay();
    const currentIndex = this.state.currentIndex;
    if (currentIndex > 0) {
      this.scrollToIndex(currentIndex - 1);
    }
    this.startAutoplay();
  };

  goToNext = () => {
    this.stopAutoplay();
    const currentIndex = this.state.currentIndex;
    const totalItems = this.state.totalItems;
    if (currentIndex < totalItems) {
      this.scrollToIndex(currentIndex + 1);
    }
    this.startAutoplay();
  };

  scrollToIndex(index, smooth = true) {
    if (this.state.isTransitioning) return;
    const prevButton = document.getElementById('carouselPrevButton');
    const nextButton = document.getElementById('carouselNextButton');
    if (prevButton) {
      if (index == 0 || index == this.state.totalItems) {
        prevButton.classList.add('u-isDisabled');
        nextButton.classList.remove('u-isDisabled');
      } else {
        prevButton.classList.remove('u-isDisabled');
      }
    }
    this.setState({ isTransitioning: true });
    const carousel = document.querySelector('.carousel-slider');
    if (carousel) {
      carousel.style.scrollBehavior = smooth ? 'smooth' : 'auto';
      carousel.scrollLeft = index * carousel.clientWidth;
    }
    this.setState({ isTransitioning: false, currentIndex: index });
  }

  startAutoplay = () => {
    if (window.autoplayInterval === null) {
      window.autoplayInterval = setInterval(() => {
        const currentIndex = this.state.currentIndex || 0;
        const totalItems = this.state.totalItems;
        const carousel = document.querySelector('.carousel-slider');
        if (currentIndex < totalItems) {
          this.scrollToIndex(currentIndex + 1);
          this.updateDots();
        } else {
          this.scrollToIndex(totalItems, true);
          setTimeout(() => {
            carousel.style.scrollBehavior = 'auto';
            carousel.scrollLeft = carousel.clientWidth;
            this.setState({ currentIndex: 0, isTransitioning: false });
            this.updateDots();
          }, 5000);
        }
      }, 5000);
    }
  };

  updateDots() {
    const dots = document.querySelectorAll('.carousel-dots div');
    dots.forEach((dot) => dot.classList.remove('active'));
    const { currentIndex, totalItems } = this.state;
    if (currentIndex >= 0 && currentIndex < totalItems) {
      dots[currentIndex].classList.add('active');
    }
  }

  componentDidMount() {
    const carousel = document.querySelector('.carousel-slider');
    const wrapper = document.querySelector('.carousel-wrapper');
    const prevButton = document.getElementById('carouselPrevButton');
    let items = [...document.querySelectorAll('.carousel-item')];
    const dotsContainer = document.querySelector('.carousel-dots');

    let randomSlide = false;
    let randomStyle = 'all';
    window.autoplayInterval = null;

    if (randomSlide) {
      if (randomStyle === 'first_element') {
        let index = Math.floor(Math.random() * items.length);
        let index_element = items[index];
        items.splice(index, 1);
        let first_element = [items[0]];
        items.splice(0, 1);
        items = first_element.concat([index_element]).concat(items);
      } else if (randomStyle === 'all') {
        this.shuffle(items); // Call this.shuffle() instead of defining a new shuffle function
      }
      carousel.innerHTML = '';
      items.forEach((item) => carousel.appendChild(item));
    }

    const firstItemClone = items[0].cloneNode(true);
    carousel.appendChild(firstItemClone);
    prevButton.classList.add('u-isDisabled');

    items.forEach((_item, index) => {
      const dot = document.createElement('div');
      dot.classList.add('carousel-dot-item');
      dot.setAttribute('data-index', index);
      dot.addEventListener('click', () => this.scrollToIndex(index));
      dotsContainer.appendChild(dot);
    });

    this.updateDots();

    // Scroll event listener
    this.handleScroll = () => {
      let index = Math.round(carousel.scrollLeft / carousel.clientWidth);
      if (index !== this.state.currentIndex) {
        this.setState({ currentIndex: index });
        this.updateDots();
      }
      if (carousel.scrollLeft >= this.state.totalItems * carousel.clientWidth) {
        setTimeout(() => {
          carousel.style.scrollBehavior = 'auto';
          carousel.scrollLeft = 0;
          this.setState({ currentIndex: 0 });
          this.updateDots();
        }, 30);
      }
    };
    carousel.addEventListener('scroll', this.handleScroll);

    // Intersection Observer to handle autoplay
    this.observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.startAutoplay();
        } else {
          this.stopAutoplay();
        }
      });
    };
    this.intersectionObserver = new IntersectionObserver(
      this.observerCallback,
      {
        root: null,
        threshold: 0,
      }
    );
    this.intersectionObserver.observe(wrapper);

    // Add mouse event listeners for each item
    items.forEach((item) => {
      item.addEventListener('mouseenter', this.stopAutoplay);
      item.addEventListener('mouseleave', this.startAutoplay);
    });

    // Window focus and blur event listeners
    window.addEventListener('focus', this.startAutoplay);
    window.addEventListener('blur', this.stopAutoplay);
  }

  componentWillUnmount() {
    // Remove window event listeners
    window.removeEventListener('focus', this.startAutoplay);
    window.removeEventListener('blur', this.stopAutoplay);

    // Remove mouse event listeners from each item
    const items = [...document.querySelectorAll('.carousel-item')];
    items.forEach((item) => {
      item.removeEventListener('mouseenter', this.stopAutoplay);
      item.removeEventListener('mouseleave', this.startAutoplay);
    });

    // Clear autoplay interval if it's set
    if (window.autoplayInterval) {
      clearInterval(window.autoplayInterval);
      window.autoplayInterval = null;
    }

    // Disconnect the intersection observer
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }

    // Remove scroll event listener
    const carousel = document.querySelector('.carousel-slider');
    if (carousel) {
      carousel.removeEventListener('scroll', this.handleScroll);
    }
  }

  // Shuffle function defined as a class method
  shuffle(items) {
    let currentIndex = items.length;
    while (currentIndex !== 0) {
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [items[currentIndex], items[randomIndex]] = [
        items[randomIndex],
        items[currentIndex],
      ];
    }
  }

  render() {
    return (
      <div className="carousel-wrapper">
        <div className="carousel-slider u-marginBottom--sm">
          <div className="carousel-item">
            <div className="ContentPanel u-overflow--hidden u-marginBottom--none  u-flexJustifyContent--center">
              <div className="flex-row flex-middle-xxs">
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  {/* <img
                      className="u-displayInlineFlex u-verticalAlign--top c-iconCircle c-iconCircle--sm"
                      src="https://assets.reviews.io/img/multiplatform/logos/icon-boost.svg"
                      alt=""
                    /> */}
                  <div className="TextHeading TextHeading--xxs TextHeading--newGreen u-marginBottom--xs">
                    New Integration
                  </div>
                  <div className="TextHeading TextHeading--xs">
                    UPS Integration Launched
                  </div>
                  <div className="TextBody TextBody--xxs u-marginBottom--md">
                    This new integration allows merchants to generate UPS return
                    labels directly within the Rich Returns platform,
                    eliminating the need for third-party services.
                  </div>
                  <div className="u-textLeft--all">
                    <a target="_blank" rel="noopener noreferrer" href="https://updates.richreturns.io/new-ups-integration-for-rich-returns">
                      <div className="Button Button--xs Button--outline">
                      Learn More
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <div className="c-cardBanner">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--ups-integration.webp 1x, https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--ups-integration.webp 2x"
                      />
                      <img
                        className="c-cardBanner__image"
                        src="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--ups-integration.webp"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="ContentPanel u-overflow--hidden u-marginBottom--none  u-flexJustifyContent--center">
              <div className="flex-row flex-middle-xxs">
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  {/* <img
                      className="u-displayInlineFlex u-verticalAlign--top c-iconCircle c-iconCircle--sm"
                      src="https://assets.reviews.io/img/multiplatform/logos/icon-boost.svg"
                      alt=""
                    /> */}
                  <div className="TextHeading TextHeading--xxs TextHeading--newGreen u-marginBottom--xs">
                    New Feature
                  </div>
                  <div className="TextHeading TextHeading--xs">
                    Customer Incentives
                  </div>
                  <div className="TextBody TextBody--xxs u-marginBottom--md">
                    Encourage customers to select store credits and exchanges
                    over refunds, boosting revenue, increasing profits, as well
                    as helping to build up a loyal customer base.
                  </div>
                  <div className="u-textLeft--all">
                    <a target="_blank" rel="noopener noreferrer" href="https://updates.richreturns.io/customer-incentives">
                      <div className="Button Button--xs Button--outline">
                      Learn More
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <div className="c-cardBanner">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--customer-incentives.webp 1x, https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--customer-incentives.webp 2x"
                      />
                      <img
                        className="c-cardBanner__image"
                        src="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--customer-incentives.webp"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="ContentPanel u-overflow--hidden u-marginBottom--none  u-flexJustifyContent--center">
              <div className="flex-row flex-middle-xxs">
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  {/* <img
                      className="u-displayInlineFlex u-verticalAlign--top c-iconCircle c-iconCircle--sm"
                      src="https://assets.reviews.io/img/multiplatform/logos/icon-boost.svg"
                      alt=""
                    /> */}
                  <div className="TextHeading TextHeading--xxs TextHeading--newGreen u-marginBottom--xs">
                    New Integration
                  </div>
                  <div className="TextHeading TextHeading--xs">
                    Gorgias Integration Launched
                  </div>
                  <div className="TextBody TextBody--xxs u-marginBottom--md">
                    Encourage customers to select store credits and exchanges
                    over refunds, boosting revenue, increasing profits, as well
                    as helping to build up a loyal customer base.
                  </div>
                  <div className="u-textLeft--all">
                    <a target="_blank" rel="noopener noreferrer" href="https://support.richreturns.io/en/articles/9272195-gorgias-setup-integration">
                      <div className="Button Button--xs Button--outline">
                      Learn More
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <div className="c-cardBanner">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--gorgias-integration.webp 1x, https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--gorgias-integration.webp 2x"
                      />
                      <img
                        className="c-cardBanner__image"
                        src="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--gorgias-integration.webp"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="ContentPanel u-overflow--hidden u-marginBottom--none  u-flexJustifyContent--center">
              <div className="flex-row flex-middle-xxs">
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <img
                    className="u-displayInlineFlex u-verticalAlign--top c-iconCircle c-iconCircle--sm"
                    src="https://assets.reviews.io/img/multiplatform/logos/icon-viralsweep.svg"
                    alt=""
                  />
                  <div className="TextHeading TextHeading--xs">
                    Elevate Customer Engagement
                  </div>
                  <div className="TextBody TextBody--xxs u-marginBottom--md">
                    Supercharge your store's reach & sales by creating viral
                    giveaways, contests, sweepstakes & raffles with ViralSweep.
                  </div>
                  <div className="u-textLeft--all">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://apps.shopify.com/viralsweep"
                    >
                      <div className="Button Button--xs Button--outline js-show-carousel-modal--viralsweep js-track-bolton-card--viralsweep--primary">
                      Learn More
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <div className="c-cardBanner">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--viralsweep.webp 1x, https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--viralsweep.webp 2x"
                      />
                      <img
                        className="c-cardBanner__image"
                        src="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--viralsweep.webp"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="ContentPanel u-overflow--hidden u-marginBottom--none  u-flexJustifyContent--center">
              <div className="flex-row flex-middle-xxs">
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <img
                    className="u-displayInlineFlex u-verticalAlign--top c-iconCircle c-iconCircle--sm"
                    src="https://assets.reviews.io/img/multiplatform/logos/icon-boost.svg"
                    alt=""
                  />
                  <div className="TextHeading TextHeading--xs">
                    Enhance Product Discovery with AI
                  </div>
                  <div className="TextBody TextBody--xxs u-marginBottom--md">
                    Drive sales & elevate AOV with superior site search,
                    recommendations, product bundling & optimized merchandising
                    powered by Boost AI.
                  </div>
                  <div className="u-textLeft--all">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://apps.shopify.com/product-filter-search"
                    >
                      <div className="Button Button--xs Button--outline js-show-carousel-modal--boost js-track-bolton-card--boost--primary">
                      Learn More
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <div className="c-cardBanner">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--boost.webp 1x, https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--boost.webp 2x"
                      />
                      <img
                        className="c-cardBanner__image"
                        src="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--boost.webp"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="ContentPanel u-overflow--hidden u-marginBottom--none  u-flexJustifyContent--center">
              <div className="flex-row flex-middle-xxs">
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <img
                    className="u-displayInlineFlex u-verticalAlign--top c-iconCircle c-iconCircle--sm"
                    src="https://assets.reviews.io/img/multiplatform/logos/icon-address-validator.svg"
                    alt=""
                  />

                  <div className="TextHeading TextHeading--xs">
                    Prevent Failed Deliveries
                  </div>
                  <div className="TextBody TextBody--xxs u-marginBottom--md">
                    Address Validator handles over 300,000 orders daily,
                    offering 120,000+ new address suggestions & preventing{' '}
                    <strong>11,000+ failed deliveries</strong>. Trusted by
                    Shopify Plus's largest stores for efficient deliveries!
                  </div>
                  <div className="u-textLeft--all">
                    <a target="_blank" rel="noopener noreferrer" href="https://apps.shopify.com/address-validator">
                      <div className="Button Button--xs Button--outline js-show-carousel-modal--address-validator js-track-bolton-card--av--primary">
                      Learn More
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <div className="c-cardBanner">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--address-validator.webp 1x, https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--address-validator.webp 2x"
                      />
                      <img
                        className="c-cardBanner__image"
                        src="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--address-validator.webp"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="ContentPanel u-overflow--hidden u-marginBottom--none  u-flexJustifyContent--center">
              <div className="flex-row flex-middle-xxs">
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <img
                    className="u-displayInlineFlex u-verticalAlign--top c-iconCircle c-iconCircle--sm"
                    src="https://assets.reviews.io/img/multiplatform/logos/icon-influence.svg"
                    alt=""
                  />

                  <div className="TextHeading TextHeading--xs">
                    Increase Customer Loyalty & AOV
                  </div>
                  <div className="TextBody TextBody--xxs u-marginBottom--md">
                    Reward your customers & turn them into fans with a loyalty
                    program. Easily set up reward, referral & VIP programs
                    within minutes.
                  </div>
                  <div className="u-textLeft--all">
                    <a target="_blank" rel="noopener noreferrer" href="https://apps.shopify.com/influence-io">
                      <div className="Button Button--xs Button--outline">
                      Learn More
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <div className="c-cardBanner">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--influence.webp 1x, https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--influence.webp 2x"
                      />
                      <img
                        className="c-cardBanner__image"
                        src="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--influence.webp"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <div className="ContentPanel u-overflow--hidden u-marginBottom--none  u-flexJustifyContent--center">
              <div className="flex-row flex-middle-xxs">
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <img
                    className="u-displayInlineFlex u-verticalAlign--top c-iconCircle c-iconCircle--sm"
                    src="https://assets.reviews.io/img/multiplatform/logos/icon-reviews.svg"
                    alt=""
                  />

                  <div className="TextHeading TextHeading--xs">REVIEWS.io</div>
                  <div className="TextBody TextBody--xxs u-marginBottom--md">
                    Build trust with potential customers by showcasing authentic
                    reviews and UGC that highlight your product's value and
                    quality.
                  </div>
                  <div className="u-textLeft--all">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://apps.shopify.com/reviews-co-uk-product-and-merchant-review-collection"
                    >
                      <div className="Button Button--xs Button--outline">
                      Learn More
                      </div>
                    </a>
                  </div>
                </div>
                <div className="flex-col-xxs-12 flex-col-xs-6">
                  <div className="c-cardBanner">
                    <picture>
                      <source
                        type="image/webp"
                        srcSet="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--ai-review-summary.webp 1x, https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--ai-review-summary.webp 2x"
                      />
                      <img
                        className="c-cardBanner__image"
                        src="https://assets.reviews.io/img/richreturns/dashboard/homepage/graphic-card-banner--ai-review-summary.webp"
                      />
                    </picture>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row flex-middle-xxs">
          <div className="flex-col-xxs-0 flex-col-xs-2"></div>

          <div className="flex-col-xxs-12 flex-col-xs-8 flex-center-xxs">
            <div className="carousel-dots"></div>
          </div>

          <div className="flex-col-xxs-12 flex-col-xs-2 flex-end-xxs">
            <div
              id="carouselPrevButton"
              data-slide="Prev"
              // ng-non-bindable
              className="IconButton IconButton--md u-marginTop--xxs"
              onClick={this.goToPrev}
            >
              <i className="ricon-thin-arrow--left IconButton__icon"></i>
            </div>
            <div
              id="carouselNextButton"
              data-slide="Next"
              // ng-non-bindable
              className="IconButton IconButton--md u-marginTop--xxs"
              onClick={this.goToNext}
            >
              <i className="ricon-thin-arrow--right IconButton__icon"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Carousel;
