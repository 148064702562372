/**
 * Component for Return / Shipping-Labels settings page in the Dashboard
 * that allows configuration of our  ShipStation
 * integrations for the user.
 *
 * @component
 */
import {
  Button,
  Col,
  Divider,
  Icon,
  Input,
  Row,
  Select,
  Tooltip,
  message,
} from "antd";
import axios from "axios";
import React, { Component } from "react";

import { IconTray } from "../../../../../components/CustomReturnsIcons";
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";

const { Option } = Select;
class ShipStation extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      carrierListShipStation: this.props.labelCarrier
        ? [
          {
            code: this.props.labelCarrier,
            name: this.props.labelCarrierName,
          },
        ]
        : [],
      ratesListShipStation: this.props.labelServicelevel
        ? [
          {
            code: this.props.labelServicelevel,
            name: this.props.labelServicelevelName,
          },
        ]
        : [],
    };
  }

  reloadCarrierList = async () => {
    if (this.props.labelApiKey == null || this.props.labelApiKey === "") {
      return message.error("Please add your API key and API secret first.", 4);
    }

    this.setState({
      loading: true,
    });

    await this.props.saveSettings();

    try {
      const response = await axios.get(
        "/api/integrations/shipstation/carriers"
      );
      if (response.data.status === "error") {
        this.setState({
          loading: false,
        });
        return message.error(
          "Error requesting carriers from ShipStation. Please try again.",
          5
        );
      }

      const carrierListShipStation = response.data.carrierListShipStation;
      const ratesListShipStation = response.data.ratesListShipStation;

      return this.setState({
        carrierListShipStation,
        ratesListShipStation,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
      });
      return message.error(
        "Error requesting carriers from ShipStation. Please try again.",
        5
      );
    }
  };

  handleCarrierChange = async (value) => {
    const carriersList = this.state.carrierListShipStation;
    const selectedCarrierName = carriersList.find(carrier => carrier.code === value)?.name;
    try {
      const selectedCarrier = value;
      const response = await axios.post(
        "/api/integrations/shipstation/servicelevels",
        {
          selectedCarrier,
        }
      );
      if (response.data.status === "error") {
        return message.error(
          "Error requesting service-levels from ShipStation. Please try again.",
          5
        );
      }

      const ratesListShipStation = response.data.ratesListShipStation;
      this.setState({
        ratesListShipStation,
        labelCarrier: value,
      });
      this.props.handleChange('labelCarrierName', selectedCarrierName);
      return this.props.handleChange("labelCarrier", value)
    } catch (err) {
      return message.error(
        "Error requesting service-levels from ShipStation. Please try again.",
        5
      );
    }
  };

  handleServicelevelChange = (value) => {
    const name = this.state.ratesListShipStation.find(
      (rate) => rate.code === value
    ).name;
    this.props.handleChange("labelServicelevelName", name)
    this.props.handleChange("labelServicelevel", value)
  };

  render() {
    const reloadCarrierListButtonDisabled =
      this.props.labelApiKey == null ||
        this.props.labelApiKey === "" ||
        this.props.labelApiSecret == null ||
        this.props.labelApiSecret === ""
        ? true
        : false;
    let ratesList;
    if (this.state.ratesListShipStation) {
      ratesList = this.state.ratesListShipStation.map((rate) => {
        const rateName = rate.name;
        const rateCode = rate.code;
        return (
          <Option value={rateCode} key={rateCode}>
            {rateName}
          </Option>
        );
      });
    }
    let carriersList;
    if (this.state.carrierListShipStation) {
      carriersList = this.state.carrierListShipStation;
      const distinctCarriers = carriersList.filter((obj, index, self) =>
        index === self.findIndex((car) =>  car.code === obj.code)
      );
      carriersList = distinctCarriers.map((carrier) => {
        const carrierName = carrier.name;
        const carrierCode = carrier.code;
        return (
          <Option value={carrierCode} key={carrierCode}>
            {carrierName}
          </Option>
        );
      });
    }
    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="top">
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle" className="u-rowGap--md">
          <Col xs={24} md={10}>
            <h4>API Key</h4>
            <span>
              Enter your API-Key here (also called Token in some cases). Click
              on the link at the top to see where to find it.
            </span>
          </Col>
          <Col xs={24} md={10}>
            <a
              href="https://ss.shipstation.com/#/settings/api"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can find here in your Shipstation account
            </a>
            <br />
            {this.props.inputFieldValidation === false &&
              <p style={{ color: "#f00000" }}>API key cannot contain "API", "test", "sandbox" or special characters</p>
            }
            <Input
              name="labelApiKey"
              onChange={(e) =>
                this.props.handleChange("labelApiKey", e.target.value)
              }
              value={this.props.labelApiKey}
              className="u-marginRight--none u-marginTop--sm"
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle" className="u-rowGap--md">
          <Col xs={24} md={10}>
            <h4>API Secret</h4>
            <span>Enter your API-Secret here.</span>
          </Col>
          <Col xs={24} md={10}>
            <Input
              // type = "password"
              value={this.props.labelApiSecret}
              onChange={(e) =>
                this.props.handleChange("labelApiSecret", e.target.value)
              }
              className="u-marginRight--none"
            />
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="top">
          <Col span={24}>
            <Divider />
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="middle" className="u-rowGap--md">
          <Col xs={24} md={10}></Col>
          <Col xs={24} md={10}>
            {reloadCarrierListButtonDisabled ? (
              <Tooltip title="Enter API key and API secret and save at the bottom of the dialog first.">
                <Button
                  type="primary"
                  disabled={reloadCarrierListButtonDisabled}
                >
                  <Icon type="reload" /> Refresh carrier list
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="primary"
                onClick={this.reloadCarrierList.bind(this)}
                loading={this.state.loading}
                disabled={reloadCarrierListButtonDisabled}
              >
                <Icon type="reload" /> Refresh carrier list
              </Button>
            )}
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="middle" className="u-rowGap--md">
          <Col xs={24} md={10}>
            <h4>Carrier</h4>
            <p>Select a carrier from your account for your labels.</p>
          </Col>
          <Col xs={24} md={10}>
            <Select
              defaultValue={this.props.labelCarrier}
              labelSelectCheapestRate
              style={{ width: "100%" }}
              onSelect={this.handleCarrierChange}
              placeholder="Select your carrier contract"
              notFoundContent={
                <div className="flex-row flex-middle-xxs flex-center-xxs">
                  <div className="flex-col-xxs-12">
                    <IconTray size="md" />
                  </div>
                  <div>
                    <p className="TextBody TextBody--xxxs">No Data</p>
                  </div>
                </div>
              }
            >
              {carriersList}
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle" className="u-rowGap--md">
          <Col xs={24} md={10}>
            <h4>Service</h4>
            <span>
              Select a default rate. This rate will be used for all
              prepaid-labels.
            </span>
          </Col>
          <Col xs={24} md={10}>
            <Select
              name="labelServicelevel"
              style={{ width: "100%" }}
              value={this.props.labelServicelevel ?? undefined}
              onChange={this.handleServicelevelChange.bind(this)}
              placeholder="Select the rate for labels"
              notFoundContent={
                <div className="flex-row flex-middle-xxs flex-center-xxs">
                  <div className="flex-col-xxs-12">
                    <IconTray size="md" />
                  </div>
                  <div>
                    <p className="TextBody TextBody--xxxs">
                                  No Data
                    </p>
                  </div>
                </div>
              }
            >
              {ratesList}
            </Select>
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="top">
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ShipStation;
