/**
 * Component for Advanced-Rule Conditions settings
 *
 * @component
 */

import React, { Component } from 'react';
import { Row, Col, Select, Checkbox } from 'antd';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import RuleConditionSection from './RuleConditionSection';

const { Option } = Select;

class RuleCondition extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Row type="flex" align="top">
          <Col span={24}>
            <div className="ContentPanel">
              <h3 className="u-marginBottom--md">Rule condition</h3>
              <div className="flex-row u-rowGap--md">
                <div className="flex-col-xxs-12">
                  <RuleConditionSection props={this.props} />
                </div>
                <div className="flex-col-xxs-12">
                  <Checkbox
                    checked={
                      this.props.selectedRule.enableMultipleRuleCondition
                    }
                    onChange={(e) =>
                      this.props.updateInput(e, 'enableMultipleRuleCondition')
                    }
                  >
                    Enable checkbox to add multiple conditions
                  </Checkbox>
                  {this.props.selectedRule.enableMultipleRuleCondition && (
                    <>
                      <Select
                        defaultValue={
                          this.props.selectedRule?.secondaryConditionOperator
                            ? this.props.selectedRule.secondaryConditionOperator
                            : 'AND'
                        }
                        style={{ marginBottom: 10, marginTop: 10 }}
                        onChange={(e) =>
                          this.props.updateInput(
                            e,
                            'secondaryConditionOperator'
                          )
                        }
                      >
                        <Option value="AND" key="AND">AND</Option>
                        <Option value="OR" key="OR">OR</Option>
                      </Select>
                    </>
                  )}
                  {this.props.selectedRule.enableMultipleRuleCondition && (
                    <RuleConditionSection props={this.props} secondary={true} />
                  )}
                </div>
              </div>
            </div>
          </Col>
          {/* <Card
              title={
                <React.Fragment>
                  <span>Rule Condition</span>
                  <Text type="secondary">
                    {' '}
                    - What has to be true for the rule to trigger?
                  </Text>
                </React.Fragment>
              }
              headStyle={{ textAlign: 'center', backgroundColor: '#F8F8F8' }}
              style={{ marginBottom: 20 }}
            >
              <Row type="flex" align="top" style={{ marginBottom: 20 }}></Row>
              <Row type="flex" align="top" style={{ marginBottom: 20 }}>
                {this.props.selectedRule.enableMultipleRuleCondition && (
                  <React.Fragment>
                    <Col></Col>
                    <RuleConditionSection props={this.props} secondary={true} />
                  </React.Fragment>
                )}
              </Row>
            </Card> */}
        </Row>
      </React.Fragment>
    );
  }
}

export default RuleCondition;
