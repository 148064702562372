import React from "react";
import { Row, Col, Card, Divider, Result, Button } from "antd";
import { FormattedMessage } from "react-intl";
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios';

class PaymentCancel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      companyIdentifier: '',
      returnNumber: '',
    }
  }

  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);
    const companyIdentifier = urlObject.searchParams.get("identifier");
    const returnNumber = urlObject.searchParams.get("returnNumber")
    if (companyIdentifier && returnNumber) {
      this.setState({
        companyIdentifier,
        returnNumber
      });
    }
  }

  handlePayments = async () => {
    this.setState({
      loading: true,
    })
    try{
      const stripe = await loadStripe(
        this.props.returnSettings.stripePublishableKey
      )
      const response = await axios.post('/api/visitors/return/createSession', {
        companyIdentifier: this.state.companyIdentifier,
        returnNumber: this.state.returnNumber,
      })
      stripe.redirectToCheckout({
        sessionId: response.data.sessionId,
      })
    } catch(err) {
      console.log('Error while creating stripe checkout',err)
    }
  }

  render() {
    return(
      <React.Fragment>
        <Card
          title={
            <FormattedMessage
              id="app.visitor.status.headline"
              description="Title of the last step of the return-portal process 'status page'"
              defaultMessage="Summary of your Payment"
            />
          }
          className="textCenter"
        >
          <Row type="flex" justify="center" align="top" className="textCenter">
            <Col>
              {
                <Result
                  status="warning"
                  title={
                    <FormattedMessage
                      id="app.visitor.payment.canceled"
                      description="Payment cancelation message'"
                      defaultMessage="You canceled the payment. Do you want to proceed without paying?"
                    />
                  }
                />
              }
            </Col>
          </Row>
          <Button
            type="primary"
            onClick={() => this.props.history.push(`/return/status?identifier=${this.state.companyIdentifier}&returnNumber=${this.state.returnNumber}`)}
            style={{ backgroundColor: this.props.brandColorButton }}
          >
            <span
              style={{
                color: this.props.brandColorButtonText,
                fontStyle: this.props.brandFontStyle,
              }}
            >
              Proceed
            </span>
          </Button>

          <Button
            type="primary"
            style={{
              backgroundColor: this.props.brandColorButton,
              marginLeft: 20,
            }}
            onClick={this.handlePayments}
          >
            <span
              style={{
                color: this.props.brandColorButtonText,
                fontStyle: this.props.brandFontStyle,
              }}
            >
              Retry
            </span>
          </Button>
          <Divider />
          <Row type="flex" justify="center" align="top">
            <Col>
              <p>
                <FormattedMessage
                  id="app.visitor.status.contact"
                  description="Contact info for customer"
                  defaultMessage="If you need a helping hand contact us at "
                /> {" "}
                <a style= {{ textDecoration: "underline" }} href={"mailto:" + this.props.contactEmail}>{this.props.contactEmail}</a>.
              </p>
            </Col>
          </Row>
        </Card>
      </React.Fragment>
    );
  }
}

export default PaymentCancel;
