import React, { Component } from "react";
import "../styles/login.css";

import LoginForm from "../pages/dashboard/login/login";

class LoginScreen extends Component {
  render() {
    return (
      <LoginForm />
    );
  }
}

export default LoginScreen;
