/**
 * Component for our Analytics Customer page
 * used to show the return products by a customer
 * @component
 */

import { Col, Divider, Row, Table, Typography, message } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { IconTray } from '../../../../components/CustomReturnsIcons';
import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
const { Text } = Typography;

class ReturnProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  static contextType = SubscriptionContext;
  async componentDidMount() {
    if (this.props.customerId) {
      try {
        const response = await axios.get(
          `/api/analytics/customers/${this.props.customerId}/products`
        );
        const products = [...response.data.data];
        this.setState({
          data: products,
        });
      } catch (err) {
        return message.error(
          'something went wrong in getting the customer details',
          5
        );
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.customerId !== this.props.customerId) {
      try {
        const response = await axios.get(
          `/api/analytics/customers/${this.props.customerId}/products`
        );
        const products = [...response.data.data];
        this.setState({
          data: products,
        });
      } catch (err) {
        return message.error(
          'something went wrong in getting the customer details',
          5
        );
      }
    }
  }

  render() {

    const dataSource = this.state.data;
    const columns = [
      {
        title: 'Order Name',
        dataIndex: 'Return.orderName',
      },
      {
        title: 'Return Number',
        dataIndex: 'Return.returnNumber',
      },
      {
        title: 'Product Name',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
    ];

    return (
      <React.Fragment>
        {this.context.featureAnalyticsAdvanced ? (
          <React.Fragment>
            <div className="u-marginBottom--md">
              <Text style={{ fontSize: 14 }}>
                This report shows how many products were returned by the
                customer. Latest Returns on top.
              </Text>
            </div>
            {/* <Row type="flex" justify="start" align="top">
              <Col>
                <Tooltip
                  placement="bottom"
                  title="This report shows how many products were returned by the customer. Latest Returns on top."
                >
                  <Icon style={{ paddingTop: "13px" }} type="info-circle" />
                </Tooltip>
              </Col>
              <Col>
                &nbsp;
                <span className="TextHeading TextHeading--sm">Customer Returned Products</span>
              </Col>
            </Row> */}

            {/* <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ paddingTop: 5 }}
            >
              <Tooltip
                placement="top"
                title="Search for a customer by using their first name, last name or email address. Only customers with at least one Return in Rich Returns will show up as a result."
              >
                <Select
                  showSearch
                  value={this.props.customerNameEmail}
                  style={{ width: 350 }}
                  placeholder="Search for a customer"
                  onChange={onChange}
                  onSearch={(value)=>this.props.onSearch(value)}
                  filterOption={false}
                >
                  {this.props.matchingCustomers &&
                  this.props.matchingCustomers.length > 0
                    ? this.props.matchingCustomers.map((value) => {
                      return (
                        <Option value={value.customerId}>
                          {`${value.shippingName} (${value.customerEmail})`}
                        </Option>
                      );
                    })
                    : null}
                </Select>
              </Tooltip>
              
            </Row>


            <br /> */}
            <div style={{ overflowX: 'auto' }}>
              <div style={{ minWidth: 768 }}>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  locale={{
                    emptyText: (
                      <div className="flex-row flex-middle-xxs flex-center-xxs">
                        <div className="flex-col-xxs-12">
                          <IconTray size="md" />
                        </div>
                        <div>
                          <p className="TextBody TextBody--xxxs">No Data</p>
                        </div>
                      </div>
                    ),
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Divider />
              </Col>
              <Upgrade
                title="Business Intelligence at your fingertips."
                description="Choose a plan with access to Reports. Explore insights into why customers return products and make smarter business decisions."
                videoId="VjbdXeknVMM"
              />
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ReturnProducts;
