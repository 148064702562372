/**
 * Component for our Manual-Returns feature
 * ("Multichannel Return") that is only
 * available to selected accounts. Adds a button
 * "Add RMA" to our main Returns-Dashboard.
 * 
 * @component
 */

import React, { Component } from "react";
import { Drawer, Button } from "antd";
import ManualReturnForm from "./ManualReturnForm";

class ManualReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: false
    };
  }
  showDrawer() {
    this.setState({
      drawerVisible: true
    });
  }

    hideDrawer = () => {
      this.setState({
        drawerVisible: false
      });
    }

    render() {
      return(
        <React.Fragment>
          <Button onClick={this.showDrawer.bind(this)} style={{ marginRight: 15 }}>Add RMA</Button>
          {
            this.state.drawerVisible
              ? (
                <Drawer
                  title="Add a Multichannel-RMA"
                  onClose={this.hideDrawer}
                  visible={this.state.drawerVisible}
                  width="40%"
                >
                  <ManualReturnForm
                    hideDrawer={this.hideDrawer.bind(this)}
                    updateData={ this.props.updateData.bind(this) }
                    setVisibilityOfResetSearchButton={this.props.setVisibilityOfResetSearchButton.bind(this)}
                  />
                </Drawer>
              )
              : null
          }
        </React.Fragment>
      )
    }
}

export default ManualReturn;