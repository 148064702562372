/**
 * Component for displaying notes that staff left
 * on the Return.
 *
 * @component
 */

import { Button, Input, message } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';

const { TextArea } = Input;
class ReturnDetailsNoteStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffNotes: this.props.staffNotes,
      notesKey: undefined,
    };
  }
  updateStaffNotesInState = (e) => {
    const staffNotes = e.target.value;
    this.setState({
      staffNotes,
      notesKey: Date.now(),
    });
  };

  saveStaffNotes = async () => {
    let staffNotes = this.state.staffNotes;

    if (staffNotes == null || staffNotes === '') {
      staffNotes = null;
    }

    try {
      const response = await axios.post('/api/returns/updateNotes', {
        staffNotes,
        returnId: this.props.returnId,
      });
      if (response.data.status === 'error') {
        return message.error(
          'There was an error saving your note. Please try again.',
          4
        );
      }

      this.props.handleGetReturn();

      return message.success('Notes saved successfully.', 3);
    } catch (err) {
      return message.error(
        'There was an error saving your note. Please try again.',
        4
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
          <div className="flex-col-xxs-12">
            <strong className="TextHeading TextHeading--xxxs u-marginBottom--none">Staff notes</strong>
          </div>
          <div className="flex-col-xxs-12">
            <TextArea
              rows={4}
              placeholder="Internal notes. Not visible to the customer"
              defaultValue={this.props.returnObject.staffNotes}
              onChange={this.updateStaffNotesInState}
            />
          </div>
          <div className="flex-col-xxs-12 flex-end-xxs">
            <Button
              onClick={this.saveStaffNotes.bind(this)}
              // icon="save"
              type="ghost"
              // style={{ padding: 'var(--input-padding)' }}
            >
              Save notes
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReturnDetailsNoteStaff;
