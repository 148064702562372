const keyLocalStorage = "locale";

export const getLocale = (listTranslationCode) => {
  const url = window.location.href;
  const urlObject = new URL(url);
  const locale = urlObject.searchParams.get("locale");
  let urlLocale;
  if (locale && locale.length === 2) {
    urlLocale = locale.toLowerCase();
  }

  // Get locale from local storage
  const storageLocale = localStorage.getItem(keyLocalStorage);
  const browserLocale = navigator.language.split(/[-_]/)[0];
  let result = urlLocale || storageLocale || browserLocale;
  // exception for Norwegian that has 3 different language codes: no, nb, nn
  let isNorwegian = result === "nb" || result === "nn";

  if (isNorwegian) {
    result = "no";
  }
  if (listTranslationCode && listTranslationCode.length > 0 && !listTranslationCode.includes(result)) {
    result = listTranslationCode[0]
    setLocale(result);
  }
  return result;
};

export const setLocale = (locale) => {
  localStorage.setItem(keyLocalStorage, locale);
}