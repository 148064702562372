/**
 * Component for Subscriptions Page in the Dashboard. Loads
 * a page with our plans and details on the subscription
 * such as current number of Returns in the billing period.
 *
 * @component
 */

import React, { Component } from 'react';
import Plans from './components/Plans';
import { PageButtonHeader } from '../../../../components/CustomHeader';

class UserAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <PageButtonHeader headingTitle={'Your subscription plan'}></PageButtonHeader>
        <Plans />
      </React.Fragment>
    );
  }
}

export default UserAccount;
