/**
 * Component for our Advanced-Exchanges feature
 * that loads product details for the selected
 * Exchange-Product.
 * 
 * @component
 */

import React from 'react';
import axios from 'axios';
import { Spin, Row, Col, Select, Button, message } from 'antd';
import { placeholderImageUrl } from '../../../constants/constants';
import { FormattedMessage } from "react-intl";


const { Option } = Select;
class showProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      product: undefined,
      variantId: undefined,
      variantTitle: '',
    };
  }

  async componentDidMount() {
    const { companyIdentifier, returnNumber, currentProduct } = this.props;
    try {
      const url = this.props.isMerchantDashboard ? `/api/returns/advancedExchangeproducts/${this.props.productId}` : `/api/visitors/return/exchange/advanced/products/${this.props.productId}`
      const response = await axios.post(url,
        {
          companyIdentifier,
          returnNumber,
          currentProduct
        }
      );
      const productImage = response?.data?.product.image?.src ? response.data.product.image.src : placeholderImageUrl

      if (response.data) {
        this.setState({ product: response.data.product, loading: false, selectedImage: productImage });
      }
    } catch (err) {
      this.setState({ loading: false });

      return message.error('Error fetching products. Please try again.', 5);
    }
  }
  onChange(variantId) {
    let variant = this.state.product.variants.find(
      (variant) => variant.id === variantId
    );
    let variantTitle = variant ? variant.title : '';
    let variantImage = this.state.product.images.find(image => image.id === variant.image_id)
    variantImage = variantImage?.src ? variantImage?.src : this.state.product?.image?.src
    variantImage = variantImage ? variantImage : placeholderImageUrl
    this.setState({ variantId, variantTitle, selectedImage: variantImage });
  }

  handleSubmit = () => {
    const {
      variantId,
      variantTitle,
      product: {
        id: productId,
        title: productName,
      },
    } = this.state;

    if (variantId) {
      this.props.updateExchangedProduct({
        lineItemIdToBeExchanged: this.props.lineItemIdToBeExchanged,
        productId,
        productName,
        variantId,
        variantTitle,
      });
      this.props.showProductDetails(false);
    } else {
      return message.error('Please select a variant.', 5);
    }
  };

  handleCancel() {
    this.props.showProductDetails(false);
  }

  render() {
    const { intl } = this.props;
    return (
      <React.Fragment>
        {this.state.loading ? (
          <Row type='flex' justify='space-around' align='middle'>
            <Spin
              spinning={this.state.loading}
              style={{ paddingTop: '45px' }}
            ></Spin>
          </Row>
        ) : (
          <React.Fragment>
            <Row type='flex' justify='space-around' align='middle'>
              <Col span={24}>
                {this.state.product && (
                  <img width={'100%'} src={this.state.selectedImage} />
                )}
              </Col>
            </Row>
            <Row
              type='flex'
              justify='space-around'
              align='middle'
              style={{ padding: '10px 5px 0' }}
            >
              <Col span={24}>
                <p>{this.state.product && this.state.product.title}</p>
              </Col>
            </Row>
            <Row type='flex' justify='space-around' align='middle'>
              <Col span={24}>
                {this.state.product && (
                  <Select
                    style={{ width: '100%' }}
                    placeholder={this.props.isMerchantDashboard ? "Select a Variant": intl.formatMessage({
                      id:"app.visitor.advanceExchange.selectVariant",
                      description:"Select variant placeholder in advanced exchange modal",
                      defaultMessage:'Select a Variant',
                    })}                    
                    optionFilterProp='children'
                    onChange={this.onChange.bind(this)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.product.variants.map((variant) => (
                      <Option value={variant.id} key={variant.id} disabled={variant.disabled} style={{color: variant.disabled && 'rgba(0, 0, 0, 0.25)'}}>
                        {variant.disabledReason ? `${variant.title} (${variant.disabledReason})` : `${variant.title}`}
                      </Option>
                    ))}
                  </Select>
                )}
              </Col>
            </Row>
            <Row
              type='flex'
              justify='space-around'
              align='middle'
              style={{
                width: '100%',
                paddingTop: '15px',
              }}
            >
              <Col span={24}>
                <Button
                  onClick={this.handleSubmit.bind(this)}
                  size='large'
                  type='primary'
                  block
                >
                  {this.props.isMerchantDashboard ? "Confirm Item" :
                    <FormattedMessage
                      id="app.visitor.advancedExchange.confirm"
                      description="Confirm button in advance exchnage modal"
                      defaultMessage="Confirm Item"
                    />
                  }
                </Button>
              </Col>
            </Row>
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'left',
              }}
            >
              <Button
                onClick={this.handleCancel.bind(this)}
                style={{ marginRight: 10 }}
              >
                {this.props.isMerchantDashboard ? "Go back" :
                  <FormattedMessage
                    id="app.visitor.order.buttonGoBack"
                    description="Button to go back one screen"
                    defaultMessage="Go back"
                  />
                }
              </Button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default showProductDetails;
