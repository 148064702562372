/**
 * Component to render a spinner for our Authentication screens
 * that involve some sort of redirect and waiting time for the user.
 * 
 * @component
 */

import { Button, Col, Layout, Row, Spin } from 'antd';
import { Component } from 'react';
import { Tooltip } from '../../node_modules/antd/lib/index';
import { LogoContainer } from '../pages/dashboard/index';
import Navigation from '../pages/dashboard/navigation/Navigation';
import '../styles/App.css';

const { Header, Content, Sider } = Layout;

class AuthenticationLoadingScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  render() {
    return (
      <Layout>
        <Sider
          theme="light"
          breakpoint="md"
          onBreakpoint={(broken) => {
            if (broken) {
              this.setState({ collapsed: true });
            } else {
              this.setState({ collapsed: false });
            }
          }}
          collapsedWidth="0"
          className="SideBar"
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
        >
          <Navigation />
        </Sider>
        <Layout className="PageLayout" style={{ justifyContent: 'center' }}>
          <Header>
            <LogoContainer
              collapsed={this.state.collapsed}
              onClick={() =>
                this.setState({ collapsed: !this.state.collapsed })
              }
            ></LogoContainer>
            <span>
              <Tooltip
                title="Struggling with anything? Do not worry, we are here to help. Get in contact with our team now."
                placement="bottomLeft"
              >
                <Button type="primary" href="mailto:hello@richcommerce.co">
                    Need Help?
                </Button>
              </Tooltip>
            </span>
          </Header>
          <Content>
            <div className="ContentPanel" style={{ minHeight: '500px', justifyContent: 'center' }}>
              <Row type="flex">
                <Col span={24}>
                  <Row type="flex" justify="center">
                    <Spin size="large" />
                  </Row>
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }

}

export default AuthenticationLoadingScreen;
