/**
 * Form Component for our Return details sidebar that
 * lets users edit the details of a customer like
 * email, name and address.
 *
 * @component
 */

import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { SubscriptionContext } from '../../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../../helpers/fullstory';

const FormItem = Form.Item;
const { Option } = Select;

class ReturnMultipleAddress extends Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      countriesList: [],
      storeAddress: [],
      editStoreAddress: null,
      isSelectable: null,
      defaultAddress: null,
      locationName: undefined,
      fullName: undefined,
      company: undefined,
      streetAddress: undefined,
      country: undefined,
      optional: undefined,
      city: undefined,
      state: undefined,
      zipCode: undefined,
      email: undefined,
      phone: undefined,
    };
  }

  async getStoreAddresses() {
    try {
      const response = await axios.get('/api/returnSettings/multipleAddress');
      const storeAddress = response.data.getStoreAddress;
      const filterAddress = storeAddress.find((e) => {
        return e.defaultActive === true;
      });
      this.setState({
        storeAddress,
        isSelectable: filterAddress,
        defaultAddress: filterAddress.id,
      });
    } catch (err) {
      console.log('Error, Unable to get get store Address.Please try again.');
    }
  }

  async componentDidMount() {
    this.getStoreAddresses();
    this.setState({
      countriesList: this.props.countriesList,
    });
  }

  async componentWillReceiveProps() {
    if (this.props.updateProps) {
      this.getStoreAddresses();
    }
  }

  handleCancel = () => {
    this.props.showAddressModal();
    this.props.handleChangeMultipleAddress(
      this.state.defaultAddress,
      this.state.storeAddress
    );
    this.setState({
      locationName: null,
      fullName: null,
      company: null,
      streetAddress: null,
      country: null,
      optional: null,
      city: null,
      zipCode: null,
      state: null,
      email: null,
      phone: null,
    });
  };

  toggleModal = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      this.props.form.validateFields(async (err, values) => {
        if (err) {
          return;
        }
        const response = await axios.post(
          '/api/returnSettings/multipleAddress/create',
          {
            values,
          }
        );
        if (response.data.status === 'error') {
          return message.error(
            'Error, Unable to add store address, Please try again.',
            5
          );
        }

        await this.context.updateContext();

        if (response.data.status === 'Success') {
          this.props.showAddressModal();
          this.getStoreAddresses();
          trackFSEvent('Add multiple store address', {
            feature: 'Return Settings',
            numberOfStoreAddress: this.state.storeAddress
              ? this.state.storeAddress.length
              : 1,
            storeAddressName: this.state.locationName || '',
          });
          return message.success('Store Address Added Successfully.', 5);
        }
      });
    } catch (err) {
      message.error('Error, Unable to add store address, Please try again.');
    }
  };

  editStoreAddress = async (e, id) => {
    try {
      this.props.form.validateFields(async (err, values) => {
        if (err) {
          return;
        }
        const response = await axios.post(
          `/api/returnSettings/multipleAddress/edit/${id}`,
          {
            values,
          }
        );
        if (response.data.status === 'error') {
          return;
        }

        this.handleCancel();
        this.props.handleChangeMultipleAddress(
          this.state.defaultAddress,
          this.state.storeAddress
        );
        this.getStoreAddresses();

        trackFSEvent('Edit multiple store address', {
          feature: 'Return Settings',
          numberOfStoreAddress: this.state.storeAddress
            ? this.state.storeAddress.length
            : 1,
          storeAddressName: this.state.locationName || '',
        });
        return message.success('Store Address Added Successfully.', 5);
      });
    } catch (err) {
      message.error('Error, Unable to add store address, Please try again.');
    }
  };

  saveDefaultStoreAddress = async (e) => {
    try {
      e.preventDefault();
      this.props.form.validateFields(async (err, values) => {
        if (err) {
        }
        values.defaultActive = true;
        const response = await axios.post(
          '/api/returnSettings/multipleAddress/create',
          {
            values,
          }
        );
        if (response.data.status === 'error') {
          return message.error(
            'Error, Unable to add store address, Please try again.',
            5
          );
        }
        await this.context.updateContext();

        if (response.data.status === 'Success') {
          this.props.showAddressModal();
          this.props.getReturnSettings();
          this.getStoreAddresses();
          return message.success('Store Address Added Successfully.', 5);
        }
      });
    } catch (err) {
      message.error('Error, Unable to add store address, Please try again.');
    }
  };

  handleEditStoreAddress = async (id) => {
    this.props.showAddressModal();

    const storeAddress = this.state.storeAddress.filter((e) => {
      return e.id === id;
    });

    this.setState({
      isSelectable: id,
      editStoreAddress: id,
    });

    storeAddress &&
      storeAddress.map((address) => {
        this.setState({
          locationName: address.locationName,
          fullName: address.fullName,
          company: address.company,
          streetAddress: address.streetAddress,
          country: address.country,
          optional: address.optional,
          city: address.city,
          zipCode: address.zipCode,
          state: address.state,
          email: address.email,
          phone: address.phone,
        });
      });
  };

  deleteStoreAddress = async (id) => {
    try {
      const response = await axios.post(
        `/api/returnSettings/multipleAddress/remove/${id}`
      );

      if (response.data.status === 'error') {
        return message.error(
          'Error, Unable to remove store address, Please try again.',
          5
        );
      }
      trackFSEvent('Delete multiple store address', {
        feature: 'Return Settings',
        numberOfStoreAddress:
          this.state.storeAddress && this.state.storeAddress.length > 1
            ? this.state.storeAddress.length - 1
            : 0,
        storeAddressName: this.state.locationName || '',
      });
      await this.context.updateContext();
      this.getStoreAddresses();
      this.props.getReturnSettings();
      this.props.handleChangeMultipleAddress(
        this.state.defaultAddress,
        this.state.storeAddress
      );
      return message.success('Store Address remove successfully.', 5);
    } catch (err) {
      message.error('Error, unable to delete store address.', 5);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <Select
          placeholder="Select Address"
          value={
            this.props.defaultActiveStoreAddress
              ? this.props.defaultActiveStoreAddress
              : this.state.isSelectable && this.state.isSelectable.id
          }
          onChange={(e) =>
            this.props.handleChangeMultipleAddress(e, this.state.storeAddress)
          }
        >
          {this.state.storeAddress &&
            this.state.storeAddress.length > 0 &&
            this.state.storeAddress.map((address) => (
              <Option value={address.id} key={address.id}>
                {address.locationName}

                {this.props.defaultActiveStoreAddress !== address.id &&
                  !address.defaultActive && (
                  <div style={{ float: 'right' }}>
                    <Icon
                      type="edit"
                      theme="twoTone"
                      onClick={() => this.handleEditStoreAddress(address.id)}
                    />
                      &#160; &#160;
                    <Popconfirm
                      title="Are you sure delete this store address?"
                      onConfirm={() => this.deleteStoreAddress(address.id)}
                      onCancel={() => this.handleCancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Icon
                        type="delete"
                        theme="twoTone"
                        twoToneColor="#FF0000"
                      />
                    </Popconfirm>
                  </div>
                )}
              </Option>
            ))}
        </Select>
        {this.props.visible && (
          <Modal
            title={
              this.state.editStoreAddress
                ? 'Update Customer shipping details'
                : 'Add Customer shipping details'
            }
            visible={true}
            onCancel={this.handleCancel}
            footer={[
              <Button
                key="back"
                onClick={this.handleCancel}
                className="u-marginTop--md"
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={this.state.loading}
                onClick={
                  this.state.editStoreAddress
                    ? (e) =>
                      this.editStoreAddress(e, this.state.editStoreAddress)
                    : (e) => this.handleSubmit(e)
                }
                className="u-marginTop--md"
              >
                Save changes
              </Button>,
              !this.state.editStoreAddress && (
                <Button
                  key="defaultSave"
                  type="primary"
                  onClick={this.saveDefaultStoreAddress}
                  className="u-marginTop--md"
                >
                  Save as default
                </Button>
              ),
            ]}
          >
            <Form>
              <Row>
                <Col span={24}>
                  <h4>Select country</h4>
                  <FormItem>
                    {getFieldDecorator('country', {
                      initialValue: this.state.country,
                      rules: [{ required: true, message: 'Country is required' }],
                    })(
                      <Select
                        placeholder="Select country"
                        className="u-marginBottom--sm"
                      >
                        {this.state.countriesList}
                      </Select>
                    )}
                  </FormItem>
                  <h4>Store name</h4>
                  <FormItem>
                    {getFieldDecorator('locationName', {
                      initialValue: this.state.locationName,
                      rules: [{ required: true, message: 'Store name is required' }],
                    })(
                      <Input
                        placeholder="Store name"
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                  <h4>Full name</h4>
                  <FormItem>
                    {getFieldDecorator('fullName', {
                      initialValue: this.state.fullName,
                      rules: [{ required: true, message: 'Full name is required' }],
                    })(
                      <Input
                        placeholder="Full name"
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                  <h4>Company</h4>
                  <FormItem>
                    {getFieldDecorator('company', {
                      initialValue: this.state.company,
                    })(
                      <Input
                        placeholder="(Optional)"
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                  <h4>Address</h4>
                  <FormItem>
                    {getFieldDecorator('streetAddress', {
                      initialValue: this.state.streetAddress,
                      rules: [{ required: true, message: 'Address is required' }],
                    })(
                      <Input
                        placeholder="Street and number, P.O. box, c/o."
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                  <h4>Address line 2</h4>
                  <FormItem>
                    {getFieldDecorator('optional', {
                      initialValue: this.state.optional,
                    })(
                      <Input
                        placeholder="Apartment, suite, unit, building, floor, etc."
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                  <h4>City</h4>
                  <FormItem>
                    {getFieldDecorator('city', {
                      initialValue: this.state.city,
                      rules: [{ required: true, message: 'City is required' }],
                    })(
                      <Input
                        placeholder="City"
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                  <h4>State / Province / Region</h4>
                  <FormItem>
                    {getFieldDecorator('state', {
                      initialValue: this.state.state,
                      rules: [{ validator: this.handleStateValidator }],
                    })(
                      <Input
                        placeholder="State / Province / Region"
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                  <h4>Zip code</h4>
                  <FormItem>
                    {getFieldDecorator('zipCode', {
                      initialValue: this.state.zipCode,
                      rules: [{ required: true, message: 'Zip code is required' }],
                    })(
                      <Input
                        placeholder="Zip Code"
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                  <h4>Phone</h4>
                  <FormItem>
                    {getFieldDecorator('phone', {
                      initialValue: this.state.phone,
                      rules: [{ required: true, message: 'Phone is required' }],
                    })(
                      <Input
                        placeholder="May be used to assist delivery"
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                  <h4>Email</h4>
                  <FormItem>
                    {getFieldDecorator('email', {
                      initialValue: this.state.email,
                      rules: [{ required: true, message: 'Email is required' }],
                    })(
                      <Input
                        placeholder="May be used to assist delivery"
                        className="u-marginBottom--sm"
                      />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const WrappedReturnDetailsCustomerForm = Form.create()(ReturnMultipleAddress);
export default WrappedReturnDetailsCustomerForm;
