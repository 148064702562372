import { Button, Row } from 'antd';
import { SolidIconArrowLeft } from '../components/CustomReturnsIcons';

/**
 *  Rich Returns Custom Header
 *
 *  This file is dedicated to storing custom a custom
 *  header which can be used at top of any page layout.
 *
 *  Authors:
 *
 *  Don Kvasys
 *  Rohan Mistry
 *
 */

export const PageButtonHeader = ({
  showButton,
  onClick,
  headingTitle,
  headingRightContent,
}) => {
  // By default, the button will go back to the previous page was on in the Dashboard.
  const goBack = () => {
    window.history.back();
  };

  return (
    <Row
      type="flex"
      justify="space-between"
      style={{
        alignItems: 'center',
        marginBottom: '10px',
        gap: '10px'
      }}
    >
      <Row
        type="flex"
        style={{
          alignItems: 'center',
          columnGap: '20px',
          flexFlow: 'nowrap',
        }}
      >
        {showButton ? (
          <Button
            type="default"
            ghost
            onClick={onClick ? onclick : goBack}
            className="u-marginBottom--sm"
          >
            <SolidIconArrowLeft size="lg"></SolidIconArrowLeft>
          </Button>
        ) : null}
        <div className='TextHeading TextHeading--sm u-whiteSpace--noWrap u-marginBottom--sm'
        >
          {headingTitle}
        </div>
      </Row>
      {headingRightContent ? (
        <div className="u-marginBottom--sm">{headingRightContent}</div>
      ) : (
        null
      )}
    </Row>
  );
};
