/**
 * Component to welcome the user after they granted us
 * access in Shopify's permission dialog. We then try to
 * initiate OAuth/authentication process and redirect
 * the user to the main Dashboard if successful.
 * 
 * Implementation applies to our Shopify Public-App in the App-Store.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Button, Row, Col,message, Card, Result} from 'antd';
// import AuthenticationLoadingScreen from "../../../../../components/AuthenticationLoadingScreen"

class AppHubAppConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectAppStore: false,
    };
  }

  async componentDidMount() {

    const url = window.location.href;
    const urlObject = new URL(url);
    const code = urlObject.searchParams.get("code");
    const state = urlObject.searchParams.get("state");
    // Shopify only
    const hmac = urlObject.searchParams.get("hmac");
    const shop = urlObject.searchParams.get("shop");
    const timestamp = urlObject.searchParams.get("timestamp");
    const host = urlObject.searchParams.get("host");
    if (code == null) {
      return;
    }

    const userData = atob(localStorage.getItem(state))
    localStorage.removeItem(state);

    try {
      const response = await axios.post("/api/integrations/apphub/boltOns", {
        code,
        hmac,
        host,
        shop,
        state,
        timestamp,
        userData,
      });
      if (response.status === 200) {
        return this.setState({
          redirectAppStore: true,
        });
      }
      
    } catch (err) {
      message.error("Error connecting your Shopify account.", err);
    }
  }

  render() {
    if (this.state.redirectAppStore) {
      return <Redirect to="/dashboard" />;
    }

    return(
      <React.Fragment>
        <Card
          title="Pending Subscription"
          className="textCenter"
        >
          <Row type="flex" justify="center" align="top" className="textCenter">
            <Col>
              {
                <Result
                  status="warning"
                  title="Please update your subscription from Reviews.io"
                />
              }
            </Col>
          </Row>
          <a href='https://dash.reviews.io/subscription' target="_blank" style={{color: "white"}}>
            <Button
              type="primary"
              style={{ backgroundColor: this.props.brandColorButton }}
            >
            Proceed
            </Button>
          </a>
        </Card>
      </React.Fragment>
    );
  }
}

export default AppHubAppConfirm;
