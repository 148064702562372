/**
 * Component for our Graph on the volume of overall Returns.
 * 
 * @component
 */

import React from "react";
import VolumeGraph from "../VolumeGraph";
class ReturnVolume extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <VolumeGraph
        url="/api/analytics/return-volume-by-date"
        title="Return Volume"
        tooltip="Shows the number of Returns requested per day."
        config={{
          xField: "date",
          yField: "volume",
          xAxis: { tickCount: 5 },
          yAxis: { tickInterval: 1 },
          smooth: true,
        }}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        updateDate={this.props.updateDate}
      />
    );
  }
}

export default ReturnVolume;
