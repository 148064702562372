/**
 * Component for email based logins.
 *
 * @component
 */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Form, Icon, Input, Button, message } from 'antd';
import { getAppInfo } from '../../../helpers/appInfo';
import '../../../styles/login.css';
import '../../../styles/App.css';
const FormItem = Form.Item;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      redirect: false,
      isProductionApp: false,
    };
  }

  componentDidMount = async () => {
    try {
      const info = await getAppInfo();
      if (info?.isProductionApp) {
        this.setState({
          isProductionApp: info.isProductionApp,
        });
      }
    } catch (err) {
      message.error('error fetching clientId of application');
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          let response = await axios.post('/auth/login', {
            username: values.username,
            password: values.password,
          });
          if (response.data.error) {
            return message.error('Username unknown or wrong password.', 5);
          }
          return this.setState({ redirect: true });
        } catch (err) {
          return message.error('Error while logging you in.', 5);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.redirect) {
      return <Redirect push to="/dashboard" />;
    }

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <div
            className="u-PageHeader"
            style={{
              marginBottom: 30,
              fontWeight: 'bold',
            }}
          >
            Welcome Back 👋
          </div>
          <div className="flex-row flex-row--noMargin" style={{ gap: 20 }}>
            {!this.state.isProductionApp && (
              <FormItem>
                {getFieldDecorator('username', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your username or user id',
                    },
                  ],
                })(
                  <Input
                    size="large"
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="text"
                    placeholder="Username or user id"
                  />
                )}
              </FormItem>
            )}
            {this.state.isProductionApp && (
              <FormItem>
                {getFieldDecorator('username', {
                  rules: [
                    { required: true, message: 'Please enter your email' },
                  ],
                })(
                  <Input
                    size="large"
                    type="email"
                    placeholder="Your email address"
                  />
                )}
              </FormItem>
            )}
            <FormItem>
              {getFieldDecorator('password', {
                rules: [{ required: true, message: 'Please enter password' }],
              })(<Input size="large" type="password" placeholder="Password" />)}
            </FormItem>
            <FormItem className="textCenter">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Login
              </Button>
            </FormItem>
            <div
              className="flex-row flex-row--noMargin"
              style={{
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div>
                Don't have a Rich Returns account?{' '}
                <a href="https://www.richreturns.io/pricing">Sign Up</a>
              </div>
            </div>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedLoginForm = Form.create()(LoginForm);
export default WrappedLoginForm;
