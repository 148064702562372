import { Card, Col, Icon, message, Row, Tag, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import Carousel from './carousel';
import { trackFSEvent } from '../../../helpers/fullstory';

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverLatestUpdatesButton: false,
      loading: true,
      returnRate: '-',
      numberOfTotalReturns: '-',
      currency: '-',
      totalReturnValue: '-',
      changeInPercent: {},
    };
  }

  componentDidMount() {
    this.initializeComponent();
  }

  initializeComponent = async () => {
    try {
      await this.loadReturnsData();
      await this.loadNPSScript();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error fetching data. Please try again.', 5);
    }
  };

  async loadReturnsData() {
    const getDateRange = (daysAgo) => ({
      startDate: moment().subtract(daysAgo + 30, 'days'),
      endDate: moment().subtract(daysAgo, 'days'),
    });

    const fetchAnalytics = async ({ startDate, endDate }) => {
      try {
        const response = await axios.post('/api/analytics', {
          startDate,
          endDate,
          adjustForOrderDate: false,
          includeOpenOrders: false,
        });
        if (!response?.data) return null;
        return response?.data;
      } catch (error) {
        return message.error('Error fetching analytics data from server.', 4);
      }
    };

    try {
      const currentPeriod = getDateRange(0);
      const previousPeriod = getDateRange(30);

      const [currentData, previousData] = await Promise.all([
        fetchAnalytics(currentPeriod),
        fetchAnalytics(previousPeriod),
      ]);

      //fix for null data
      if (currentData === null || previousData === null) {
        return;
      }

      const dataTypes = [
        'returnRate',
        'numberOfTotalReturns',
        'totalReturnValue',
      ];

      const changeInPercent = dataTypes.reduce((returnData, dataType) => {
        const currentValue = parseFloat(currentData[dataType]);
        const previousValue = parseFloat(previousData[dataType]);

        returnData[dataType] = previousValue !== 0 ? (dataType === 'returnRate')? (currentValue - previousValue) : (((currentValue - previousValue) / previousValue) * 100).toFixed(2) : parseFloat(0).toFixed(2);
        return returnData;
      }, {});

      this.setState({
        returnRate: currentData.returnRate,
        numberOfTotalReturns: currentData.numberOfTotalReturns,
        currency: currentData.currency,
        totalReturnValue: currentData.totalReturnValue,
        changeInPercent,
      });
    } catch (err) {
      message.error('Error fetching analytics data.', 4);
    }
  }

  displayChangePercentTag = (changeInPercent) => {
    changeInPercent = Math.round(parseFloat(changeInPercent) * 100) / 100;
    if (changeInPercent > 0) {
      return (
        <Tag color="cyan">
          <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--xs">
            <Icon type="arrow-up" />
            <div>{changeInPercent}%</div>
          </div>
        </Tag>
      );
    } else if (changeInPercent < 0) {
      return (
        <Tag color="magenta">
          <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--xs">
            <Icon type="arrow-down" />
            <div>{changeInPercent}%</div>
          </div>
        </Tag>
      );
    } else if (changeInPercent === 0) {
      return (
        <Tag className="ant-tag-gray">
          <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--xs">
            <Icon type="minus" />
            <div>{changeInPercent}%</div>
          </div>
        </Tag>
      );
    }
  };

  loadNPSScript = async () => {
    try {
      this.injectCustomStyles();
      const script = document.createElement('script');
      script.src = 'https://widget.reviews.io/modern-widgets/survey.js';
      script.async = true;
      script.onerror = () => {
        message.error(
          'Error loading the survey widget script. Please try again.',
          5
        );
      };

      document.body.appendChild(script);
    } catch (err) {
      message.error('Error loading widget. Please try again.', 5);
    }
  };

  injectCustomStyles = () => {
    const style = document.createElement('style');
    style.innerHTML = `
      .SurveyWidget-prefix .SurveyWidget {
        --panel-border-width: 0px !important;
        --panel-padding: 0px !important;
        --base-font-size: 18px !important;
        --primary-button-bg-color: #67C2C2 !important;
        --primary-button-border-color: #67C2C2 !important;
        --common-button-border-radius: 6px !important;
        --common-button-vertical-padding: 8px !important;
        --common-button-horizontal-padding: 20px !important;
      }
      .SurveyWidget-prefix .SurveyWidget--contentCenter .SurveyWidget__question__heading {
        text-align: left !important;
      }
      .SurveyWidget-prefix .SurveyWidget--inline .SurveyPanel {
        z-index: auto !important;
      }
      .SurveyWidget-prefix .SurveyWidget:not(.R-XS) .SurveyPanel .SurveyPanel__content .R-ContentCard .R-Button {
        display: block !important;
        width: fit-content !important;
        margin-right: 0px !important;
      }
    `;
    document.head.appendChild(style);
  };

  render() {
    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    return (
      <div className="home-page">
        <h1 className="TextHeading TextHeading--sm u-marginBottom--md">Home</h1>

        <div className="u-marginBottom--lg">
          <Row
            type="flex"
            gutter={10}
            style={{
              marginTop: '20px',
              textAlign: 'center',
              rowGap: '15px',
            }}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="bottom"
                title="Return rate for the last 30 days: calculated as returns created divided by total orders placed."
              >
                <Card
                  title="Return Rate"
                  bordered={true}
                  hoverable={false}
                  extra={<React.Fragment></React.Fragment>}
                >
                  <div className="flex-row flex-middle-xxs flex-row--noMargin flex-center-xxs">
                    <div className="TextHeading TextHeading--sm u-marginBottom--none u-marginRight--sm">
                      {this.state.returnRate}
                    </div>
                    <div style={{ marginRight: -60 }}>
                      {this.displayChangePercentTag(
                        this.state.changeInPercent.returnRate
                      )}
                    </div>
                  </div>
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="bottom"
                title="Total number of returns created over the last 30 days, reflecting all customer-initiated returns within this period."
              >
                <Card
                  title="Return Volume"
                  bordered={true}
                  hoverable={false}
                  extra={<React.Fragment></React.Fragment>}
                >
                  <div className="flex-row flex-middle-xxs flex-row--noMargin flex-center-xxs">
                    <div className="TextHeading TextHeading--sm u-marginBottom--none u-marginRight--sm">
                      {this.state.numberOfTotalReturns}
                    </div>
                    <div style={{ marginRight: -60 }}>
                      {this.displayChangePercentTag(
                        this.state.changeInPercent.numberOfTotalReturns
                      )}
                    </div>
                  </div>
                </Card>
              </Tooltip>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <Tooltip
                placement="bottom"
                title="Total value of returns received over the last 30 days, representing the total amount refunded for all returned items within this period."
              >
                <Card
                  title="Return Value"
                  bordered={true}
                  hoverable={false}
                  extra={<React.Fragment></React.Fragment>}
                >
                  <div className="flex-row flex-middle-xxs flex-row--noMargin flex-center-xxs">
                    <div className="TextHeading TextHeading--sm u-marginBottom--none u-marginRight--sm">
                      {this.state.totalReturnValue} {this.state.currency}
                    </div>
                    <div style={{ marginRight: -60 }}>
                      {this.displayChangePercentTag(
                        this.state.changeInPercent.totalReturnValue
                      )}
                    </div>
                  </div>
                </Card>
              </Tooltip>
            </Col>
          </Row>
        </div>

        <Carousel />

        <div className="u-marginBottom--md"></div>

        <div className="ContentPanel">
          <div className="flex-row flex-middle-xxs">
            <div className="flex-col-xxs-12 flex-col-xs-6">
              <div>
                <div
                  id="reviews-io-survey-widget"
                  widget-id="ZLPnkCBVafyw0Fdo"
                  store-name="www.richcommerce.co"
                  campaign-id="3912"
                  lang="en"
                ></div>
              </div>
            </div>
            <div className="flex-col-xxs-12 flex-col-xs-6">
              <div className="c-cardBanner">
                <picture>
                  <source
                    type="image/png"
                    srcSet="https://assets.reviews.io/img/dashboard/homepage/new/graphic-card-banner--richreturns.webp 1x, https://assets.reviews.io/img/dashboard/homepage/new/graphic-card-banner--richreturns.webp 2x"
                  />
                  <img
                    className="c-cardBanner__image"
                    src="https://assets.reviews.io/img/dashboard/homepage/new/graphic-card-banner--richreturns.webp"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Homepage;
