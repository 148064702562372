import React, { Component } from 'react';
import axios from 'axios';
import {
  Button,
  Row,
  Col,
  Form,
  Divider,
  Alert,
  message,
  Input,
  Checkbox,
  Icon,
} from 'antd';
import '../../../../styles/App.css';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { PageButtonHeader } from '../../../../components/CustomHeader';

const FormItem = Form.Item;

export class StripePayment extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      stripePublishableKey: '',
      stripeSecretKey: '',
      labelCostPayment: undefined,
      webhookSignature: '',
      CompanyId: undefined,
      enableStripePayment: false,
      manualLabelCostPayment: undefined,
      autoAcceptWithCovered: false,
      trackingChange: {
        insertPublishableKey: false,
        insertSecretKey: false,
        addStripeWebhookSignature: false,
      },
      loading: true,
    };
  }

  async fetchReturnSettings() {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data === null) {
        return message.error(
          'Error retrieving your stripe credentials. Try again.',
          5
        );
      }
      this.setState({
        ...response.data,
      });
    } catch (err) {
      message.error('Error retrieving your stripe settings. Try again.', 5);
    }
  }

  async componentDidMount() {
    try {
      await this.fetchReturnSettings();
      await this.generateWebhookUrl();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error retrieving your stripe settings. Try again.', 5);
    }
  }

  handleInputChange = (e) => {
    const insertPublishableKey = e.target.id === 'stripePublishableKey';
    const insertSecretKey = e.target.id === 'stripeSecretKey';
    const addStripeWebhookSignature = e.target.id === 'webhookSignature';
    this.setState({
      [e.target.id]: e.target.value,
      trackingChange: {
        ...this.state.trackingChange,
        insertPublishableKey,
        insertSecretKey,
        addStripeWebhookSignature,
      },
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
    });
  };

  generateWebhookUrl = async () => {
    try {
      let response = await axios.get('/api/integrations/appInfo');
      if (response.data == null) {
        return;
      }
      const env = response?.data?.env;
      const appUrl = response?.data?.appUrl;
      let url =
        'https://713f-103-152-101-209.ngrok-free.app/api/stripe/webhook';
      if (env == 'production') {
        url = `${appUrl}/api/stripe/webhook`;
      }
      url = url + `/${this.state.CompanyId}`;
      this.setState({
        stripeWebhookUrl: url,
        featureStripePayments: response?.data?.featureStripePayments,
      });
    } catch (err) {
      message.error('Error creating webhook URL. Try again.', 5);
    }
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      try {
        const response = await axios.post('/api/returnSettings/update', {
          ...values,
        });
        if (response.data.status === 'error') {
          return message.error(
            'Error updating your stripe credentials. Please try again',
            5
          );
        }
        this.trackEvents(values);
        return message.success('Stripe credentials successfully updated.', 5);
      } catch (err) {
        return message.error(
          'Error updating your stripe credentials. Please try again.',
          5
        );
      }
    });
  };

  trackEvents = (values) => {
    trackFSEvent('Change Payment Settings', {
      feature: 'Payment',
      enableStripePayments: values.enableStripePayment || false,
      enablePaymentsForLabelCosts: values.labelCostPayment || false,
      enableManualLabelCostPayment: values.manualLabelCostPayment || false,
    });
    if (this.state.trackingChange.insertPublishableKey) {
      trackFSEvent('Insert Publishable Key', {});
    }
    if (this.state.trackingChange.insertSecretKey) {
      trackFSEvent('Insert Secret Key', {});
    }
    if (this.state.trackingChange.addStripeWebhookSignature) {
      trackFSEvent('Add Stripe Webhook Signature', {});
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        {this.context && this.context.featureStripePayment ? (
          <Form onSubmit={this.handleSubmitForm} className="removeFormMargin">
            <PageButtonHeader
              headingTitle={'Payments'}
              headingRightContent={
                <FormItem>
                  <Button type="primary" htmlType="submit">
                    Save changes
                  </Button>
                </FormItem>
              }
            />
            <Row type="flex" justify="space-around" align="middle">
              <Col span={24}>
                <Alert
                  message="Setup Stripe Payments"
                  description={
                    <span>
                      Enter your Credentials to setup Stripe Payments. You can
                      now use our dedicated Stripe integration to charge
                      customers when they're trying to exchange for a higher
                      priced item or if you're looking to charge the label cost.
                      Learn more{' '}
                      <a
                        href="https://support.apphub.com/en/articles/8765385-stripe-integration"
                        target="_blank"
                      >
                        here
                      </a>
                    </span>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Row type="flex" align="top">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Enable Stripe payments</h4>
                <span>
                  Check this box if you want to enable payments through stripe.
                  This will alow you to charge customers for higher-priced items
                  or label costs.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <h4>Enable Stripe payments</h4>
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%', gap: 10 }}
                  >
                    <FormItem>
                      {getFieldDecorator('enableStripePayment')(
                        <Checkbox
                          checked={this.state.enableStripePayment}
                          onChange={this.handleChange}
                        >
                          Enable
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Row type="flex" align="top">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            {this.state.enableStripePayment && (
              <React.Fragment>
                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Insert your public key</h4>
                    <span>
                      A public key is a type of API key that is used in the
                      frontend of your application to interact with Stripe's API
                      for handling secure payment processes. You can find it{' '}
                      <a
                        href="https://dashboard.stripe.com/apikeys"
                        target="_blank"
                      >
                        here
                      </a>
                      .
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Insert public key</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('stripePublishableKey', {
                            initialValue: this.state.stripePublishableKey,
                            rules: [
                              {
                                required: true,
                                message: 'Please add the publishable key',
                              },
                            ],
                          })(
                            <Input
                              placeholder="Publishable Key"
                              onChange={this.handleInputChange}
                            />
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Insert your secret key</h4>
                    <span>
                      The secret key in Stripe is a private API key used on the
                      server-side for sensitive actions like charging customers
                      and managing refunds. It must be kept confidential, as it
                      grants full access to your Stripe account. You can find it{' '}
                      <a
                        href="https://dashboard.stripe.com/apikeys"
                        target="_blank"
                      >
                        here
                      </a>
                      .
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Insert secret key</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('stripeSecretKey', {
                            initialValue: this.state.stripeSecretKey,
                            rules: [
                              {
                                required: true,
                                message: 'Please add the secret key',
                              },
                            ],
                          })(
                            <Input
                              placeholder="Secret Key"
                              onChange={this.handleInputChange}
                            />
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Stripe webhook signature</h4>
                    <span>
                      A webhook is a way for Stripe to send real-time data
                      updates to your application. The Webhook Signature ensures
                      these updates are authentic. After adding your Rich
                      Returns webhook, paste the signature here to verify secure
                      communication.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Insert webhook signature</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('webhookSignature', {
                            initialValue: this.state.webhookSignature,
                          })(
                            <Input
                              placeholder="Webhook Signature"
                              onChange={this.handleInputChange}
                            />
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Enable payments for label costs</h4>
                    <span>
                      Check this box if you want your customers to pay for label
                      costs. Only applicable if you're automatically approving
                      returns or you have a prepaid label fee enabled in your
                      Return settings. Will be applied to all returns.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Enable label payment costs</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('labelCostPayment')(
                            <Checkbox
                              checked={this.state.labelCostPayment}
                              onChange={this.handleChange}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Enable Manual Label Cost Payment</h4>
                    <span>
                      Check this box if you want your customers to pay for label
                      if Pre-Paid Return Label (manual) is enabled. Only
                      applicable if prepaid label fee is enabled in Return
                      settings.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Enable manual label cost payment</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('manualLabelCostPayment')(
                            <Checkbox
                              checked={this.state.manualLabelCostPayment}
                              onChange={this.handleChange}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Enable discount deductions</h4>
                    <span>
                      Check this box if you want to deduct the discount from
                      exchanged product price as well.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Enable discount deductions</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('deductDiscounts')(
                            <Checkbox
                              checked={this.state.deductDiscounts}
                              onChange={this.handleChange}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Mark returns with unfulfilled payments as pending</h4>
                    <span>
                      Even if you''re automatically accepting all returns, a
                      return will always be marked as pending if the label fee
                      or price difference was not covered. The decision to
                      approve or not can be made later.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Mark unfulfilled returns as pending</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('autoAcceptWithCovered')(
                            <Checkbox
                              checked={this.state.autoAcceptWithCovered}
                              onChange={this.handleChange}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Generate webhook URL</h4>
                    <span>
                      Click the button to copy the webhook URL. Use it to create
                      your Stripe Webhook Signature. You can find the webhook
                      section in Stripe{' '}
                      <a
                        href="https://dashboard.stripe.com/webhooks"
                        target="_blank"
                      >
                        here
                      </a>
                      .
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Generate webhook URL</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <CopyToClipboard text={this.state.stripeWebhookUrl}>
                          <Button
                            type="ghost"
                            onClick={() =>
                              message.success(
                                'Webhook URL copied successfully',
                                3
                              )
                            }
                            style={{ padding: 'var(--input-padding)' }}
                          >
                            <Icon type="copy" />
                            Copy webhook link
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}

            <div
              className="flex-row flex-row--noMargin flex-end-xxs u-marginBottom--lg"
              style={{ marginTop: 30 }}
            >
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Save changes
                </Button>
              </FormItem>
            </div>
          </Form>
        ) : (
          <React.Fragment>
            <PageButtonHeader headingTitle={'Payments'} />
            <p>
              Let your customers easily and securely pay for their orders with
              Stripe.
            </p>
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={24}>
                  <Alert
                    message="Already on our Plus Plan?"
                    description={
                      <React.Fragment>
                        <span>
                          Please reach out to us directly in order to have this
                          feature enabled and set up for your account.
                        </span>
                      </React.Fragment>
                    }
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            </React.Fragment>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const WrappedStripeDetails = Form.create()(StripePayment);
export default WrappedStripeDetails;
