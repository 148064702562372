/**
 * Component for Connections settings page in the Dashboard.
 *
 * @component
 */

import { Alert, Divider, Tag, message } from 'antd';
import axios from 'axios';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import { LogoUPS } from '../../../../components/CustomReturnsIcons';
import { IntegrationCard } from '../../../../components/IntegrationCard';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { getAppInfo } from '../../../../helpers/appInfo';
import { trackFSEvent } from '../../../../helpers/fullstory';
import '../../../../styles/App.css';

class ConnectCourier extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      brandSettings: {},
      connectionSettings: {},
      connectionStatus: {},
      clientId: 'efdd8904c3999b79312362f88fc31059',
      isProductionApp: false,
      env: null,
      developmentStore: null,
      loading: true,
    };
  }

  async fetchClientId() {
    try {
      const info = await getAppInfo();
      if (info.clientId) {
        this.setState({
          clientId: info.clientId,
        });
      }
      this.setState({
        isProductionApp: info.isProductionApp,
        env: info.env,
      });
    } catch (err) {
      message.error('error fetching clientId of application');
    }
  }

  componentDidMount = () => {
    this.initializeComponent(); // Initialize the component by calling all async functions
  };

  // Wrapper function to manage all async calls
  initializeComponent = async () => {
    try {
      // Run all async functions sequentially
      await this.fetchClientId();
      await this.loadConnectionSettings();

      // Stop loading once all functions have completed
      this.setState({ loading: false });
    } catch (err) {
      // Stop loading and handle any errors
      this.setState({ loading: false });
      message.error('Error loading connection settings. Please try again.', 4);
    }
  };

  // Function to load connection settings
  loadConnectionSettings = async () => {
    const response = await axios.get('/api/connections');
    if (response.data.status === 'error') {
      return message.error(
        'Error loading your connection settings. Try again.',
        4
      );
    }

    const connectionStatus = this.getConnectionStatus(
      response.data.connectionSettings
    );

    // Update the state with data from the API
    this.setState({
      ...response.data,
      connectionStatus,
    });
  };

  // componentDidMount = async () => {
  //   try {
  //     this.fetchClientId();

  //     const response = await axios.get('/api/connections');
  //     if (response.data.status === 'error') {
  //       return message.error(
  //         'Error loading your connection settings. Try again.',
  //         4
  //       );
  //     }

  //     const connectionStatus = this.getConnectionStatus(
  //       response.data.connectionSettings
  //     );

  //     return this.setState({
  //       ...response.data,
  //       connectionStatus,
  //     });
  //   } catch (err) {
  //     message.error('Error loading your connection settings. Try again.', 4);
  //   }
  // };

  getConnectionStatus = (connectionSettings) => {
    let connectionStatus = this.state.connectionStatus;
    connectionSettings.map((connection) => {
      connectionStatus = {
        ...connectionStatus,
        [connection.identifier]: connection.status,
      };
    });
    return connectionStatus;
  };

  trackingEvent = (appName) => {
    trackFSEvent('Connect app', {
      feature: 'Integrations',
      integratedApp: appName,
    });
  };

  render() {
    const url = window.location.href;
    const urlObject = new URL(url);
    const urlProtocol = urlObject.protocol;
    const urlHostname = urlObject.host;
    const oAuthRedirectUrlUPS = `${urlProtocol}//${urlHostname}/dashboard/connect/ups`;

    const upsUrl =
      `${
        this.state.developmentStore ? 'https://wwwcie' : 'https://onlinetools'
      }.ups.com/security/v1/oauth/authorize?` +
      'client_id=0blYFDlAbrXE2itGOCfbJfRfBWgwDq4llaCxkohBrlZcFHHz' +
      `&redirect_uri=${oAuthRedirectUrlUPS}` +
      '&response_type=code';
    let data = [
      {
        title: (
          <>
            UPS <Tag color="purple">New</Tag>
          </>
        ),
        identifier: 'ups',
        oAuthUrl: upsUrl,
        logo: <LogoUPS></LogoUPS>,
        description:
          'Connect to UPS to streamline your return process with seamless shipping and tracking.',
      },
    ];

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <PageButtonHeader headingTitle={'Connect carrier'}></PageButtonHeader>
        <Alert
          message="Looking for Return Labels?"
          description={
            <span>
              Head over to{' '}
              <strong>
                Configuration / Returns / Return methods / Configure pre-paid labels
              </strong>{' '}
              to set up the integration with one of our supported shipping
              carriers.
            </span>
          }
          type="info"
          showIcon
        />
        <div style={{ marginTop: 30 }}>
          <div className="flex-row">
            {data.map((item) => {
              return (
                <div
                  className="flex-col-xxs-12 flex-col-xs-12 flex-col-sm-6 flex-col-md-4 flex-col-lg-3 u-displayFlex u-flexAlignItems--stretch"
                  key={item.identifier}
                >
                  <IntegrationCard
                    item={item}
                    connectionStatus={this.state.connectionStatus}
                    modalVisible={this.modalVisible}
                    trackingEvent={this.trackingEvent}
                  ></IntegrationCard>
                </div>
              );
            })}
          </div>
        </div>
        <Divider />
        <div className="TextBody TextBody--xxs u-textCenter--all">
          Missing a service?
          <a href="mailto:hello@richcommerce.co"> Let us know here.</a>
        </div>
      </React.Fragment>
    );
  }
}

export default ConnectCourier;
