/**
 * Component for our Report showing most-returned Variants.
 * 
 * @component
 */

import React from "react";
import Report from "../Report";
import Stats from "./Stats";

const exportHeaders = [
  {
    label: "Product Name",
    value: "productName",
  },
  {
    label: "Variant Name",
    value: "variantName",
  },
  {
    label: "Return Reasons",
    value: "returnReasons",
  },
  {
    label: "Customer Notes",
    value: "notes",
  },
  {
    label: "SKU",
    value: "sku",
  },
  {
    label: "Count",
    value: "variantCount",
  },
];


class Variants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalRecord: {},
      showModal: false,
    }
  }

  columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      title: "Variant Name",
      dataIndex: "variantName",
    },
    {
      title: "Return Reasons",
      dataIndex: "returnReasons",
      render: (items) => {
        return items?.map((item, index) => <span key={index}>{item}, <br/></span>)
      }
    },
    {
      title: "Customer Notes",
      dataIndex: "notes",
      render: (items) => {
        return items?.map((item, index) => <span key={index}>{item}, <br/></span>)
      }
    },
    {
      title: "SKU",
      dataIndex: "sku",
    },
    {
      title: "Count",
      dataIndex: "variantCount",
    },
    {
      title: "Details",
      render: (_, record) => (
        <a onClick={() => {this.setState({modalRecord: record, showModal: true})}}>View</a>
      )
    },
  ];

  handleCancel = () => {
    this.setState({
      modalRecord: {},
      showModal: false,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Report
          url="/api/reports/products/by-variant"
          title="Most Returned Variants"
          tooltip="This report shows how many times a variant was returned within the selected date range."
          columns={this.columns}
          reportName="most-returned-variants"
          exportHeaders={exportHeaders}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          updateDate={this.props.updateDate}
        />
        {this.state.showModal &&
          <Stats
            url="/api/reports/products/by-variant"
            reportName="most-returned-variants"
            modalRecord={this.state.modalRecord}
            visible={this.state.showModal}
            handleCancel={this.handleCancel}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
          />
        }
      </React.Fragment>
    );
  }
}

export default Variants;
