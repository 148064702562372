/**
 * Component for our Analytics Customer pages
 * including navigation and routing for different
 * component.
 * @component
 */

import { Col, message, Row, Tabs } from 'antd';
import axios from 'axios';
import { Component } from 'react';
import Comments from './Comments';
import ReturnProducts from './ReturnProducts';
import Stats from './Stats';
import MostReturn from './top/MostReturn';

const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

class Customer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: '',
      customerNameEmail: '',
      matchingCustomers: [],
    };
  }

  render() {
    const updateCustomerDetails = (customerId, shippingName) => {
      if (shippingName != null) {
        this.setState({
          customerId,
          customerNameEmail: shippingName,
        });
      } else {
        const customerDetails = this.state.matchingCustomers.find(
          (value) => value.customerId === customerId
        );
        this.setState({
          customerId,
          customerNameEmail: `${customerDetails.shippingName} ${customerDetails.customerEmail}`,
        });
      }
    };

    const onSearch = async (value) => {
      if (value.length > 3) {
        try {
          const responses = await axios.post('/api/analytics/customers', {
            value,
          });
          this.setState({
            matchingCustomers: responses.data.data,
          });
        } catch (err) {
          return message.error('something went wrong please try again', 5);
        }
      } else {
        return this.setState({
          matchingCustomers: [],
        });
      }
    };

    return (
      <>
        <div className="u-marginBottom--lg">
          <Row>
            <Col>
              <Stats
                customerId={this.state.customerId}
                onSearch={onSearch}
                matchingCustomers={this.state.matchingCustomers}
                customerNameEmail={this.state.customerNameEmail}
                updateCustomerDetails={updateCustomerDetails}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <Col>
            <div className="ContentPanel u-paddingTop--sm">
              <Tabs defaultActiveKey="1" onChange={callback} animated={false}>
                <TabPane tab="Most returns" key="1">
                  <MostReturn />
                </TabPane>
                <TabPane tab="Returned products" key="2">
                  <ReturnProducts
                    onSearch={onSearch}
                    customerId={this.state.customerId}
                    matchingCustomers={this.state.matchingCustomers}
                    customerNameEmail={this.state.customerNameEmail}
                    updateCustomerDetails={updateCustomerDetails}
                  />
                </TabPane>
                <TabPane tab="Customer comments" key="3">
                  <Comments
                    onSearch={onSearch}
                    customerId={this.state.customerId}
                    matchingCustomers={this.state.matchingCustomers}
                    customerNameEmail={this.state.customerNameEmail}
                    updateCustomerDetails={updateCustomerDetails}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>

        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ paddingTop: 40 }}
        >
          <Col>
            <h4 className="TextHeading TextHeading--xxxxs">
              Want to see another Metric in the dashboard? Let us know{' '}
              <a
                href="mailto:hello@richcommerce.co?subject=Please%20add%20New%20Metric%20to%20the%20dashboard"
                target="_blank"
                style={{ color: '#000000', textDecoration: 'underline' }}
              >
                here
              </a>
              .
            </h4>
          </Col>
        </Row>

        <Row
          type="flex"
          justify="center"
          align="top"
          style={{ marginBottom: 30 }}
        >
          <Col>
            <div className="TextBody TextBody--xxxs u-textAlign--center">
              Note: If you encounter issues on this Analytics-Page make sure to
              disable any ad-blockers in your browser.
            </div>
          </Col>
        </Row>

        {/* <Row>
          <Col span={4}>
            <Menu
              mode="inline"
              onClick={this.handleClick}
              defaultSelectedKeys={['/dashboard/analytics/customer']}
              defaultOpenKeys={['customer-menu']}
              selectedKeys={[location.pathname]}
            >
              <Menu.ItemGroup key="Top" title="Top">
                <Menu.Item key="/dashboard/analytics/customer/most-returned">
                  <Link to="/dashboard/analytics/customer/most-returned">
                    <span>Most Returned</span>
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
              <Menu.ItemGroup key="Customer" title="Customer">
                <Menu.Item key="/dashboard/analytics/customer">
                  <Link to="/dashboard/analytics/customer">
                    <span>Stats</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/analytics/customer/products">
                  <Link to="/dashboard/analytics/customer/products">
                    <span>Returned Products</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/dashboard/analytics/customer/comments">
                  <Link to="/dashboard/analytics/customer/comments">
                    <span>Comments</span>
                  </Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>
          </Col>

          <Col span={20}>
            <Content
              style={{
                paddingLeft: 24,
                background: '#fff',
                minHeight: 600,
              }}
            >
              <Switch>
                <Route
                  exact
                  path="/dashboard/analytics/customer"
                  render={() => (
                    <Stats
                      customerId={this.state.customerId}
                      onSearch={onSearch}
                      matchingCustomers={this.state.matchingCustomers}
                      customerNameEmail={this.state.customerNameEmail}
                      updateCustomerDetails={updateCustomerDetails}
                    />
                  )}
                />
                <Route
                  exact
                  path="/dashboard/analytics/customer/comments"
                  render={() => (
                    <Comments
                      onSearch={onSearch}
                      customerId={this.state.customerId}
                      matchingCustomers={this.state.matchingCustomers}
                      customerNameEmail={this.state.customerNameEmail}
                      updateCustomerDetails={updateCustomerDetails}
                    />
                  )}
                />
                <Route
                  exact
                  path="/dashboard/analytics/customer/products"
                  render={() => (
                    <ReturnProducts
                      onSearch={onSearch}
                      customerId={this.state.customerId}
                      matchingCustomers={this.state.matchingCustomers}
                      customerNameEmail={this.state.customerNameEmail}
                      updateCustomerDetails={updateCustomerDetails}
                    />
                  )}
                />
                <Route
                  exact
                  path="/dashboard/analytics/customer/most-returned"
                  render={() => <MostReturn />}
                />
                <Route path="*" component={ErrorRedirect} />
              </Switch>
            </Content>
          </Col>
        </Row> */}
      </>
    );
  }
}

export default Customer;
