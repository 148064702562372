/**
 * Component to load the Table containing top 10 returned customers * 
 * @component
 */

import { Button, Divider, Table } from "antd";
import React from 'react';
import { IconTray } from '../../../../../components/CustomReturnsIcons';
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";
class MostReturnedTable extends React.Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props)
  }
  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'shippingName',
        key: 'shippingName',
        render: text => <span>{text}</span>,
      },
      {
        title: 'Email',
        dataIndex: 'customerEmail',
        key: 'customerEmail',
      },
      {
        title: 'Returns',
        key: 'count',
        dataIndex: 'count',
      },
      {
        title: 'Action',
        key: 'action',
        render: (_text, record) => (
          <span>
            <Button type="ghost" size="small" href={`/dashboard/analytics/customer?customerId=${record.customerId}`}>See Details</Button>
            <Divider type="vertical" />
            <Button
              type="ghost"
              size="small"
              target="_blank"
              rel="noopener noreferrer"
              href={this.context.storeShopifyUrl + `/admin/customers/${record.customerId}`}
            >
              Show in Shopify
            </Button>
          </span>
        ),
      },
    ];
    return (
      <React.Fragment>
        <div style={{overflowX: 'auto'}}>
          <div style={{ minWidth: '768px' }}>
            <Table
              columns={columns}
              dataSource={this.props.mostReturns}
              pagination={false}
              rowKey={record => record.customerId + '-' + record.customerEmail}
              locale={{
                emptyText: (
                  <div className="flex-row flex-middle-xxs flex-center-xxs">
                    <div className="flex-col-xxs-12">
                      <IconTray size="md" />
                    </div>
                    <div>
                      <p className="TextBody TextBody--xxxs">No Data</p>
                    </div>
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default MostReturnedTable;