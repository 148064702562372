/**
 * Component to display notifications related to the monthly
 * included Returns-Volume of a user once the user approaches
 * their limit.
 * 
 * (1) > 80% of volume
 * (2) > 100% of volume
 * (3) using their usage-charge balance on the Shopify account billed
 * per additional Return, we display a countdown of remaining Returns
 * (4) usage-charge limit on account reached, no further Returns possible
 * 
 * @component
 */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {  Alert, Button } from "antd";
import { SubscriptionContext } from "../contexts/SubscriptionContext";

function UpgradeNotification() {

  const context = useContext(SubscriptionContext);

  let monthlyQuotaAlmostReached = false;
  let monthlyQuotaReached = false;
  let usageChargeQuotaAlmostReached = false;
  let usageChargeQuotaReached = false;

  const intervalTime = context.intervalTime;
  const usageQuota = intervalTime === 'EVERY_30_DAYS' ? context.monthlyQuota : context.monthlyQuota * 12;

  if (context.directBilling) {

    // TODO: remove this once all customers are on Shopify Billing
    return true;
    
  } else if (usageQuota === 0) {

    // this is for legacay plans with no monthly quota
    return true;

  } else if (context.usageChargeCount > usageQuota) {
    const balanceRemaining = parseInt(context.balanceRemaining);
    const balanceUsed = context.balanceUsed;

    if (balanceRemaining === 0) {

      usageChargeQuotaReached = true;
    
    } else if (balanceUsed > balanceRemaining) {

      usageChargeQuotaAlmostReached = true;

    } else {

      monthlyQuotaReached = true;

    }

  } else {
    const percentOfQuota = (context.usageChargeCount / usageQuota * 100).toFixed(0);
    if (percentOfQuota > 80) {
      monthlyQuotaAlmostReached = true;
    }
  }

  return(
    <React.Fragment>

      <React.Fragment>
        {
          monthlyQuotaAlmostReached
            ? (
              <Alert
                message={
                  <React.Fragment>
                    <span>You have almost reached your monthly quota of {usageQuota} included returns. Upgrade your plan now to avoid interruptions for your customers. </span>
                    <Link to="/dashboard/account">
                      <Button size="small" type="danger">See plans</Button>
                    </Link>
                  </React.Fragment>
                }
                banner={true}
                closable={true}
                type={"warning"}
              />
            )
            : null
        }
      </React.Fragment>

      <React.Fragment>
        {
          monthlyQuotaReached
            ? (
              <Alert
                message={
                  <React.Fragment>
                    <span>You have reached your quota of {usageQuota} included returns. Upgrade your plan now to avoid interruptions for your customers. </span>
                    <Link to="/dashboard/account">
                      <Button size="small" type="danger">See plans</Button>
                    </Link>
                  </React.Fragment>
                }
                banner={true}
                closable={true}
                type={"error"}
              />
            )
            : null
        }
      </React.Fragment>

      <React.Fragment>
        {
          usageChargeQuotaAlmostReached
            ? (
              <Alert
                message={
                  <React.Fragment>
                    <span>
                    ⚠️ Your customers can start {(context.balanceRemaining / context.amountPerUsage).toFixed(0)} additional returns. After that customers will not be able to start a return until the end of the current billing period due to Shopify's usage-charge limit on your account. {" "}
                      <Button
                        size="small"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.apphub.com/en/articles/6341449-billing-for-extra-returns-shopify-usage-charge-limit-no-additional-returns-possible"
                      >
                    Read details
                      </Button>{" "}
                    Upgrade your plan now </span>
                    <Link to="/dashboard/account">
                      <Button size="small" type="danger">See plans</Button>
                    </Link>
                  </React.Fragment>
                }
                banner={true}
                closable={true}
                type={"error"}
              />
            )
            : null
        }
      </React.Fragment>

      <React.Fragment>
        {
          usageChargeQuotaReached
            ? (
              <Alert
                message={
                  <React.Fragment>
                    <span>
                    ⚠️⚠️⚠️ Your account has reached its limit of returns due to Shopify's usage-charge limit on your account {" "}
                      <Button
                        size="small"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.apphub.com/en/articles/6341449-billing-for-extra-returns-shopify-usage-charge-limit-no-additional-returns-possible"
                      >
                      Read details
                      </Button>.
                    Your customers are not able to start a new return until the end of the current billing period. Upgrade your plan now {" "}
                    </span>
                    <Link to="/dashboard/account">
                      <Button size="small" type="danger">See plans</Button>
                    </Link>
                  </React.Fragment>
                }
                banner={true}
                closable={true}
                type={"error"}
              />
            )
            : null
        }
      </React.Fragment>

    </React.Fragment>

  );
};

export default UpgradeNotification;