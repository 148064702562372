import {
  Col,
  Divider,
  Input,
  Row,
  Select,
} from "antd";
import React, { Component } from "react";

import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";

const { Option } = Select;
class UPS extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() { 
    const internationalServices = [
      <Option value="07" key="07">
        Worldwide Express
      </Option>,
      <Option value="08" key="08">
        Worldwide Expedited
      </Option>,
      <Option value="11" key="11">
        Standard
      </Option>,
      <Option value="54" key="54">
        Worldwide Express Plus
      </Option>,
      <Option value="65" key="65">
        Saver
      </Option>,
      <Option value="71" key="71">
        UPS Worldwide Express Freight Midday
      </Option>,
      <Option value="96" key="96">
        UPS Worldwide Express Freight
      </Option>
    ];
    const domesticServices = [
      <Option value="01" key="01">
        Next Day Air
      </Option>,
      <Option value="02" key="02">
        2nd Day Air
      </Option>,
      <Option value="03" key="03">
        Ground
      </Option>,
      <Option value="12" key="12">
        3 Day Select
      </Option>,
      <Option value="13" key="13">
        Next Day Air Saver
      </Option>,
      <Option value="14" key="14">
        UPS Next Day Air Early
      </Option>,
      <Option value="59" key="59">
        2nd Day Air A.M
      </Option>,
      <Option value="75" key="75">
        UPS Heavy Goods
      </Option>
    ];

    return (
      <React.Fragment>
        <Row type="flex" justify="space-around" align="top">
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="middle" className="u-rowGap--md">
          <Col xs={24} md={10}>
            <h4>Billing Account Number</h4>
            <span>
              Enter your Billing Account Number. Click
              on the link at the top to see where to find it.
            </span>
          </Col>
          <Col xs={24} md={10}>
            <a
              href="https://developer.ups.com/apps"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can find it here in your UPS app
            </a>
            <br />
            <Input
              name="labelApiKey"
              onChange={(e) =>
                this.props.handleChange("labelApiKey", e.target.value)
              }
              value={this.props.labelApiKey}
              className="u-marginRight--none u-marginTop--sm"
            />
          </Col>
        </Row>

        <Row type="flex" justify="space-around" align="top">
          <Col span={24}>
            <Divider />
          </Col>
        </Row>

        {this.props.labelApiKey && 
          <React.Fragment>
            <Row type="flex" justify="space-around" align="middle" className="u-rowGap--md">
              <Col xs={24} md={10}>
                <h4>International Services</h4>
                <span>
                  Select a international level service for label.
                </span>
              </Col>
              <Col xs={24} md={10}>
                <Select
                  name="internationalServices"
                  style={{ width: "100%" }}
                  defaultValue={this.props.internationalService}
                  onChange={(value) =>
                    this.props.handleChange("internationalService", value)
                  }
                  placeholder="Select a international level service for label"
                >
                  {internationalServices}
                </Select>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
          </React.Fragment>
        }

        {this.props.labelApiKey &&
          <React.Fragment>
            <Row type="flex" justify="space-around" align="middle" className="u-rowGap--md">
              <Col xs={24} md={10}>
                <h4>Domestic Services</h4>
                <span>
                  Select a domestic level service for label.
                </span>
              </Col>
              <Col xs={24} md={10}>
                <Select
                  name="domesticServices"
                  style={{ width: "100%" }}
                  defaultValue={this.props.domesticService}
                  onChange={(value) =>
                    this.props.handleChange("domesticService", value)
                  }
                  placeholder="Select a domestic level service for label"
                >
                  {domesticServices}
                </Select>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
          </React.Fragment>
        }

      </React.Fragment>
    );
  }
}

export default UPS;
