import React from 'react'
import { Modal, Table } from 'antd';
import licenses from '../package-licenses.json';

class PackageLicenses extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'License Type',
        dataIndex: 'licenseType',
        key: 'licenseType',
      },
      {
        title: 'Installed Version',
        dataIndex: 'installedVersion',
        key: 'installedVersion',
      },
      {
        title: 'Link',
        dataIndex: 'link',
        key: 'link',
        render: (text) => (
          <span>
            {text.replace('git+', '')}
          </span>
        ),
      },
    ];
    return (
      <div>
        <Modal
          title="Licenses"
          width={800}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          footer={null}
        >
          <Table dataSource={licenses} columns={columns} />
        </Modal>
      </div>
    );
  }
}

export default PackageLicenses
