/**
 * Component for Logout the session.
 *
 * @component
 */

import React, { Component } from "react";
import { message } from "antd";
import axios from 'axios'

class Logout extends Component {
  constructor(props) {
    super(props);
      
  }

    componentDidMount = async () => {
      try {
        let response = await axios.post("/auth/logout");
        if (response.data.error) {
          return message.error("something went wrong please try again", 5);
        }
        return message.success("logout successfully.", 4)
      } catch (err) {
        return message.error("Error while logging you out please try again.", 5)
      }
    }

    render() {
      return(
        <React.Fragment>
                
        </React.Fragment>
      );
    }
};
 
export default Logout;