/**
 * Component for our Analytics section displaying top 10 returned customers.
 *
 * @component
 */

import { message, Typography, } from "antd";
import axios from "axios";
import React from 'react';
import MostReturnedTable from './MostReturnTable';

const { Text } = Typography;
class MostReturn extends React.Component {
  constructor() {
    super()
    this.state = {
      mostReturns: []
    }
  }
  async componentDidMount() {
    try {
      const response = await axios.get("/api/analytics/customer/most-returned");
      if (response?.data.status === "error") {
        return message.error("Error while fetching records of most returned customers .");
      }
      const returnData = response.data
      this.setState({
        mostReturns: returnData
      })
    } catch (err) {
      return message.error("Error while fetching records of most  returned customers .");
    }
  }
  render() {
    return (
      <React.Fragment>
        {/* <Row
          type="flex"
          justify="start"
          align="top"
        >
          <Col>
            <h1 className="TextHeading TextHeading--sm">Customers with Most Returns</h1>
            <p>List of top 10 Customers with most Returns</p>
          </Col>
        </Row> */}

        <div className="u-marginBottom--md">
          <Text style={{ fontSize: 14 }}>List of top 10 Customers with most Returns</Text>
        </div>

        <MostReturnedTable
          mostReturns={this.state.mostReturns}
        />
      </React.Fragment>
    )
  }
}
export default MostReturn;