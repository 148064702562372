import React, { Component } from "react";
import { Redirect } from 'react-router-dom'
import axios from 'axios'

class ErrorRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      loading: true
    };
  }

  async componentDidMount(){
    try{
      const response = await axios.get('/api/contexts', { skipInterceptor: true })
      if (response.data.status === "unauthorized"){
        this.setState({ authorized: false, loading: false })
      }
      else{
        this.setState({ authorized: true, loading: false })
      }
    }
    catch(error){
      this.setState({ authorized: false, loading: false })
    }
  }

  render() {
    if (this.state.loading){
      return (<></>)
    }
    if(this.state.authorized){
      return <Redirect to="/dashboard" />;
    }
    return (
      <Redirect to="/error-404" />
    )
  }
}

export default ErrorRedirect;
