/**
 * Component for our Timeline feature that displays
 * activities from a Return, e.g. created, status changed.
 * @component
 */

import {
  Collapse,
  message,
  Timeline,
  Typography
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { Component } from "react";

const { Panel } = Collapse;
const { Text } = Typography;
 
class ReturnDetailsTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
    };
  }

    getActivitiesOnChange = (data) => {
      if(data[0] && data[0]==='1') {
        this.getActivities();
      }
    }

    async getActivities() {
      try {
        const response = await axios.get(`/api/activities/${this.props.returnId}?sort=desc`);
        if (response.data.status === "error") {
          message.error("There was an error loading the timeline. Try again.", 4);
        } else {
          this.setState({
            activities: [...response.data.activities]
          });
        }
      } catch(err) {
        return message.error("There was an error loading the timeline. Try again."+err.message, 4);
      }
    }
 
    render() {
      const activityToTimelineItem = (activity, index) => {
        let text = undefined;
        const { type, subtype, createdAt, action, status } = activity;
        const date = moment(createdAt).format("MMM DD, YYYY");
        const time = moment(createdAt).format("hh:mm A");
        switch (type) {
        case "refund":
          if (subtype === "store-credit") {
            text = `Order refunded as store credit.`;
          } else if (subtype === "payment") {
            text = `Order refunded to original payment method.`;
          }
          break;
        case "order":
          if (subtype === "exchange-order") {
            text = `Exchange Order created.`;
          } else if (subtype === "draft-order") {
            text = `Draft Exchange Order created.`;
          }
          break
        case "shipment":
          if (subtype === "tracking-shipped") {
            text = `Auto-Tracking: Status changed to 'Shipped'.`;
          } else if (subtype === "tracking-delivered") {
            text = `Auto-Tracking: Status changed to 'Received'.`;
          }
          break
        case "label":
          if (action === "created") {
            text = `Return Shipping-Label created.`;
          }
          break
        case "return":
          if (action === "created") {
            if (subtype === "Approved") {
              text = `Return created and Auto-Approved.`;
            } else {
              text = `Return created with status ${subtype}.`;
            }
          } else if (
            [
              "Pending",
              "Approved",
              "Shipped",
              "In Transit",
              "Received",
              "Resolved",
              "Rejected",
            ].includes(subtype)
          ) {
            // Find a valid previous order status
            let prevStatus = null;
            for (let i = index + 1; i < this.state.activities.length; i++) {
              if (this.state.activities[i].type === "return") {
                prevStatus = this.state.activities[i].subtype;
                break;
              }
            }
            if (!prevStatus) {
              text = `Status changed to ${subtype}.`;
            } else {
              text = `Status changed from ${activity ? prevStatus : 'Unknown'} to ${subtype}.`;
            }
          } else {
            text = `${type} - ${subtype} - ${action}`;
          }
          break;
        case "note":
          text = `Staff notes updated.`;
          break;
        case "email":
          if (subtype === "Pending") {
            text = `Email notification 'Pending' sent.`;
          } else if (subtype === "Approved") {
            text = `Email notification 'Approved' sent.`;
          } else if (subtype === "ApprovedLabel") {
            text = `Email notification 'Approved Label' sent.`;
          } else if (subtype === "ApprovedLabelError") {
            text = `Email notification 'Approved Label Error' sent.`;
          } else if (subtype === "ApprovedLabelManual") {
            text = `Email notification 'Approved Manual Label' sent.`;
          } else if (subtype === "Shipped") {
            text = `Email notification 'Shipped' sent.`;
          } else if (subtype === "Received") {
            text = `Email notification 'Received' sent.`;
          } else if (subtype === "Resolved") {
            text = `Email notification 'Resolved' sent.`;
          } else if (subtype === "Rejected") {
            text = `Email notification 'Rejected' sent.`;
          } else if (subtype === "StoreCredit") {
            text = `Email notification 'Store-Credit' sent.`;
          } else {
            text = `${type} - ${subtype} - ${action}`;
          }
          break;
        default:
          text = `${type} - ${subtype} - ${action}`;
        }
        let color = status === "failed" ? "red" : "green";
        return (
          <Timeline.Item color={color}>
            <Text>{text}</Text>
            <br />
            <Text type="secondary">{`${date} at ${time}`}</Text>
          </Timeline.Item>
        );
      };
      
      const getTimeline = () => {
        const timelineItems =
                this.state.activities.length === 0 ? (
                  <Timeline.Item>No events, yet. Events are recorded for all returns after March 22, 2021 which is the date this feature was released.</Timeline.Item>
                ) : (
                  this.state.activities.map((activity, index) =>
                    activityToTimelineItem(activity, index)
                  )
                );
        return <Timeline>{timelineItems}</Timeline>;
      };
      return(
        <React.Fragment>
          <div className="flex-row flex-row--noMargin">
            <div className="flex-col-xxs-12">
              <Collapse onChange={this.getActivitiesOnChange}>
                <Panel header={<span>Show Timeline</span>} key="1"> 
                  {getTimeline()}
                  <span>Want to see another event? Let us know <a href="mailto:hello@richcommerce.co?subject=Please%20add%20this%20Activity%20to%20the%20timeline" target="_blank">here</a>.</span>
                </Panel>
              </Collapse>
            </div>
          </div>
        </React.Fragment>
      )
    }
}
 
export default ReturnDetailsTimeline;