/**
 * Component to confirm and activate a recurring charge once
 * the user is redirected back from the Shopify Billing dialog.
 * 
 * Implementation applies to our Shopify Public-App in the App-Store.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import AuthenticationLoadingScreen from "../../../../components/AuthenticationLoadingScreen"

class ShopifyBillingConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);
    const chargeId = urlObject.searchParams.get("charge_id");

    if (chargeId == null) {
      return message.error("There was an error connecting to Shopify. Please try again.");
    }

    try {
      const response = await axios.post("/api/shop/shopify/billing/confirm", {
        chargeId,
      });
      if (response.data.status === "error") {
        return message.error("Error connecting to the Shopify billing service. Please try again.");
      }
      this.setState({
        redirect: true,
      });
    } catch (err) {
      message.error("Error connecting your Shopify account.");
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    return(
      <AuthenticationLoadingScreen />
    );
  }
}

export default ShopifyBillingConfirm;
