/**
 * Component to manage our  Return Templates
 *
 * @component
 */

import React, { Component } from 'react';
import {
  Modal,
  Button,
  Divider,
  message,
  Col,
  Row,
  Checkbox,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Icon,
  Alert,
} from 'antd';
import axios from 'axios';
import { IconTray } from '../../../../../components/CustomReturnsIcons';

const { Option } = Select;

class ReturnTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      templates: {},
      convenienceTemplate: {
        type: 'select',
        isRequire: false,
        options: ['Regular', 'Convenience'],
      },
      labelService: undefined,
      labelCarrier: undefined,
      ratesList: [],
    };
  }

  componentDidMount = async () => {
    await this.getReturnSetting();
    await this.getShippoRates();
  };

  getReturnSetting = async () => {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response?.data) {
        const templates = response.data.templates;
        this.setState({
          ...templates,
          templates,
          labelService: response.data.labelService,
          labelCarrier: response.data.labelCarrier,
        });
      }
    } catch (err) {
      return message.error('Something went wrong please try again', 5);
    }
  };

  getShippoRates = async () => {
    const selectedCarrier = this.state.labelCarrier;
    let ratesList = [];
    try {
      if (this.state.labelService === 'shippo') {
        if (!selectedCarrier) {
          return message.error(
            'Error requesting services label carrier is missing from the settings.',
            5
          );
        }
        const response = await axios.get(
          `/api/integrations/shippo/servicelevels/${selectedCarrier}`
        );
        if (response.data.status === 'error') {
          return message.error(
            'Error requesting services from Shippo. Please try again.',
            5
          );
        }
        ratesList = response.data.ratesListShippo;
      } else if (this.state.labelService === 'shipstation') {
        if (!selectedCarrier) {
          return message.error(
            'Error requesting services label carrier is missing from the settings.',
            5
          );
        }
        const response = await axios.post(
          '/api/integrations/shipstation/servicelevels',
          {
            selectedCarrier,
          }
        );
        if (response.data.status === 'error') {
          return message.error(
            'Error requesting service-levels from ShipStation. Please try again.',
            5
          );
        }
        ratesList = response.data.ratesListShipStation;
      }

      this.setState({
        ratesList,
      });
    } catch (err) {
      return message.error('Error requesting the label services.', 5);
    }
  };

  onSave = async () => {
    let fields;

    let templates = this.state.templates;
    this.setState({
      isLoading: true,
    });

    fields = this.state.convenienceTemplate;

    if (!fields.title) {
      this.setState({
        isLoading: false,
      });
      return message.error(
        'Query for convenience return template cannot be empty',
        5
      );
    }

    if (this.state.labelService === 'ups') {
      if (!fields.convenienceInternationalService) {
        this.setState({
          isLoading: false,
        });
        return message.error(
          'International label service for convenience return cannot be empty',
          5
        );
      }

      if (!fields.convenienceDomesticService) {
        this.setState({
          isLoading: false,
        });
        return message.error(
          'Domestic label service for convenience return cannot be empty',
          5
        );
      }
    } else {
      if (!fields.convenienceService) {
        this.setState({
          isLoading: false,
        });
        return message.error(
          'Label service for convenience return cannot be empty',
          5
        );
      }
    }

    if (!fields.convenienceLabelFee) {
      templates.convenienceTemplate.convenienceLabelFee = 0;
      fields.convenienceLabelFee = 0;
      this.setState({
        convenienceTemplate: fields,
        templates,
      });
    }

    const payload = {
      templates,
    };
    try {
      await axios.post(`/api/returnSettings/updateTemplates`, payload);
      this.setState({
        isLoading: false,
      });
      message.success('Saved successfully', 5);
      this.props?.onCancel();
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      return message.error('Something went wrong please try again', 5);
    }
  };

  handleChange = (template, field, value) => {
    const templateFields = this.state[template];
    templateFields[field] = value;
    const templates = this.state.templates;
    templates[template] = templateFields;
    this.setState({
      templates,
      [template]: templateFields,
    });
  };

  convenienceServices = () => {
    const labelService = this.state.labelService;
    let services;

    if (labelService === 'ups') {
      const international = upsInternationalServices;
      const domestic = upsDomesticServices;
      services = {
        international,
        domestic,
      };
    } else if (labelService === 'shippo' || labelService === 'shipstation') {
      const list = this.state.ratesList;
      services = list.map((item) => {
        const name = item.name;
        const code = item.code;
        return (
          <Option value={code} key={code}>
            {name}
          </Option>
        );
      });
    }

    return services;
  };

  render() {
    const labelServices = this.convenienceServices();

    return (
      <React.Fragment>
        <Modal
          width={720}
          title="Convenience Return Template"
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          footer={null}
        >
          <Row type="flex" justify="space-around" align="middle">
            <Col span={20}>
              <Alert
                message="How to use convenience return template?"
                description={
                  <span>
                    This template allows users to choose between regular and
                    convenience returns on return portal. To use it, you must
                    provide a template query, select the label service for the
                    current shipping platform according to the prepaid
                    return-label settings, and specify the label fee for
                    convenience returns. You also have the option to make this
                    choice mandatory for the return portal.
                  </span>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>

          <React.Fragment>
            <Row type="flex" className="u-rowGap--md">
              <Col xs={24} md={12}>
                <span>Enter query for Convenience Return Template</span>
              </Col>
              <Col xs={24} md={12}>
                <Input
                  placeholder={'Enter your query'}
                  defaultValue={this.state?.convenienceTemplate?.title}
                  value={this.state?.convenienceTemplate?.title}
                  onChange={(e) =>
                    this.handleChange(
                      'convenienceTemplate',
                      'title',
                      e.target.value
                    )
                  }
                />
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            {this.state.labelService === 'easypost' ? (
              <Row type="flex" className="u-rowGap--md">
                <Col xs={24} md={12}>
                  <span>
                    Enter label service for Convenience Return Template
                  </span>
                </Col>
                <Col xs={24} md={12}>
                  <p>
                    <strong>Tip:</strong> Find the name of your rate{' '}
                    <a
                      href="https://www.easypost.com/service-levels-and-parcels"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                    . Select your carrier from the prepaid-label settings and
                    copy the exact name of your rate under 'Service Levels' to
                    this field here.
                  </p>
                  <Input
                    placeholder={'Enter label service'}
                    defaultValue={
                      this.state?.convenienceTemplate?.convenienceService
                    }
                    value={this.state?.convenienceTemplate?.convenienceService}
                    onChange={(e) =>
                      this.handleChange(
                        'convenienceTemplate',
                        'convenienceService',
                        e.target.value
                      )
                    }
                  />
                </Col>
              </Row>
            ) : this.state.labelService === 'ups' ? (
              <React.Fragment>
                <Row type="flex" className="u-rowGap--md">
                  <Col xs={24} md={12}>
                    <span>
                      Select international label service for Convenience Return
                      Template
                    </span>
                  </Col>
                  <Col xs={24} md={12}>
                    <Select
                      style={{ width: '100%' }}
                      placeholder={'Select international label service'}
                      defaultValue={
                        this.state?.convenienceTemplate
                          ?.convenienceInternationalService
                      }
                      value={
                        this.state?.convenienceTemplate
                          ?.convenienceInternationalService
                      }
                      onChange={(value) =>
                        this.handleChange(
                          'convenienceTemplate',
                          'convenienceInternationalService',
                          value
                        )
                      }
                    >
                      {labelServices?.international}
                    </Select>
                  </Col>
                </Row>

                <Row type="flex" justify="space-around" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <Row type="flex" className="u-rowGap--md">
                  <Col xs={24} md={12}>
                    <span>
                      Select domestic label service for Convenience Return
                      Template
                    </span>
                  </Col>
                  <Col xs={24} md={12}>
                    <Select
                      style={{ width: '100%' }}
                      placeholder={'Select domestic label service'}
                      defaultValue={
                        this.state?.convenienceTemplate
                          ?.convenienceDomesticService
                      }
                      value={
                        this.state?.convenienceTemplate
                          ?.convenienceDomesticService
                      }
                      onChange={(value) =>
                        this.handleChange(
                          'convenienceTemplate',
                          'convenienceDomesticService',
                          value
                        )
                      }
                    >
                      {labelServices?.domestic}
                    </Select>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <Row type="flex" className="u-rowGap--md">
                <Col xs={24} md={12}>
                  <span>
                    Select label service for Convenience Return Template
                  </span>
                </Col>
                <Col xs={24} md={12}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={'Select label service'}
                    defaultValue={
                      this.state?.convenienceTemplate?.convenienceService
                    }
                    value={this.state?.convenienceTemplate?.convenienceService}
                    onChange={(value) =>
                      this.handleChange(
                        'convenienceTemplate',
                        'convenienceService',
                        value
                      )
                    }
                    notFoundContent={
                      <div className="flex-row flex-middle-xxs flex-center-xxs">
                        <div className="flex-col-xxs-12">
                          <IconTray size="md" />
                        </div>
                        <div>
                          <p className="TextBody TextBody--xxxs">
                                  No Data
                          </p>
                        </div>
                      </div>
                    }
                  >
                    {labelServices}
                  </Select>
                </Col>
              </Row>
            )}
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <Row type="flex" className="u-rowGap--md">
              <Col xs={24} md={12}>
                <span>
                  Convenience Label-Fee amount (in your store's currency)
                </span>
              </Col>
              <Col xs={24} md={12}>
                <InputNumber
                  min={0}
                  value={
                    this.state?.convenienceTemplate?.convenienceLabelFee
                      ? this.state.convenienceTemplate.convenienceLabelFee
                      : 0
                  }
                  onChange={(value) =>
                    this.handleChange(
                      'convenienceTemplate',
                      'convenienceLabelFee',
                      value
                    )
                  }
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </React.Fragment>

          <Row type="flex" justify="space-around" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>

          <Row
            span={24}
            type="flex"
            justify="end"
          >
            <Button
              type="primary"
              onClick={this.onSave}
              loading={this.state.isLoading}
            >
              Save changes
            </Button>
          </Row>
        </Modal>
      </React.Fragment>
    );
  }
}

const upsInternationalServices = [
  <Option value="07" key="07">
    Worldwide Express
  </Option>,
  <Option value="08" key="08">
    Worldwide Expedited
  </Option>,
  <Option value="11" key="11">
    Standard
  </Option>,
  <Option value="54" key="54">
    Worldwide Express Plus
  </Option>,
  <Option value="65" key="65">
    Saver
  </Option>,
  <Option value="71" key="71">
    UPS Worldwide Express Freight Midday
  </Option>,
  <Option value="96" key="96">
    UPS Worldwide Express Freight
  </Option>,
];
const upsDomesticServices = [
  <Option value="01" key="01">
    Next Day Air
  </Option>,
  <Option value="02" key="02">
    2nd Day Air
  </Option>,
  <Option value="03" key="03">
    Ground
  </Option>,
  <Option value="12" key="12">
    3 Day Select
  </Option>,
  <Option value="13" key="13">
    Next Day Air Saver
  </Option>,
  <Option value="14" key="14">
    UPS Next Day Air Early
  </Option>,
  <Option value="59" key="59">
    2nd Day Air A.M
  </Option>,
  <Option value="75" key="75">
    UPS Heavy Goods
  </Option>,
];

export default ReturnTemplate;
