/**
 * Component for our Manual-Returns feature
 * ("Multichannel Return") that allows the user
 * to add a Product to the Manual-Return.
 * 
 * @component
 */

import React, { Component } from "react";
import { Form, Input, Row, Col, Select, Modal, message } from "antd";

const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;

class ManualReturnProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
            
    };
  }

    handleCountryChange = (countryCode) => {
      this.setState({
        shippingCountryCode: countryCode,
      });
    };

    handleChange = () => {

    }

    handleSubmit = (e) => {
      e.preventDefault();
      this.props.form.validateFields( async (err, values) => {
        if (err) {
          return message.error("Error. Please check the data you entered.", 5);
        }
        this.props.addProduct(values);
        this.props.setModalAddProductVisible(false);
      });
    }

    render() {
      const { getFieldDecorator } = this.props.form;

      return(
        <Modal
          title="Add product to return"
          centered
          visible={this.props.modalAddProductVisible}
          okText="Add"
          onOk={this.handleSubmit.bind(this)}
          onCancel={() => this.props.setModalAddProductVisible(false)}
        >
          <Form>
                                        
            <Row type="flex" justify="start" align="top">
              <Col span={24}>
                <FormItem label="Product">
                  {
                    getFieldDecorator("selectedVariantId", {
                      rules: [{ required: true, message: 'Please add a product to the return.' }],
                    })(
                      <Select
                        placeholder="Select a product"
                        onChange={ this.handleChange.bind(this) }
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        { this.props.products }
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Row type="flex" justify="start" align="top">
              <Col span={24}>
                <FormItem label="Resolution">
                  {
                    getFieldDecorator("selectedResolution", {
                      rules: [
                        { required: true, message: 'Please select a resolution.'  }
                      ]
                    })(
                      <Select>
                        <Option value="refundToExchange">Exchange</Option>
                        <Option value="refundToOriginal">Refund</Option>
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Row type="flex" justify="start" align="top">
              <Col span={24}>
                <FormItem label="Return reason">
                  {
                    getFieldDecorator("selectedReturnReason", {
                      rules: [{ required: true, message: 'Please add a reason for return.' }],
                    })(
                      <Select
                        onChange={ this.handleChange.bind(this) }
                        placeholder="Select a return reason"
                      >
                        { this.props.returnReasonsElements }
                      </Select>
                    )
                  }
                </FormItem>
              </Col>
            </Row>

            <Row type="flex" justify="start" align="top">
              <Col span={24}>
                <FormItem label="Notes">
                  {
                    getFieldDecorator("notes", {
                    })(
                      <TextArea
                        rows={3}
                        placeholder="Optional notes in relation to the product being returned or exchange item."
                      />
                    )
                  }
                </FormItem>
              </Col>
            </Row>
                        
          </Form>
        </Modal>
      )
    }
}
const WrappedManualReturnProduct = Form.create()(ManualReturnProduct);
export default WrappedManualReturnProduct;