/**
 * Form Component for our Return details sidebar that
 * lets users edit the details of a customer like
 * email, name and address.
 *
 * @component
 */

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import {
  frequentlyUsedCountries,
  nonFrequentlyUsedCountries,
  USStateNames,
} from '../../../../constants/constants';

const { Option } = Select;
const FormItem = Form.Item;
// eslint-disable-next-line no-unused-vars
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

class ReturnDetailsCustomerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      loading: false,
      updateDetailsOnShopify: true,
    };
  }

  componentDidMount() {
    this.setState({
      ...this.props.returnObject,
    });
  }

  handleCancel = () => {
    this.props.toggleModal();
  };

  handleCountryChange = (countryCode) => {
    this.setState({
      shippingCountryCode: countryCode,
    });
  };

  toggleUpdateDetailsOnShopify = () => {
    this.setState({
      updateDetailsOnShopify: !this.state.updateDetailsOnShopify,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }

      this.setState({
        loading: true,
      });
      try {
        const formValues = values;
        const response = await axios.put('/api/returns/customer', {
          returnId: this.state.id,
          updateDetailsOnShopify: this.state.updateDetailsOnShopify,
          formValues,
        });

        if (response.data.status === 'error') {
          this.setState({
            loading: false,
          });
          return message.error(response.data.error, 10);
        }

        this.setState({
          loading: false,
        });
        this.props.handleGetReturn();
        this.props.toggleModal();
        return message.success('Customer details updated successfully.', 5);
      } catch (err) {
        this.setState({
          loading: false,
        });
        return message.error(
          'Error updating Customer details. Please try again.',
          5
        );
      }
    });
  };

  getCountriesList = () => {
    let countriesList = [];
    frequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option value={country.code}>{country.name}</Option>);
    });

    const disabledoption = (
      <Option value="disabled" disabled={true}>
        ---------
      </Option>
    );
    countriesList.push(disabledoption);
    nonFrequentlyUsedCountries.forEach((country) => {
      countriesList.push(<Option value={country.code}>{country.name}</Option>);
    });

    return countriesList;
  };

  getUSStateList = () => {
    const USStateList = USStateNames.map((state) => {
      return <Option value={state.code}>{state.name}</Option>;
    });

    return USStateList;
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title="Update customer shipping details"
        visible={true}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel} className="u-marginTop--xs">
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.loading}
            onClick={this.handleSubmit}
            className="u-marginTop--xs"
          >
            Update
          </Button>,
        ]}
      >
        <Form>
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            className="dashboard-content"
          >
            <Col span={24}>
              <h4>Email Address</h4>
              <FormItem>
                {getFieldDecorator('customerEmail', {
                  initialValue: this.state.customerEmail,
                  rules: [
                    {
                      required: true,
                      message: "Please add the customer's email",
                    },
                  ],
                })(
                  <Input
                    placeholder="For delivering instructions via email"
                    className="u-marginBottom--sm"
                  />
                )}
              </FormItem>
              <h4>First name</h4>
              <FormItem>
                {getFieldDecorator('shippingFirstName', {
                  initialValue: this.state.shippingFirstName,
                  rules: [
                    {
                      required: true,
                      message: "Please add the customer's first name",
                    },
                  ],
                })(<Input className="u-marginBottom--sm" />)}
              </FormItem>
              <h4>Last name</h4>
              <FormItem>
                {getFieldDecorator('shippingLastName', {
                  initialValue: this.state.shippingLastName,
                  rules: [
                    {
                      required: true,
                      message: "Please add the customer's last name",
                    },
                  ],
                })(<Input className="u-marginBottom--sm" />)}
              </FormItem>
              <h4>Company</h4>
              <FormItem>
                {getFieldDecorator('shippingCompany', {
                  initialValue: this.state.shippingCompany,
                })(
                  <Input
                    placeholder="(optional)"
                    className="u-marginBottom--sm"
                  />
                )}
              </FormItem>
              <h4>Street address</h4>
              <FormItem>
                {getFieldDecorator('shippingAddress1', {
                  initialValue: this.state.shippingAddress1,
                  rules: [
                    {
                      required: true,
                      message: "Please add the customer's street address",
                    },
                  ],
                })(
                  <Input
                    placeholder="Street and number, P.O. box, c/o."
                    className="u-marginBottom--sm"
                  />
                )}
              </FormItem>
              <h4>Apartment, suite, unit, building, floor, etc. (optional)</h4>
              <FormItem>
                {getFieldDecorator('shippingAddress2', {
                  initialValue: this.state.shippingAddress2,
                })(
                  <Input
                    placeholder="Apartment, suite, unit, building, floor, etc."
                    className="u-marginBottom--sm"
                  />
                )}
              </FormItem>
              <h4>City</h4>
              <FormItem>
                {getFieldDecorator('shippingCity', {
                  initialValue: this.state.shippingCity,
                  rules: [
                    {
                      required: true,
                      message: "Please add the customer's city",
                    },
                  ],
                })(<Input className="u-marginBottom--sm" />)}
              </FormItem>
              <h4>ZIP code</h4>
              <FormItem>
                {getFieldDecorator('shippingZip', {
                  initialValue: this.state.shippingZip,
                  rules: [
                    {
                      required: true,
                      message: "Please add the customer's zip code",
                    },
                  ],
                })(<Input className="u-marginBottom--sm" />)}
              </FormItem>
              <h4>Phone number</h4>
              <FormItem>
                {getFieldDecorator('shippingPhone', {
                  initialValue: this.state.shippingPhone,
                })(
                  <Input
                    placeholder="(optional) May be used to assist delivery"
                    className="u-marginBottom--sm"
                  />
                )}
              </FormItem>
              <h4>Country</h4>
              <FormItem>
                {getFieldDecorator('shippingCountryCode', {
                  initialValue: this.state.shippingCountryCode,
                  rules: [
                    {
                      required: true,
                      message: 'Please choose the country of the customer',
                    },
                  ],
                })(
                  <Select
                    placeholder="Select country"
                    onChange={this.handleCountryChange.bind(this)}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="u-marginBottom--sm"
                  >
                    {this.getCountriesList()}
                  </Select>
                )}
              </FormItem>
              <h4>State</h4>
              {this.state.shippingCountryCode !== 'US' ? (
                <FormItem>
                  {getFieldDecorator('shippingProvinceCode', {
                    initialValue: this.state.shippingProvinceCode,
                  })(<Input placeholder="(optional)" />)}
                </FormItem>
              ) : (
                <FormItem>
                  {getFieldDecorator('shippingProvinceCode', {
                    initialValue: this.state.shippingProvinceCode,
                    rules: [
                      { required: true, message: 'Please choose a state' },
                    ],
                  })(
                    this.state.shippingCountryCode === 'US' ? (
                      <Select
                        placeholder="State / Province / Region"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        className="u-marginBottom--sm"
                      >
                        {this.getUSStateList()}
                      </Select>
                    ) : (
                      <Select
                        placeholder="State / Province / Region"
                        onChange={this.handleChange.bind(this)}
                      >
                        <Option value="" key="disabled" disabled>
                          State only needed for US based customers.
                        </Option>
                      </Select>
                    )
                  )}
                </FormItem>
              )}
              <FormItem>
                {getFieldDecorator('updateDetailsOnShopify')(
                  <React.Fragment>
                    <Checkbox
                      checked={this.state.updateDetailsOnShopify}
                      onChange={this.toggleUpdateDetailsOnShopify}
                    >
                      Update customer shipping details in Shopify order
                    </Checkbox>
                  </React.Fragment>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const WrappedReturnDetailsCustomerForm = Form.create()(
  ReturnDetailsCustomerForm
);
export default WrappedReturnDetailsCustomerForm;
