/**
 * Component for staff/user E-Mail settings page in the Dashboard.
 *
 * @component
 */

import React from 'react';
import axios from 'axios';
import {
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Divider,
  message,
} from 'antd';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { PageButtonHeader } from '../../../../components/CustomHeader';

const FormItem = Form.Item;

class EmailAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      notificationAdminEnabled: undefined,
      notificationErrorEnabled: undefined,
      notificationErrorEmail: undefined,
      notificationAdminEmail: undefined,
      notificationAdminCopyEnabled: undefined,
      notificationAdminCopyEmailAddress: undefined,
      emailReport: undefined,
      trackingChange: {
        newReturnChange: false,
        copyOfCustomerNotificationsChange: false,
        errorNotificationsChange: false,
      },
      loading: true,
    };
  }

  componentDidMount = async () => {
    this.initializeComponent();
  };

  initializeComponent = async () => {
    try {
      await this.fetchEmailSettings();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error loading email settings. Please try again.', 4);
    }
  };

  fetchEmailSettings = async () => {
    try {
      const response = await axios.get('/api/emailSettings/admin');
      if (response.data.status === 'error') {
        return message.error(
          'There was an error loading your email settings. Try again.',
          4
        );
      }
      return this.setState({
        ...response.data,
      });
    } catch (err) {
      return message.error(
        'There was an error loading your email settings. Try again.',
        4
      );
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error('Error saving your email settings. Try again.', 4);
      }

      const { notificationAdminEnabled, notificationAdminEmail } = this.state;
      if (
        notificationAdminEnabled &&
        (notificationAdminEmail == null || notificationAdminEmail === '')
      ) {
        return message.error('Please enter a valid email address.', 4);
      }

      const { notificationErrorEnabled, notificationErrorEmail } = this.state;
      if (
        notificationErrorEnabled &&
        (notificationErrorEmail == null || notificationErrorEmail === '')
      ) {
        return message.error('Please enter a valid email address.', 4);
      }

      try {
        const response = await axios.post(
          '/api/emailSettings/admin/update',
          values
        );
        if (response.data.status === 'error') {
          return message.error(
            'There was an error saving your email settings. Try again.',
            4
          );
        }
        if (this.state.trackingChange?.newReturnChange) {
          trackFSEvent('Edit new return', {
            feature: 'Email settings',
            enableNewReturn:
              values.notificationAdminEnabled ||
              this.state.notificationAdminEnabled ||
              false,
            email:
              values.notificationAdminEmail ||
              this.state.notificationAdminEmail ||
              '',
          });
        }
        if (this.state.trackingChange?.copyOfCustomerNotificationsChange) {
          trackFSEvent('Edit copy of customer notifications', {
            feature: 'Email settings',
            enableCopyOfCustomerNotifications:
              values.notificationAdminCopyEnabled ||
              this.state.notificationAdminCopyEnabled ||
              false,
            email:
              values.notificationAdminCopyEmailAddress ||
              this.state.notificationAdminCopyEmailAddress ||
              '',
          });
        }
        if (this.state.trackingChange?.errorNotificationsChange) {
          trackFSEvent('Edit error notification', {
            feature: 'Email settings',
            enableCopyOfCustomerNotifications:
              values.notificationErrorEnabled ||
              this.state.notificationErrorEnabled ||
              false,
            email:
              values.notificationErrorEmail ||
              this.state.notificationErrorEmail ||
              '',
          });
        }
        message.success('E-Mail settings updated successfully.', 4);
      } catch (err) {
        return message.error(
          'There was an error saving your email settings. Try again.',
          4
        );
      }
    });
  };

  onChange = (e) => {
    const target = e.target.id;
    const newReturnChange = [
      'notificationAdminEnabled',
      'notificationAdminEmail',
    ].includes(target);
    const copyOfCustomerNotificationsChange = [
      'notificationAdminCopyEnabled',
      'notificationAdminCopyEmailAddress',
    ].includes(target);
    const errorNotificationsChange = [
      'notificationErrorEnabled',
      'notificationErrorEmail',
    ].includes(target);
    this.setState({
      [target]: e.target.checked,
      trackingChange: {
        ...this.state.trackingChange,
        newReturnChange,
        copyOfCustomerNotificationsChange,
        errorNotificationsChange,
      },
    });
  };

  onChangeEmail = (e) => {
    const target = e.target.id;
    const newReturnChange = [
      'notificationAdminEnabled',
      'notificationAdminEmail',
    ].includes(target);
    const copyOfCustomerNotificationsChange = [
      'notificationAdminCopyEnabled',
      'notificationAdminCopyEmailAddress',
    ].includes(target);
    const errorNotificationsChange = [
      'notificationErrorEnabled',
      'notificationErrorEmail',
    ].includes(target);
    this.setState({
      [target]: e.target.value,
      trackingChange: {
        ...this.state.trackingChange,
        newReturnChange,
        copyOfCustomerNotificationsChange,
        errorNotificationsChange,
      },
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit} hideRequiredMark={true}>
          <PageButtonHeader headingTitle={'Staff emails'}></PageButtonHeader>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Return contact email</h4>
              <span>
                Specify the email address for return inquiries. You can override
                the default email if needed.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Recipient address</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem {...formItemLayout}>
                    {getFieldDecorator('notificationAdminEmail', {
                      initialValue: this.state.notificationAdminEmail,
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Enter an email address"
                        onChange={this.onChangeEmail.bind(this)}
                        disabled={
                          this.state && !this.state.notificationAdminEnabled
                        }
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout}>
                    {getFieldDecorator(
                      'notificationAdminEnabled',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.notificationAdminEnabled}
                        onChange={this.onChange.bind(this)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Report recipient email</h4>
              <span>
                Designate the email address to receive all reports and exports.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Recipient address</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%' }}
                >
                  <FormItem {...formItemLayout}>
                    {getFieldDecorator('emailReport', {
                      initialValue: this.state.emailReport,
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Enter an email address"
                        onChange={this.onChangeEmail.bind(this)}
                      />
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Customer notification copies</h4>
              <span>
                Opt to receive a copy of all email notifications sent to
                customers.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Recipient address</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem {...formItemLayout}>
                    {getFieldDecorator('notificationAdminCopyEmailAddress', {
                      initialValue:
                        this.state.notificationAdminCopyEmailAddress,
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Enter an email address"
                        onChange={this.onChangeEmail.bind(this)}
                        disabled={
                          this.state && !this.state.notificationAdminCopyEnabled
                        }
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout}>
                    {getFieldDecorator(
                      'notificationAdminCopyEnabled',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.notificationAdminCopyEnabled}
                        onChange={this.onChange.bind(this)}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Divider></Divider>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Error notification alerts</h4>
              <span>
                Get notified whenever a label generation error occurs for your
                returns.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Recipient address</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem {...formItemLayout}>
                    {getFieldDecorator('notificationErrorEmail', {
                      initialValue: this.state.notificationErrorEmail,
                    })(
                      <Input
                        style={{ width: '100%' }}
                        placeholder="Enter an email address"
                        onChange={this.onChangeEmail}
                        disabled={
                          this.state && !this.state.notificationErrorEnabled
                        }
                      />
                    )}
                  </FormItem>
                  <FormItem {...formItemLayout}>
                    {getFieldDecorator(
                      'notificationErrorEnabled',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.notificationErrorEnabled}
                        onChange={this.onChange}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex-row flex-row--noMargin flex-end-xxs u-marginBottom--lg"
            style={{ marginTop: 10 }}
          >
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                disabled={this.state.loading}
                loading={this.state.loading}
                style={{ padding: 'var(--input-padding)' }}
              >
                Save changes
              </Button>
            </FormItem>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedEmailAdmin = Form.create()(EmailAdmin);
export default WrappedEmailAdmin;
