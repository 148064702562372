/**
 * Component for our Photo-Upload feature for staff
 * to review these photos.
 *
 * @component
 */

import { Button, Icon, Modal } from 'antd';
import React, { Component } from 'react';

class ReturnDetailsPhotoUpload extends Component {
  constructor(props) {
    super(props);
    const oldPhotoUploads = this.buildInitPhotoUploads(
      this.props.customerPhotoUrl1,
      this.props.customerPhotoUrl2,
      this.props.customerPhotoUrl3
    );
    this.state = {
      imagesVisible: false,
      currentImage: undefined,
      customerPhotoUrl1: this.props.customerPhotoUrl1,
      customerPhotoUrl2: this.props.customerPhotoUrl2,
      customerPhotoUrl3: this.props.customerPhotoUrl3,
      photoUploads: {
        ...oldPhotoUploads,
        ...this.props.photoUploads,
      },
    };
  }

  // Build initial photo uploads for old logic, the old logic save the photo uploads in the return object (field: customerPhotoUrl1, customerPhotoUrl2, customerPhotoUrl3)
  buildInitPhotoUploads = (
    customerPhotoUrl1,
    customerPhotoUrl2,
    customerPhotoUrl3
  ) => {
    const photoUploads = {};
    if (customerPhotoUrl1 != null) {
      photoUploads['photo1'] = {
        url: customerPhotoUrl1,
        title: 'Photo 1',
      };
    }
    if (customerPhotoUrl2 != null) {
      photoUploads['photo2'] = {
        url: customerPhotoUrl2,
        title: 'Photo 2',
      };
    }
    if (customerPhotoUrl3 != null) {
      photoUploads['photo3'] = {
        url: customerPhotoUrl3,
        title: 'Photo 3',
      };
    }
    return photoUploads;
  };

  // eslint-disable-next-line no-unused-vars
  showImages = (e, returnObject) => {
    const photoUploads = this.state.photoUploads;

    const firstKey = Object.keys(photoUploads)[0];
    const initialImage = photoUploads[firstKey].url;

    this.setState({
      imagesVisible: true,
      currentImage: initialImage,
    });
  };

  setCurrentImage = (e, customerPhotoUrl) => {
    const currentImage = customerPhotoUrl;
    this.setState({
      currentImage,
    });
  };

  closeImageDialog = () => {
    this.setState({
      imagesVisible: false,
    });
  };

  render() {
    const photoUploads = this.state.photoUploads;
    const photoUploadsCount = Object.keys(photoUploads).length;
    const buttonLoadImages = Object.keys(photoUploads).map((key) => {
      return (
        <Button
          key={key}
          disabled={
            this.state.currentImage === photoUploads[key].url ? true : false
          }
          value={photoUploads[key].url}
          onClick={(e) => this.setCurrentImage(e, photoUploads[key].url)}
        >
          {photoUploads[key].title}
        </Button>
      );
    });
    return (
      <React.Fragment>
        {this.props.featureCustomerPhotoUpload ? (
          <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
            <div className="flex-col-xxs-12">
              <strong>Customer photos</strong>
            </div>
            <div className="flex-col-sm-8 flex-col-xxs-12">
              <div>
                {photoUploadsCount === 0 ? (
                  'No photos uploaded'
                ) : (
                  <Button
                    type="ghost"
                    size="small"
                    style={{ padding: 'var(--input-padding)', width: '100%' }}
                    onClick={(e) => this.showImages(e, this.props.returnObject)}
                  >
                    <Icon type="picture" />
                    Show photo(s)
                  </Button>
                )}
                {this.state.imagesVisible ? (
                  <Modal
                    title="Photo(s) uploaded by customer"
                    visible={this.state.imagesVisible}
                    maskClosable={true}
                    onCancel={this.closeImageDialog}
                    centered
                    footer={buttonLoadImages}
                  >
                    <img
                      style={{ maxWidth: '100%', maxHeight: 700 }}
                      src={this.state.currentImage}
                    />
                  </Modal>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ReturnDetailsPhotoUpload;
