/**
 * Component for redirection  to our subscription plans page.
 *
 * @component
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

class UpgradeButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Tooltip title="This feature is not available on your current Plan.">
          <Link
            className="ant-btn ant-btn-danger"
            to="/dashboard/account"
          >
            View Plans
          </Link>
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default UpgradeButton;
