import { Dropdown, Layout, Menu, Tooltip, Typography } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import UseIntercomHook from './intercomHook';
// screens
import UpgradeNotification from '../../components/UpgradeNotification';
import { SubscriptionContext } from '../../contexts/SubscriptionContext';
import { initFullStory } from '../../helpers/fullstory';
import DashboardRouter from '../../routes/Dashboard';
import Navigation from './navigation/Navigation';

//styles
import '../../styles/App.css';

const { Text } = Typography;

const { Header, Content, Sider } = Layout;
let isLocalhost = false;

const menu = (
  <Menu>
    <Menu.Item key="0">
      <a href="/dashboard/logout">Log out</a>
    </Menu.Item>
  </Menu>
);

export class LogoContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLargerThanMd: false,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  handleResize() {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    this.setState({ isLargerThanMd: mediaQuery.matches });
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { onClick, collapsed } = this.props;
    if (!this.state.isLargerThanMd)
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={onClick}
        >
          {collapsed ? (
            <div
              className="IconSVG IconSVG--sm"
              style={{ width: 35, height: 35 }}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 7.99984C5 7.73464 5.10536 7.48024 5.29304 7.2928C5.48048 7.10536 5.73488 7 6.00008 7C9.41408 7 20.5861 7 24.0001 7C24.2653 7 24.5197 7.10536 24.7071 7.2928C24.8948 7.48024 25.0002 7.73464 25.0002 7.99984C25.0002 8.26504 24.8948 8.51944 24.7071 8.70712C24.5197 8.89456 24.2653 8.99992 24.0001 8.99992C20.5861 8.99992 9.41408 8.99992 6.00008 8.99992C5.73488 8.99992 5.48048 8.89456 5.29304 8.70712C5.10536 8.51944 5 8.26504 5 7.99984Z"
                  fill="black"
                />
                <path
                  d="M5 15.0001C5 14.7349 5.10536 14.4805 5.29304 14.293C5.48048 14.1054 5.73488 14 6.00008 14C9.41408 14 20.5861 14 24.0001 14C24.2653 14 24.5197 14.1054 24.7071 14.293C24.8948 14.4805 25.0002 14.7349 25.0002 15.0001C25.0002 15.2653 24.8948 15.5197 24.7071 15.7071C24.5197 15.8948 24.2653 16.0002 24.0001 16.0002C20.5861 16.0002 9.41408 16.0002 6.00008 16.0002C5.73488 16.0002 5.48048 15.8948 5.29304 15.7071C5.10536 15.5197 5 15.2653 5 15.0001Z"
                  fill="black"
                />
                <path
                  d="M5 22.0001C5 21.7349 5.10536 21.4805 5.29304 21.2928C5.48048 21.1054 5.73488 21 6.00008 21C9.41408 21 20.5861 21 24.0001 21C24.2653 21 24.5197 21.1054 24.7071 21.2928C24.8948 21.4805 25.0002 21.7349 25.0002 22.0001C25.0002 22.2653 24.8948 22.5197 24.7071 22.7071C24.5197 22.8946 24.2653 22.9999 24.0001 22.9999C20.5861 22.9999 9.41408 22.9999 6.00008 22.9999C5.73488 22.9999 5.48048 22.8946 5.29304 22.7071C5.10536 22.5197 5 22.2653 5 22.0001Z"
                  fill="black"
                />
              </svg>
            </div>
          ) : (
            <div
              className="IconSVG IconSVG--sm"
              style={{ width: 24, height: 24 }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ marginRight: '-8px', width: '17px' }}
              >
                <path
                  d="M12.2849 10.4871L22.3092 0.36054C22.7897 -0.12018 23.233 -0.12018 23.6395 0.36054C24.0831 0.80406 24.0831 1.26558 23.6395 1.74606L13.7736 12.0003L23.6395 22.3095C24.1198 22.7158 24.1198 23.1593 23.6395 23.6398C23.233 24.1201 22.7897 24.1201 22.3092 23.6398L12.2849 13.5133L2.26057 23.6398C1.78033 24.1201 1.33705 24.1201 0.930492 23.6398C0.450253 23.1593 0.450253 22.7158 0.930492 22.3095L10.7964 12.0003L0.930492 1.74606C0.486972 1.26558 0.486972 0.80406 0.930492 0.36054C1.33705 -0.12018 1.78033 -0.12018 2.26057 0.36054L12.2849 10.4871Z"
                  fill="black"
                />
              </svg>
            </div>
          )}
        </div>
      );
    return (
      <div className="logo-container">
        <div className="logo" />
      </div>
    );
  }
}

class VitallySurveyWidget extends Component {
  componentDidMount() {
    // Load the vitally.js script
  }

  componentWillUnmount() {
    // Remove vitally.js and init scripts when the component is unmounted
    const vitallyScript = document.querySelector(
      'script[src="https://cdn.vitally.io/vitally.js/v1/vitally.js"]'
    );
    if (vitallyScript) document.body.removeChild(vitallyScript);

    const vitallyInitScript = document.querySelectorAll('script');
    vitallyInitScript.forEach((script) => {
      if (script.innerHTML.includes('Vitally.init')) {
        document.body.removeChild(script);
      }
    });
  }

  render() {
    return (
      <div>
        {/* You can place the widget content here */}
        <div id="vitally-survey-widget"></div>
      </div>
    );
  }
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // hoverLatestUpdatesButton: false,
      email: null,
      collapsed: false,
      userName: null,
    };
  }

  static contextType = SubscriptionContext;

  async componentDidMount() {
    try {
      const response = await axios.get('/api/integrations/intercom/getUser');

      if (response) {
        const userData = response.data.userData;

        const userStoreInfo = response.data;

        const email = userStoreInfo.userData.shopifyProfileEmail
          ? userStoreInfo.userData.shopifyProfileEmail
          : userStoreInfo.userData.customer_email;
        this.setState({ email });
        const userName = userStoreInfo.userData.username;
        this.setState({ userName });
        const user_id = userStoreInfo.userData.domain;
        const store_created_at = userStoreInfo.userData.storeCreatedAt;
        const shopify_domain = userStoreInfo.userData.domain
          ? userStoreInfo.userData.domain
          : userStoreInfo.userData.myshopify_domain;
        const shopify_plan = userStoreInfo.userData.plan_name;
        const plan_name = userStoreInfo.subscription
          ? userStoreInfo.subscription.shopifyPlanName
          : '';
        const name = userData.name;
        initFullStory({
          domain: userStoreInfo.userData.domain,
          displayName: name,
          email,
          status_str: 'active', // 'active' or 'uninstalled'
          shopId_str: user_id, // unique identifier of shop (ie 'xxxxx' in xxxxx.myshopify.com or xxxxx.mybigcommerce.com, unique URL)
          platform_str: 'shopify', // platform name (ie 'shopify', 'bigcommerce')
          planName_str: shopify_plan, // platform plan name (ie 'shopify_plus')
          appHubId_str: 'rich-returns', // business unit name (ie 'conversionbear')
          appName_str: 'Rich Returns', // app name (ie 'checkoutbear')
          //new user property more properties
          shopify_plan_str: shopify_plan,
          plan_types: plan_name,
          country: userStoreInfo.userData,
          days_active: userStoreInfo.trackingInfo.daysActive,
          return_number: userStoreInfo.trackingInfo.totalReturns,
          trial_period: userStoreInfo.trackingInfo.trialPeriod,
          trial_days: userStoreInfo.trackingInfo.trialDays,
          add_ons: userStoreInfo?.trackingInfo?.addOns,
          number_of_rules: userStoreInfo?.trackingInfo?.totalRules,
          subscription_interval_time: userStoreInfo?.trackingInfo?.intervalTime
        });
        //intercom
        this.props.boot({
          customAttributes: {
            email,
            user_id,
            store_created_at,
            shopify_domain,
            shopify_plan,
            plan_name,
            apphub_id: 'rich-returns',
            alignment: 'left',
            horizontal_padding: '20',
          },
        });

        const url = window.location.href;
        const urlObject = new URL(url);
        const urlHostname = urlObject.host;

        // this.createIntercomUser(userData);
        // this.createFullStoryUser(userData)

        if (this.context.first_use) {
          this.createIntercomUser(userData);
          this.createFullStoryUser(userData);
        }
        if (urlHostname.includes('localhost')) {
          isLocalhost = true;
        }

        /**
         *  Author: Rohan Mistry
         *  About:  The code below handles the NPS Widget to be displayed using Vitally. It should be triggered based on the configuration defined in Vitally, and will only show if certain conditions are met.
         *          The API data is in the correct format, it uses the user data fetched from Intercom to push the relevant IDs and emails.
         *
         *  TODO:   Fix the Vitally Widget so the configuration is correctly triggered so the widget displays in the dashboard.
         */

        /*const vitallyScript = document.createElement('script');
        vitallyScript.src = 'https://cdn.vitally.io/vitally.js/v1/vitally.js';
        vitallyScript.defer = true;
        document.body.appendChild(vitallyScript);
        vitallyScript.onload = () => {
          const vitallyInitScript = document.createElement('script');
          vitallyInitScript.innerHTML = `
          !(function (n, t, r) {
          for (
          var i = (n[t] = n[t] || []),
            o = function (r) {
              i[r] =
                i[r] ||
                function () {
                  for (var n = [], t = 0; t < arguments.length; t++)
                    n[t] = arguments[t];
                  return i.push([r, n]);
                };
            },
            u = 0,
            c = ["init", "user", "account", "track", "nps"];
          u < c.length;
          u++
        ) {
          o(c[u]);
        }
      })(window, "Vitally");
      Vitally.init(
        "ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmhkV1FpT2lKbE5HTTJaRFJsTWkxbFpERm1MVFExWVdVdFlXTm1NeTA0TkRVNE9UaGlOVGN6TlRraUxDSmhjR2tpT2lJMllUTXdNelpsTkMwMk56TmpMVFExTXpjdE9ETXdPQzAxWVdNM1kyTmlZVFV6T0RJaUxDSnpaV01pT2lKaU5ERmtZbVZtT1NKOS5GSzIzbjV1VVF4RHhZNHNXLXRzQ3R3TnhTZnU0WWJRSThRb1VWSkgyU05zOg=="
      );

      Vitally.account({
        accountId: 'RR_${userData.CompanyId}',
        traits: {
          name: '${userData.name}',
        },
      });

      const triggerReferralModal = (vitallyNpsScore) => {
        setTimeout(() => {
          const referralPopup = document.querySelector(".js-referral-enroll-popup");
          const vitallyNpsSubmitButton = document.querySelector(
            ".Vitally--ScorePicker-submitButton"
          );

          referralPopup.classList.remove("isActive");
          if (vitallyNpsSubmitButton) {
            vitallyNpsSubmitButton.addEventListener("click", function () {
              if (vitallyNpsScore > 7) {
                referralPopup.classList.add("isActive");
              }
            });
          }
        }, 100);
      };

      Vitally.nps("survey", {
        submitText: ({ score }) => {
          triggerReferralModal(score);
          return "SUBMIT MY RESPONSE";
        },
      });

      Vitally.user({
        userId: '${userData.id}',
        accountId: 'RR_${userData.CompanyId}',
        traits: {
          email: '${userData.username}',
          createdAt: '${userData.createdAt}',
        },
      });
    `;
          document.body.appendChild(vitallyInitScript);
        }; */
      }
    } catch (err) {
      console.log('Error getting the user data', err);
    }
  }

  createIntercomUser = async (userData) => {
    try {
      const user_id = userData.domain
        ? userData.domain
        : userData.myshopify_domain;
      if (user_id) {
        const response = await axios.post(
          `/api/messenger/intercom/user/create`,
          { user_id, userData }
        );

        if (response) {
          console.log('Successfully created Intercom user.');
        }
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  createFullStoryUser = async (userData) => {
    try {
      const uid = userData.domain ? userData.domain : userData.myshopify_domain;
      if (uid) {
        const response = await axios.post(
          `/api/messenger/fullstory/user/create`,
          { uid, userData }
        );

        if (response) {
          console.log('Success with updating full story status');
        }
      }
    } catch (error) {
      console.log(
        'Failed to hit update status of full story while uninstall',
        error
      );
    }
  };

  helmatRender = () => {
    const urlObject = new URL(window.location.href);
    const urlpathname = urlObject.pathname;

    return (
      <Helmet>
        <script type="text/javascript">
          {`
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
              'userId': '${this.context?.storeShopifyUrl}',
              'userProject': 'RichReturns',
              'pagePath': '${urlpathname}',
              'pageuserRRLevelTitle': '${
                this.context?.inTrial === true ? true : this.context?.planName
              }',
              'userShopifyLevel': '${this.context?.planTitle}'
            });
          `}
        </script>
        <script type="text/javascript">
          {(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js',
            });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-T5X6QQD5')}
        </script>
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T5X6QQD5" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      </Helmet>
    );
  };

  ContentOverride = ({ children }) => {
    const location = useLocation();

    const pathClassMap = {
      '/dashboard/translations': 'translations-page',
      '/dashboard/email': 'customer-emails-page',
      '/dashboard/email/admin': 'staff-emails-page',
      '/dashboard/brand': 'brand-customizations-page',
      '/dashboard/brand/custom-domain': 'own-domain-page',
      '/dashboard/settings/payment': 'stripe-payment-page',
      '/dashboard/settings/sync': 'shopify-sync-page',
      '/dashboard/settings/refunds': 'refunds-page',
      '/dashboard/settings': 'returns-settings-page',
      '/dashboard/settings/rules': 'advanced-rules-page',
      '/dashboard/settings/translations': 'translations-page',
    };

    const getContentClass = () => pathClassMap[location.pathname] || '';

    return <Content className={getContentClass()}>{children}</Content>;
  };

  // handleMouseEnter = () => {
  //   this.setState({ hoverLatestUpdatesButton: true });
  // };

  // handleMouseLeave = () => {
  //   this.setState({ hoverLatestUpdatesButton: false });
  // };

  render() {
    // const { hoverLatestUpdatesButton } = this.state;
    // const buttonStyle = {
    //   background: hoverLatestUpdatesButton ? '#6FA5A5' : '#619191', // Change color on hover
    //   borderColor: hoverLatestUpdatesButton ? '#6FA5A5' : '#619191',
    // };
    return (
      <React.Fragment>
        {this.context?.companyId && this.helmatRender()}
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-129175672-1"
          ></script>
          <script type="text/javascript">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
      
            gtag('config', 'UA-129175672-1', { 'anonymize_ip': true });
          `}
          </script>
          {!isLocalhost && this.context && this.context.companyId ? (
            <script type="text/javascript">
              {`
                    window._refinerQueue = window._refinerQueue || []; function _refiner(){_refinerQueue.push(arguments);} _refiner('setProject', '69f4fcc0-c1f8-11eb-9def-716bf873030e'); (function(){var a=document.createElement("script");a.type="text/javascript";a.async=!0;a.src="https://js.refiner.io/v001/client.js";var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b)})();
                    
                    _refiner('identifyUser', {
                      id: ${this.context.companyId},
                      companyId: ${this.context.companyId}
                    });
                    `}
            </script>
          ) : null}
        </Helmet>
        <UpgradeNotification />

        <Layout>
          <Header>
            <LogoContainer
              collapsed={this.state.collapsed}
              onClick={() =>
                this.setState({ collapsed: !this.state.collapsed })
              }
            ></LogoContainer>
            <span className="header__trailing">
              <Tooltip placement="bottom" title={'Visit our help center'}>
                <a
                  href="https://support.apphub.com/en/collections/3507240-rich-returns"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="IconSVG IconSVG--sm"
                  style={{ width: 24, height: 24 }}
                >
                  <svg
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9999 0C4.92888 0 0 4.92792 0 10.9999C0 17.071 4.92888 21.9998 10.9999 21.9998C17.071 21.9998 21.9998 17.071 21.9998 10.9999C21.9998 4.92792 17.071 0 10.9999 0ZM10.9999 18.649C10.147 18.649 9.444 17.946 9.444 17.0918C9.444 16.259 10.1138 15.569 10.9409 15.5378C10.9598 15.5369 11.0599 15.5378 11.0599 15.5378C11.886 15.569 12.5558 16.259 12.5558 17.0918C12.5558 17.946 11.8538 18.649 10.9999 18.649ZM12.4668 11.898C12.2779 11.965 12.15 12.1459 12.15 12.3458V13.4369C12.15 14.071 11.635 14.586 10.9999 14.586C10.3649 14.586 9.84984 14.071 9.84984 13.4369V12.347C9.84984 11.1739 10.5938 10.121 11.7 9.72984C12.5369 9.43392 13.0999 8.63784 13.0999 7.74984C13.0999 6.59784 12.1519 5.64984 10.9999 5.64984C9.84888 5.64984 8.90088 6.59784 8.90088 7.74984C8.90088 8.38392 8.38488 8.89896 7.74984 8.89896C7.1148 8.89896 6.6 8.38392 6.60096 7.74888C6.60096 5.33592 8.58696 3.34992 10.9999 3.34992C13.4138 3.34992 15.3998 5.33592 15.3998 7.74984C15.3998 9.60984 14.22 11.2778 12.4668 11.898Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </Tooltip>

              <Tooltip
                placement="bottom"
                title={'Check our latest app updates'}
              >
                <a
                  href="https://www.richreturns.io/updates"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="IconSVG IconSVG--sm"
                  style={{ width: 28, height: 28 }}
                >
                  <svg
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.7334 23.9464C12.6098 23.9641 12.4919 23.9999 12.3825 24.051C12.1917 24.1398 12.0314 24.2732 11.9109 24.4348C11.7899 24.5958 11.7069 24.7873 11.6755 24.9954C11.6572 25.1147 11.656 25.238 11.674 25.3616C11.6839 25.4317 11.6997 25.4999 11.7208 25.5656C12.1579 27.0059 13.4954 27.9995 15.0028 27.9995C16.5103 27.9995 17.8478 27.0059 18.2846 25.5656C18.3059 25.4999 18.3215 25.4317 18.3316 25.3616C18.3496 25.238 18.3484 25.1147 18.3302 24.9954C18.2985 24.7873 18.2157 24.596 18.0947 24.4348C17.9743 24.2732 17.8139 24.1398 17.6231 24.051C17.5137 23.9999 17.3959 23.9641 17.272 23.9464C17.2043 23.9365 17.1367 23.9324 17.0697 23.9339H12.9357C12.869 23.9324 12.8013 23.9365 12.7334 23.9464Z"
                      fill="black"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.7503 4.43794V3.2509C13.7503 2.56114 14.3102 2.00098 15.0002 2.00098C15.6902 2.00098 16.2502 2.56114 16.2502 3.2509V4.43794C20.6506 5.05426 24.0833 8.87458 24.0833 13.4339C24.0833 19.3722 25.2667 21.1515 25.2667 21.1515L25.2473 21.1338C25.3018 21.2341 25.3339 21.2797 25.3339 21.2797C25.6289 21.6563 25.6829 22.1679 25.4736 22.5978C25.2715 23.0123 24.8585 23.2808 24.4003 23.2993L24.3998 23.3003H5.60064C5.12232 23.3003 4.68624 23.0276 4.47672 22.5978C4.26744 22.1679 4.32168 21.6563 4.6164 21.2797C4.6164 21.2797 5.9172 19.4413 5.9172 13.4339C5.9172 8.87458 9.34992 5.05426 13.7503 4.43794Z"
                      fill="black"
                    />
                  </svg>
                </a>
              </Tooltip>

              <Dropdown overlay={menu} trigger={['click']}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <div
                      className="IconSVG IconSVG--md"
                      style={{ width: 30, height: 30 }}
                    >
                      <svg
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.7938 10.732C20.7938 12.388 20.2111 13.7988 19.0456 14.964C17.8804 16.1294 16.4697 16.7121 14.8137 16.7121C13.1577 16.7121 11.747 16.1294 10.5818 14.964C9.41633 13.7988 8.83361 12.388 8.83361 10.732C8.83361 9.07603 9.41633 7.66531 10.5818 6.50011C11.747 5.33467 13.1577 4.75195 14.8137 4.75195C16.4697 4.75195 17.8804 5.33467 19.0456 6.50011C20.2111 7.66531 20.7938 9.07603 20.7938 10.732ZM14.8137 25.1071C12.6671 25.1071 10.9343 24.8692 9.61578 24.394C8.29698 23.9186 7.6377 23.3589 7.6377 22.715C7.6377 21.4423 8.29698 20.3229 9.61578 19.3569C10.9343 18.3909 12.6671 17.908 14.8137 17.908C16.8991 17.908 18.6163 18.3909 19.9658 19.3569C21.3151 20.3229 21.9897 21.4423 21.9897 22.715C21.9897 23.3436 21.3074 23.9032 19.9427 24.394C18.5781 24.8846 16.8683 25.1224 14.8137 25.1071Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    {this.state.userName && (
                      <Text strong className="hide-tablet">
                        {this.state.userName}
                      </Text>
                    )}
                  </div>
                </a>
              </Dropdown>
            </span>
          </Header>
          <Sider
            theme="light"
            breakpoint="md"
            onBreakpoint={(broken) => {
              if (broken) {
                this.setState({ collapsed: true });
              } else {
                this.setState({ collapsed: false });
              }
            }}
            collapsedWidth="0"
            className="SideBar"
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
          >
            <Navigation />
          </Sider>
          <Layout
            style={{
              justifyContent: 'center',
            }}
            className="PageLayout"
          >
            <this.ContentOverride>
              <DashboardRouter />
            </this.ContentOverride>
          </Layout>
        </Layout>
      </React.Fragment>
    );
  }
}

export default UseIntercomHook(Dashboard);
