/**
 * Component to get details for shopify sync.
 *
 * @component
 */

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import { trackFSEvent } from '../../../../helpers/fullstory';
const { Option } = Select;
const FormItem = Form.Item;

class ShopifySync extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      syncReturnCreatedAddOrderTag: undefined,
      syncReturnCreatedAddOrderNote: undefined,
      syncReturnCreatedAddMetafieldReturnNumber: undefined,
      syncLabelCreatedAddMetafieldLabelNumber: undefined,
      syncLabelCreatedAddMetafieldLabelTrackingURL: undefined,
      syncLabelCreatedAddMetafieldLabelFileURL: undefined,
      shopifyReturnDataSync: undefined,
      shopifyReturnDataSyncStatus: 'Approved',
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data == null) {
        this.setState({ loading: false });
        return;
      }
      this.setState({
        ...response.data,
        loading: false,
      });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error retrieving your return settings. Try again.', 5);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      try {
        const response = await axios.post('/api/returnSettings/update', {
          ...values,
        });
        if (response.data.status === 'error') {
          return message.error('Something went wrong. Please try again.', 5);
        }
        trackFSEvent('Change Shopify Sync Settings', {
          feature: 'Shopify Sync',
          ...values,
        });
        this.setState({
          ...response.data,
        });
        return message.success('successfully updated.', 5);
      } catch (err) {
        return message.error('Something went wrong. Please try again.', 5);
      }
    });
  };

  handleChange = (e) => {
    const target = e.target.id;
    this.setState({
      [target]: e.target.checked,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <Row type="flex" align="middle" className="u-marginBottom--lg">
            <Col span={24}>
              <Alert
                message="Important"
                description={
                  <span>
                    The new Shopify API allows us to mark items in an order as
                    “Return in Progress” and “Returned”. We've rolled out this
                    feature and you can activate it by checking the{' '}
                    <b>Enable Shopify Return Tracking</b>.
                  </span>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>
          <PageButtonHeader
            headingTitle={'Shopify sync'}
            headingRightContent={
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ padding: 'var(--input-padding)' }}
                >
                  Save changes
                </Button>
              </FormItem>
            }
          ></PageButtonHeader>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Add a tag to the order</h4>
              <span>
                Add a tag to the order or leave it blank. You can use
                placeholders like ORDER_NAME, RMA_NUMBER, RETURN_METHOD,
                RETURN_ACTION, and DATE_TODAY. Tags over 40 characters will be
                ignored by Shopify.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Order tag</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator('syncReturnCreatedAddOrderTag', {
                      initialValue: this.state.syncReturnCreatedAddOrderTag,
                    })(<Input placeholder="e.g. RETURN STARTED" />)}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Add a note to the order</h4>
              <span>
                Adds or appends a note to the order. Leave empty to not add a
                note. You can use ORDER_NAME, RMA_NUMBER, RETURN_METHOD,
                RETURN_ACTION and DATE_TODAY as placeholders to insert dynamic
                values.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Order note</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator('syncReturnCreatedAddOrderNote', {
                      initialValue: this.state.syncReturnCreatedAddOrderNote,
                    })(
                      <Input placeholder="e.g. Return RMA_NUMBER requested by customer on DATE_TODAY." />
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Add return-number (RMA) as metafield to original order</h4>
              <span>
                The Return-Number (RMA) will be added to the original Shopify
                Order as a Metafield. Using Shopify's Metafield-Viewer, this can
                be displayed directly in the Shopify Order details page.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Add return number to metafield data</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator(
                      'syncReturnCreatedAddMetafieldReturnNumber',
                      {}
                    )(
                      <Checkbox
                        checked={
                          this.state.syncReturnCreatedAddMetafieldReturnNumber
                        }
                        onChange={(e) => this.handleChange(e)}
                      >
                        Return Number
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Add return-label number as metafield to original order</h4>
              <span>
                The return-label number will be added to the original Shopify
                order as a metafield. Using Shopify's Metafield-Viewer, this can
                be displayed directly in the Shopify Order details page.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Add return-label number to metafield data</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator(
                      'syncLabelCreatedAddMetafieldLabelNumber',
                      {}
                    )(
                      <Checkbox
                        checked={
                          this.state.syncLabelCreatedAddMetafieldLabelNumber
                        }
                        onChange={(e) => this.handleChange(e)}
                      >
                        Return-Label number
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>
                Add return-label tracking URL as metafield to original order
              </h4>
              <span>
                The return-label tracking URL will be added to the original
                Shopify Order as a Metafield. Using Shopify's Metafield-Viewer,
                this can be displayed directly in the Shopify Order details
                page.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Add return-label tracking URL to metafield data</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator(
                      'syncLabelCreatedAddMetafieldLabelTrackingURL',
                      {}
                    )(
                      <Checkbox
                        checked={
                          this.state
                            .syncLabelCreatedAddMetafieldLabelTrackingURL
                        }
                        onChange={(e) => this.handleChange(e)}
                      >
                        Return-label tracking
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>
                Add the return-label file URL as metafield to original order
              </h4>
              <span>
                The Return-Label File URL will be added to the original Shopify
                Order as a Metafield. Using Shopify's Metafield-Viewer, this can
                be displayed directly in the Shopify Order details page.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Add return-label file URL to metafield data</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator(
                      'syncLabelCreatedAddMetafieldLabelFileURL',
                      {}
                    )(
                      <Checkbox
                        checked={
                          this.state.syncLabelCreatedAddMetafieldLabelFileURL
                        }
                        onChange={(e) => this.handleChange(e)}
                      >
                        Return-Label file
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Enable Shopify return tracking</h4>
              <span>
                Check this box if you wish to update the status of your order in
                Shopify. This allows for greater visibility on Shopify and it is
                highly recommended.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Enable Shopify return tracking</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator(
                      'shopifyReturnDataSync',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.shopifyReturnDataSync}
                        onChange={(e) => this.handleChange(e)}
                      >
                        Enable tracking
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>When to start the return process</h4>
              <span>
                Choose a status when we start the return on the original order
                in Shopify.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Set status to begin return process</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator('shopifySyncCreateStatus', {
                      initialValue: this.state.shopifySyncCreateStatus,
                    })(
                      <Select disabled={!this.state.shopifyReturnDataSync}>
                        <Option value="Pending">Pending</Option>
                        <Option value="Approved">Approved</Option>
                        <Option value="Shipped">Shipped</Option>
                        <Option value="In Transit">In Transit</Option>
                        <Option value="Received">Received</Option>
                        <Option value="Resolved">Resolved</Option>
                        <Option value="Rejected">Rejected</Option>
                      </Select>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>When to close the return process</h4>
              <span>
                Choose a status when to close the return process on the original
                order in Shopify.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Set status to close the return process</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator('shopifyReturnDataSyncStatus', {
                      initialValue: this.state.shopifyReturnDataSyncStatus,
                    })(
                      <Select disabled={!this.state.shopifyReturnDataSync}>
                        <Option value="Approved">Approved</Option>
                        <Option value="Shipped">Shipped</Option>
                        <Option value="Received">Received</Option>
                        <Option value="Resolved">Resolved</Option>
                      </Select>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Exclude exchange information</h4>
              <span>
                We will not send related information for any of your exchanges
                in Shopify. This means that a return will not be started on
                these orders. We recommend checking this box if you are offering
                Exchanges and you're creating free exchanges.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Enable to exclude exchange information</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator(
                      'shopifySyncExcludeExchange',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.shopifySyncExcludeExchange}
                        onChange={(e) => this.handleChange(e)}
                        disabled={!this.state.shopifyReturnDataSync}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <Row type="flex" align="top">
            <Col span={24}>
              <Divider />
            </Col>
          </Row>
          <div
            className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
            style={{ gap: 20, width: '100%' }}
          >
            <div
              className="flex-col-md-3 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <h4>Exclude store credit information</h4>
              <span>
                We will not send related information for any of your store
                credit in Shopify. This means that a return will not be started
                on these orders. We recommend checking this box if you are
                offering Store credit and you're creating free store credit.
              </span>
            </div>
            <div
              className="flex-col-md-8 flex-col-sm-12"
              style={{ padding: 0, width: '100%' }}
            >
              <div className="ContentPanel">
                <h4>Enable to exclude store credit information</h4>
                <div
                  className="flex-row flex-row--noMargin"
                  style={{ width: '100%', gap: 10 }}
                >
                  <FormItem>
                    {getFieldDecorator(
                      'shopifySyncExcludeStoreCredit',
                      {}
                    )(
                      <Checkbox
                        checked={this.state.shopifySyncExcludeStoreCredit}
                        onChange={(e) => this.handleChange(e)}
                        disabled={!this.state.shopifyReturnDataSync}
                      >
                        Enable
                      </Checkbox>
                    )}
                  </FormItem>
                </div>
              </div>
            </div>
          </div>

          <Row type="flex" justify="end" className="u-marginBottom--md">
            <Col>
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ padding: 'var(--input-padding)' }}
                >
                  Save changes
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const WrappedShopifySync = Form.create()(ShopifySync);
export default WrappedShopifySync;
