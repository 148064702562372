import React, { Component } from "react";
import { Row, Col, Typography, Button  } from "antd";
import "../../../styles/Error404.css";

class Error404 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  redirectToWebsite = () => {
    window.location.href = "https://www.richreturns.io/";
  }

  render() {
    return (
      <>
        <Row style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', direction:"row" }}>
          <Col className="error-404-logo error-404-logo-desktop" md={8} lg={6}>
            <img src="/logos/rich-returns.png" alt="Error 404 Image" />
          </Col>
          <Col xs={20} md={8} lg={6}>
            <Row >
              <img  className="error-404-logo error-404-logo-mobile" src="/logos/rich-returns.png" alt="Error 404 Image" />
            </Row>
            <Row>
              <Typography.Title className="error-title" level={1}>404</Typography.Title>
            </Row>
            <Row>
              <Typography.Title level={2}>UH OH! You're lost.</Typography.Title>
            </Row>
            <Row>
              <Typography.Text>The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</Typography.Text>
            </Row>
            <Row style={{marginTop: '20px'}}>
              <Button className="website-button" onClick={this.redirectToWebsite}>
                Website
              </Button>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}

export default Error404;
