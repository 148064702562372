/**
 * Component for Custom Translations settings page in the Dashboard.
 *
 * @component
 */

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Icon,
  Input,
  message,
  Row,
  Select,
  Tooltip
} from 'antd';
import axios from 'axios';
import React from 'react';
import { PageButtonHeader } from '../../../../components/CustomHeader';
import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import '../../../../styles/App.css';

const { Option } = Select;

class Translations extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: undefined,
      defaultLanguage: undefined,
      languageActive: false,
      languageObject: [],
      referenceObject: {},
      buttonDisabled: true,
      uniqueKey: undefined,
    };
  }

  loadTranslation = (selectedLanguage) => {
    this.setState(
      {
        selectedLanguage,
      },
      async () => {
        try {
          const response = await axios.post('/api/translations', {
            selectedLanguage,
          });

          if (response.data.status === 'error') {
            return message.error('Error loading translations. Try again.', 5);
          }

          const {
            languageActive,
            defaultLanguage,
            languageObject,
            referenceObject,
          } = response.data;
          return this.setState({
            languageActive,
            defaultLanguage,
            languageObject,
            referenceObject,
            buttonDisabled: false,
            uniqueKey: Date.now(),
          });
        } catch (err) {
          return message.error('Error loading translations. Try again.', 5);
        }
      }
    );
  };

  updateTranslation = (e) => {
    const translationId = e.target.id;
    const translation = e.target.value;

    this.setState((prevState) => ({
      referenceObject: {
        ...prevState.referenceObject,
        [translationId]: translation,
      },
    }));
  };

  saveTranslation = async () => {
    try {
      const updatedTranslations = this.state.referenceObject;
      const { languageActive, defaultLanguage, selectedLanguage } = this.state;
      const response = await axios.post('/api/translations/update', {
        updatedTranslations,
        languageActive,
        defaultLanguage,
        selectedLanguage,
      });

      if (response.data.status === 'error') {
        return message.error('Error saving translations. Try again.', 5);
      }
      trackFSEvent('Select language ', {
        feature: 'Translation',
        selectedLanguage,
      });
      if (languageActive) {
        trackFSEvent('Activate language on the returns portal', {
          feature: 'Translation',
          selectedLanguage,
        });
      }
      if (defaultLanguage) {
        trackFSEvent('Set default language', {
          feature: 'Translation',
          selectedLanguage,
        });
      }
      return message.success('Language settings saved.', 5);
    } catch (err) {
      return message.error('Error saving translations. Try again.', 5);
    }
  };
  trackEvents = (values) => {};
  toggleLanguage = (e) => {
    this.setState({
      languageActive: e.target.checked,
    });
  };

  toggleDefaultLanguage = (e) => {
    this.setState({
      defaultLanguage: e.target.checked,
    });
  };

  TranslationList = ({ translations }) => {
    function capitalizeFirstWord(description) {
      return description.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    }

    const groupedTranslations = translations.reduce((translationType, item) => {
      const { description } = item || {};
      const index = description ? capitalizeFirstWord(description) : 'Other';
      if (!translationType[index]) {
        translationType[index] = [];
      }
      translationType[index].push(item);
      return translationType;
    }, {});

    return (
      <div >
        {Object.entries(groupedTranslations).map(
          ([description, items], index) => (
            <div key={index}>
              <h3>{description}</h3>
              <div
                style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}
              >
                <div style={{width:'100%'}}>
                  {items.map((item) => (
                    <div
                      key={item.id}
                      className="flex-row flex-top-xxs flex-between-xxs"
                    >
                      <div className="flex-col-md-3 flex-col-xxs-12">
                        <h4>Original text</h4>
                        <span class="TextBody TextBody--xxxs u-marginBottom--md">
                          <span style={{fontSize:'20px', lineHeight:'14px'}}>“</span>
                          {item.referenceTranslation}
                          <span style={{fontSize:'20px', lineHeight:'14px'}}>”</span>
                        </span>
                      </div>
                      <div className="flex-col-md-9 flex-col-xxs-12">
                        <div className="ContentPanel">
                          <h4>Translated text</h4>
                          <Input
                            placeholder="Enter your translation here"
                            defaultValue={item.translation}
                            id={item.key}
                            key={this.state.uniqueKey}
                            onChange={this.updateTranslation.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="flex-col-xxs-12">
                        <Divider></Divider>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div
                className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                style={{ gap: 20, width: '100%' }}
              >
                <div
                  className="flex-col-md-3 flex-col-sm-12"
                  style={{ padding: 0, width: '100%' }}
                >
                  <h4>Return contact email</h4>
                  <span>
                    Specify the email address for return inquiries. You can
                    override the default email if needed.
                  </span>
                </div>
                <div
                  className="flex-col-md-8 flex-col-sm-12"
                  style={{ padding: 0, width: '100%' }}
                >
                  <div className="ContentPanel">
                    <h4>Recipient address</h4>
                  </div>
                </div>
              </div> */}
            </div>
          )
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          className='u-marginBottom--lg'
        > 
          <Button
            type="primary"
            onClick={this.saveTranslation.bind(this)}
            disabled={this.state.buttonDisabled}
          >
            Save changes
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const remainingLanguages = languageCodes.map((language) => {
      return (
        <Option value={language.code} key={language.code}>
          {language.name}
        </Option>
      );
    });

    return (
      <React.Fragment>
        <PageButtonHeader
          headingTitle={'Translations'}
          headingRightContent={
            <Button
              type="primary"
              onClick={this.saveTranslation.bind(this)}
              disabled={this.state.buttonDisabled}
            >
              Save changes
            </Button>
          }
        ></PageButtonHeader>
        <Row type="flex" justify="space-around" align="top">
          <Col>
            <Alert
              message="How do Translations work?"
              description={
                <span>
                  You can find a detailed explanation of our Translations{' '}
                  <a
                    href="https://support.apphub.com/en/articles/6322043-translations-default-language"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here in our Docs.
                  </a>{' '}
                  By default we have translations for the following languages
                  already built in: Croatian, Danish, Dutch, English, Finnish,
                  French (beta), German, Hindi (beta), Italian, Norwegian,
                  Polish, Slovenian, Spanish (beta), Swedish (beta)
                  <br />
                  <br />
                  <strong>
                    Looking to customize a Translation or only enable certain
                    Languages?
                  </strong>{' '}
                  <br /> See further down below on this page how our
                  Language-Customization Feature works.
                </span>
              }
              type="info"
              showIcon
            />
          </Col>
        </Row>
        <Row type="flex" justify="space-around" align="top">
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        {this.context.featureLanguageCustomization ? (
          <React.Fragment>
            <div>
              <div className="ContentPanel">
                <Row style={{ marginBottom: 10 }}>
                  <h3>Select translation language</h3>
                </Row>
                <Row type="flex">
                  <Col style={{ width: '100%' }}>
                    <Select
                      style={{ width: '100%',maxWidth:'480px' }}
                      placeholder="Select language"
                      onChange={this.loadTranslation.bind(this)}
                    >
                      <Option value="disabled" key="disabled" disabled={true}>
                        --- Languages with existing translations
                      </Option>
                      <Option value="hr" key="hr">
                        Croatian
                      </Option>
                      <Option value="da" key="da">
                        Danish
                      </Option>
                      <Option value="nl" key="nl">
                        Dutch
                      </Option>
                      <Option value="en" key="en">
                        English
                      </Option>
                      <Option value="fi" key="fi">
                        Finnish
                      </Option>
                      <Option value="fr" key="fr">
                        French
                      </Option>
                      <Option value="de" key="de">
                        German
                      </Option>
                      <Option value="hi" key="hi">
                        Hindi
                      </Option>
                      <Option value="it" key="it">
                        Italian
                      </Option>
                      <Option value="no" key="no">
                        Norwegian
                      </Option>
                      <Option value="pl" key="pl">
                        Polish
                      </Option>
                      <Option value="sl" key="sl">
                        Slovenian
                      </Option>
                      <Option value="es" key="es">
                        Spanish
                      </Option>
                      <Option value="sv" key="sv">
                        Swedish
                      </Option>
                      <Option value="disabled2" key="disabled2" disabled={true}>
                        --- Languages without existing translations
                      </Option>
                      {remainingLanguages}
                    </Select>
                  </Col>
                </Row>

                <Row type="flex" align="middle" style={{ paddingTop: 20 }}>
                  <Row
                    type="flex"
                    style={{
                      columnGap: '10px',
                      flexFlow: 'nowrap',
                    }}
                  >
                    <div>
                      <Checkbox
                        checked={this.state.languageActive}
                        onChange={this.toggleLanguage.bind(this)}
                      >Activate this language on the returns portal</Checkbox>
                    </div>
                  </Row>
                </Row>

                <Row type="flex" align="middle" style={{ paddingTop: 20 }}>
                  <Row
                    type="flex"
                    style={{
                      columnGap: '10px',
                      flexFlow: 'nowrap',
                    }}
                  >
                    <Checkbox
                      checked={this.state.defaultLanguage}
                      onChange={this.toggleDefaultLanguage.bind(this)}
                    ><div className="flex-row flex-row--noMargin flex-middle-xxs u-rowGap--sm u-columnGap--sm">
                        Make this the default language
                        <Tooltip
                          placement="bottom"
                          title="This language will be used in all cases where another language is not available. If you activate only one language and make it the default language then this language will always be shown."
                        >
                          <Icon type="info-circle"></Icon>
                        </Tooltip>
                      </div>
                    </Checkbox>
                  </Row>
                </Row>
              </div>
              <Row type="flex" style={{ marginTop: 30, justifyContent:'flex-end' }}>
                {/* <Col style={{ width: '100%' }}>
                  <List
                    // header={
                    //   <Button
                    //     type="primary"
                    //     onClick={this.saveTranslation.bind(this)}
                    //     disabled={this.state.buttonDisabled}
                    //   >
                    //     Save changes
                    //   </Button>
                    // }
                    footer={
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={this.saveTranslation.bind(this)}
                          disabled={this.state.buttonDisabled}
                        >
                          Save changes
                        </Button>
                      </div>
                    }
                    bordered
                    dataSource={this.state.languageObject}
                    renderItem={(item) => (
                      <List.Item key={item.key} value={item.id}>
                        <Tag style={{ marginBottom: 5 }}>
                          {item.description}
                        </Tag>
                        <br />
                        <Typography.Text mark>
                          Original English version:{' '}
                        </Typography.Text>{' '}
                        <span style={{ marginLeft: 5 }}>
                          <strong>{item.referenceTranslation}</strong>
                        </span>
                        <Input
                          placeholder="Enter your translation here"
                          defaultValue={item.translation}
                          id={item.key}
                          key={this.state.uniqueKey}
                          onChange={this.updateTranslation.bind(this)}
                          style={{ marginTop: 5 }}
                          maxLength={240}
                        />
                      </List.Item>
                    )}
                  />
                </Col> */}
                <this.TranslationList
                  translations={this.state.languageObject}
                />
              </Row>
            </div>
          </React.Fragment>
        ) : (
          <Upgrade
            videoId="KFgs3uP6vrQ"
            title="Put your brand's voice first when it comes to customer satisfaction."
            description="Upgrade to the Pro Plan to customize text on your returns portal and enable or disable languages as you like."
          />
        )}
      </React.Fragment>
    );
  }
}

const languageCodes = [
  { code: 'ab', name: 'Abkhaz', nativeName: 'аҧсуа' },
  { code: 'aa', name: 'Afar', nativeName: 'Afaraf' },
  { code: 'af', name: 'Afrikaans', nativeName: 'Afrikaans' },
  { code: 'ak', name: 'Akan', nativeName: 'Akan' },
  { code: 'sq', name: 'Albanian', nativeName: 'Shqip' },
  { code: 'am', name: 'Amharic', nativeName: 'አማርኛ' },
  { code: 'ar', name: 'Arabic', nativeName: 'العربية' },
  { code: 'an', name: 'Aragonese', nativeName: 'Aragonés' },
  { code: 'hy', name: 'Armenian', nativeName: 'Հայերեն' },
  { code: 'as', name: 'Assamese', nativeName: 'অসমীয়া' },
  { code: 'av', name: 'Avaric', nativeName: 'авар мацӀ, магӀарул мацӀ' },
  { code: 'ae', name: 'Avestan', nativeName: 'avesta' },
  { code: 'ay', name: 'Aymara', nativeName: 'aymar aru' },
  { code: 'az', name: 'Azerbaijani', nativeName: 'azərbaycan dili' },
  { code: 'bm', name: 'Bambara', nativeName: 'bamanankan' },
  { code: 'ba', name: 'Bashkir', nativeName: 'башҡорт теле' },
  { code: 'eu', name: 'Basque', nativeName: 'euskara, euskera' },
  { code: 'be', name: 'Belarusian', nativeName: 'Беларуская' },
  { code: 'bn', name: 'Bengali', nativeName: 'বাংলা' },
  { code: 'bh', name: 'Bihari', nativeName: 'भोजपुरी' },
  { code: 'bi', name: 'Bislama', nativeName: 'Bislama' },
  { code: 'bs', name: 'Bosnian', nativeName: 'bosanski jezik' },
  { code: 'br', name: 'Breton', nativeName: 'brezhoneg' },
  { code: 'bg', name: 'Bulgarian', nativeName: 'български език' },
  { code: 'my', name: 'Burmese', nativeName: 'ဗမာစာ' },
  { code: 'ca', name: 'Catalan; Valencian', nativeName: 'Català' },
  { code: 'ch', name: 'Chamorro', nativeName: 'Chamoru' },
  { code: 'ce', name: 'Chechen', nativeName: 'нохчийн мотт' },
  {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
    nativeName: 'chiCheŵa, chinyanja',
  },
  { code: 'zh', name: 'Chinese', nativeName: '中文 (Zhōngwén), 汉语, 漢語' },
  { code: 'cv', name: 'Chuvash', nativeName: 'чӑваш чӗлхи' },
  { code: 'kw', name: 'Cornish', nativeName: 'Kernewek' },
  { code: 'co', name: 'Corsican', nativeName: 'corsu, lingua corsa' },
  { code: 'cr', name: 'Cree', nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  { code: 'cs', name: 'Czech', nativeName: 'česky, čeština' },
  { code: 'dv', name: 'Divehi; Dhivehi; Maldivian;', nativeName: 'ދިވެހި' },
  { code: 'eo', name: 'Esperanto', nativeName: 'Esperanto' },
  { code: 'et', name: 'Estonian', nativeName: 'eesti, eesti keel' },
  { code: 'ee', name: 'Ewe', nativeName: 'Eʋegbe' },
  { code: 'fo', name: 'Faroese', nativeName: 'føroyskt' },
  { code: 'fj', name: 'Fijian', nativeName: 'vosa Vakaviti' },
  {
    code: 'ff',
    name: 'Fula; Fulah; Pulaar; Pular',
    nativeName: 'Fulfulde, Pulaar, Pular',
  },
  { code: 'gl', name: 'Galician', nativeName: 'Galego' },
  { code: 'ka', name: 'Georgian', nativeName: 'ქართული' },
  { code: 'el', name: 'Greek, Modern', nativeName: 'Ελληνικά' },
  { code: 'gn', name: 'Guaraní', nativeName: 'Avañeẽ' },
  { code: 'gu', name: 'Gujarati', nativeName: 'ગુજરાતી' },
  { code: 'ht', name: 'Haitian; Haitian Creole', nativeName: 'Kreyòl ayisyen' },
  { code: 'ha', name: 'Hausa', nativeName: 'Hausa, هَوُسَ' },
  { code: 'he', name: 'Hebrew (modern)', nativeName: 'עברית' },
  { code: 'hz', name: 'Herero', nativeName: 'Otjiherero' },
  { code: 'ho', name: 'Hiri Motu', nativeName: 'Hiri Motu' },
  { code: 'hu', name: 'Hungarian', nativeName: 'Magyar' },
  { code: 'ia', name: 'Interlingua', nativeName: 'Interlingua' },
  { code: 'id', name: 'Indonesian', nativeName: 'Bahasa Indonesia' },
  {
    code: 'ie',
    name: 'Interlingue',
    nativeName: 'Originally called Occidental; then Interlingue after WWII',
  },
  { code: 'ga', name: 'Irish', nativeName: 'Gaeilge' },
  { code: 'ig', name: 'Igbo', nativeName: 'Asụsụ Igbo' },
  { code: 'ik', name: 'Inupiaq', nativeName: 'Iñupiaq, Iñupiatun' },
  { code: 'io', name: 'Ido', nativeName: 'Ido' },
  { code: 'is', name: 'Icelandic', nativeName: 'Íslenska' },
  { code: 'iu', name: 'Inuktitut', nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
  { code: 'ja', name: 'Japanese', nativeName: '日本語 (にほんご／にっぽんご)' },
  { code: 'jv', name: 'Javanese', nativeName: 'basa Jawa' },
  {
    code: 'kl',
    name: 'Kalaallisut, Greenlandic',
    nativeName: 'kalaallisut, kalaallit oqaasii',
  },
  { code: 'kn', name: 'Kannada', nativeName: 'ಕನ್ನಡ' },
  { code: 'kr', name: 'Kanuri', nativeName: 'Kanuri' },
  { code: 'ks', name: 'Kashmiri', nativeName: 'कश्मीरी, كشميري‎' },
  { code: 'kk', name: 'Kazakh', nativeName: 'Қазақ тілі' },
  { code: 'km', name: 'Khmer', nativeName: 'ភាសាខ្មែរ' },
  { code: 'ki', name: 'Kikuyu, Gikuyu', nativeName: 'Gĩkũyũ' },
  { code: 'rw', name: 'Kinyarwanda', nativeName: 'Ikinyarwanda' },
  { code: 'ky', name: 'Kirghiz, Kyrgyz', nativeName: 'кыргыз тили' },
  { code: 'kv', name: 'Komi', nativeName: 'коми кыв' },
  { code: 'kg', name: 'Kongo', nativeName: 'KiKongo' },
  {
    code: 'ko',
    name: 'Korean',
    nativeName: '한국어 (韓國語), 조선말 (朝鮮語)',
  },
  { code: 'ku', name: 'Kurdish', nativeName: 'Kurdî, كوردی‎' },
  { code: 'kj', name: 'Kwanyama, Kuanyama', nativeName: 'Kuanyama' },
  { code: 'la', name: 'Latin', nativeName: 'latine, lingua latina' },
  {
    code: 'lb',
    name: 'Luxembourgish, Letzeburgesch',
    nativeName: 'Lëtzebuergesch',
  },
  { code: 'lg', name: 'Luganda', nativeName: 'Luganda' },
  {
    code: 'li',
    name: 'Limburgish, Limburgan, Limburger',
    nativeName: 'Limburgs',
  },
  { code: 'ln', name: 'Lingala', nativeName: 'Lingála' },
  { code: 'lo', name: 'Lao', nativeName: 'ພາສາລາວ' },
  { code: 'lt', name: 'Lithuanian', nativeName: 'lietuvių kalba' },
  { code: 'lu', name: 'Luba-Katanga', nativeName: '' },
  { code: 'lv', name: 'Latvian', nativeName: 'latviešu valoda' },
  { code: 'gv', name: 'Manx', nativeName: 'Gaelg, Gailck' },
  { code: 'mk', name: 'Macedonian', nativeName: 'македонски јазик' },
  { code: 'mg', name: 'Malagasy', nativeName: 'Malagasy fiteny' },
  { code: 'ms', name: 'Malay', nativeName: 'bahasa Melayu, بهاس ملايو‎' },
  { code: 'ml', name: 'Malayalam', nativeName: 'മലയാളം' },
  { code: 'mt', name: 'Maltese', nativeName: 'Malti' },
  { code: 'mi', name: 'Māori', nativeName: 'te reo Māori' },
  { code: 'mr', name: 'Marathi (Marāṭhī)', nativeName: 'मराठी' },
  { code: 'mh', name: 'Marshallese', nativeName: 'Kajin M̧ajeļ' },
  { code: 'mn', name: 'Mongolian', nativeName: 'монгол' },
  { code: 'na', name: 'Nauru', nativeName: 'Ekakairũ Naoero' },
  { code: 'nv', name: 'Navajo, Navaho', nativeName: 'Diné bizaad, Dinékʼehǰí' },
  { code: 'nb', name: 'Norwegian Bokmål', nativeName: 'Norsk bokmål' },
  { code: 'nd', name: 'North Ndebele', nativeName: 'isiNdebele' },
  { code: 'ne', name: 'Nepali', nativeName: 'नेपाली' },
  { code: 'ng', name: 'Ndonga', nativeName: 'Owambo' },
  { code: 'nn', name: 'Norwegian Nynorsk', nativeName: 'Norsk nynorsk' },
  { code: 'ii', name: 'Nuosu', nativeName: 'ꆈꌠ꒿ Nuosuhxop' },
  { code: 'nr', name: 'South Ndebele', nativeName: 'isiNdebele' },
  { code: 'oc', name: 'Occitan', nativeName: 'Occitan' },
  { code: 'oj', name: 'Ojibwe, Ojibwa', nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  {
    code: 'cu',
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativeName: 'ѩзыкъ словѣньскъ',
  },
  { code: 'om', name: 'Oromo', nativeName: 'Afaan Oromoo' },
  { code: 'or', name: 'Oriya', nativeName: 'ଓଡ଼ିଆ' },
  { code: 'os', name: 'Ossetian, Ossetic', nativeName: 'ирон æвзаг' },
  { code: 'pa', name: 'Panjabi, Punjabi', nativeName: 'ਪੰਜਾਬੀ, پنجابی‎' },
  { code: 'pi', name: 'Pāli', nativeName: 'पाऴि' },
  { code: 'fa', name: 'Persian', nativeName: 'فارسی' },
  { code: 'ps', name: 'Pashto, Pushto', nativeName: 'پښتو' },
  { code: 'pt', name: 'Portuguese', nativeName: 'Português' },
  { code: 'qu', name: 'Quechua', nativeName: 'Runa Simi, Kichwa' },
  { code: 'rm', name: 'Romansh', nativeName: 'rumantsch grischun' },
  { code: 'rn', name: 'Kirundi', nativeName: 'kiRundi' },
  { code: 'ro', name: 'Romanian, Moldavian, Moldovan', nativeName: 'română' },
  { code: 'ru', name: 'Russian', nativeName: 'русский язык' },
  { code: 'sa', name: 'Sanskrit (Saṁskṛta)', nativeName: 'संस्कृतम्' },
  { code: 'sc', name: 'Sardinian', nativeName: 'sardu' },
  { code: 'sd', name: 'Sindhi', nativeName: 'सिन्धी, سنڌي، سندھی‎' },
  { code: 'se', name: 'Northern Sami', nativeName: 'Davvisámegiella' },
  { code: 'sm', name: 'Samoan', nativeName: 'gagana faa Samoa' },
  { code: 'sg', name: 'Sango', nativeName: 'yângâ tî sängö' },
  { code: 'sr', name: 'Serbian', nativeName: 'српски језик' },
  { code: 'gd', name: 'Scottish Gaelic; Gaelic', nativeName: 'Gàidhlig' },
  { code: 'sn', name: 'Shona', nativeName: 'chiShona' },
  { code: 'si', name: 'Sinhala, Sinhalese', nativeName: 'සිංහල' },
  { code: 'sk', name: 'Slovak', nativeName: 'slovenčina' },
  { code: 'so', name: 'Somali', nativeName: 'Soomaaliga, af Soomaali' },
  { code: 'st', name: 'Southern Sotho', nativeName: 'Sesotho' },
  { code: 'su', name: 'Sundanese', nativeName: 'Basa Sunda' },
  { code: 'sw', name: 'Swahili', nativeName: 'Kiswahili' },
  { code: 'ss', name: 'Swati', nativeName: 'SiSwati' },
  { code: 'ta', name: 'Tamil', nativeName: 'தமிழ்' },
  { code: 'te', name: 'Telugu', nativeName: 'తెలుగు' },
  { code: 'tg', name: 'Tajik', nativeName: 'тоҷикӣ, toğikī, تاجیکی‎' },
  { code: 'th', name: 'Thai', nativeName: 'ไทย' },
  { code: 'ti', name: 'Tigrinya', nativeName: 'ትግርኛ' },
  {
    code: 'bo',
    name: 'Tibetan Standard, Tibetan, Central',
    nativeName: 'བོད་ཡིག',
  },
  { code: 'tk', name: 'Turkmen', nativeName: 'Türkmen, Түркмен' },
  { code: 'tl', name: 'Tagalog', nativeName: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔' },
  { code: 'tn', name: 'Tswana', nativeName: 'Setswana' },
  { code: 'to', name: 'Tonga (Tonga Islands)', nativeName: 'faka Tonga' },
  { code: 'tr', name: 'Turkish', nativeName: 'Türkçe' },
  { code: 'ts', name: 'Tsonga', nativeName: 'Xitsonga' },
  { code: 'tt', name: 'Tatar', nativeName: 'татарча, tatarça, تاتارچا‎' },
  { code: 'tw', name: 'Twi', nativeName: 'Twi' },
  { code: 'ty', name: 'Tahitian', nativeName: 'Reo Tahiti' },
  { code: 'ug', name: 'Uighur, Uyghur', nativeName: 'Uyƣurqə, ئۇيغۇرچە‎' },
  { code: 'uk', name: 'Ukrainian', nativeName: 'українська' },
  { code: 'ur', name: 'Urdu', nativeName: 'اردو' },
  { code: 'uz', name: 'Uzbek', nativeName: 'zbek, Ўзбек, أۇزبېك‎' },
  { code: 've', name: 'Venda', nativeName: 'Tshivenḓa' },
  { code: 'vi', name: 'Vietnamese', nativeName: 'Tiếng Việt' },
  { code: 'vo', name: 'Volapük', nativeName: 'Volapük' },
  { code: 'wa', name: 'Walloon', nativeName: 'Walon' },
  { code: 'cy', name: 'Welsh', nativeName: 'Cymraeg' },
  { code: 'wo', name: 'Wolof', nativeName: 'Wollof' },
  { code: 'fy', name: 'Western Frisian', nativeName: 'Frysk' },
  { code: 'xh', name: 'Xhosa', nativeName: 'isiXhosa' },
  { code: 'yi', name: 'Yiddish', nativeName: 'ייִדיש' },
  { code: 'yo', name: 'Yoruba', nativeName: 'Yorùbá' },
  { code: 'za', name: 'Zhuang, Chuang', nativeName: 'Saɯ cueŋƅ, Saw cuengh' },
];

export default Translations;
