/**
 * Component for our Graph on the volume of Store-Credit.
 * 
 * @component
 */

import React from "react";
import VolumeGraph from "../VolumeGraph";
class StoreCreditVolume extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <VolumeGraph
        url="/api/analytics/store-credit-volume-by-date"
        title="Store Credit Volume"
        tooltip="Shows number of Store-Credit items requested per day."
        config={{
          xField: "date",
          yField: "volume",
          xAxis: { tickCount: 5 },
          yAxis: { tickInterval: 1 },
          smooth: true,
        }}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        updateDate={this.props.updateDate}
      />
    );
  }
}

export default StoreCreditVolume;
