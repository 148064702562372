/**
 * Component for displaying notes that the customer
 * left during the Returns process.
 *
 * @component
 */

import { Input } from 'antd';
import React, { Component } from 'react';

const { TextArea } = Input;

class ReturnDetailsNoteCustomer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.returnObject.notes ? (
          <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
            <div className="flex-col-xxs-12">
              <strong>Customer notes</strong>
            </div>
            <div className="flex-col-xxs-12">
              <TextArea
                autosize
                value={this.props.returnObject.notes}
                placeholder="Customer notes (if available)"
                disabled={true}
              />
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ReturnDetailsNoteCustomer;
