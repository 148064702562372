/**
 * Status: beta / will be deprecated
 * 
 * Component for a Button to load our Refunds
 * and Store-Credit screen that is available
 * in BETA to a few customers.
 * 
 * @component
 */

import React from "react";
import { Button, Modal } from "antd";
import { SubscriptionContext } from "../../../../contexts/SubscriptionContext";
import ReturnDetailsRefund from "./ReturnDetailsRefund";

class ReturnDetailsRefundButton extends React.Component {

  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  
  render() {
    return(
      <React.Fragment>
        {
          (this.context && (!this.props.storeCreditType))
            ? (
              <Button
                onClick={this.showModal}
                type="primary"
                // icon={this.props.showRefundDialog ? "dollar" : "gift"}
                disabled={(!this.props.storeCreditType && !this.props.showRefundDialog)}
                style={{ width: '100%' }}
              >
                {
                  this.props.showRefundDialog
                    ? "Refund"
                    : "Store credit"
                }
              </Button>
            )
            : null
        }
        {
          (this.props.storeCreditType && this.props.storeCreditType === "GIFT_CARD" || this.props.storeCreditType === "DISCOUNT_CODE")
            ? (
              <Button
                onClick={this.showModal}
                type="primary"
                // icon={"gift"}
                style={{width: '100%' }}
              >
                {
                  this.props.storeCreditType === "GIFT_CARD"
                    ? "Gift card": "Discount code"
                }
              </Button>
            )
            : null
        }
        {
          this.state.visible
            ? (
              <Modal
                closable={false}
                visible={this.state.visible}
                onCancel={this.handleCancel.bind(this)}
                footer={[
                  <Button onClick={this.handleCancel.bind(this)} key="close">
                    Close
                  </Button>,
                ]}
              >
                <ReturnDetailsRefund
                  returnId={this.props.returnId}
                  returnObject={this.props.returnObject}
                  showStoreCreditDialog={this.props.showStoreCreditDialog}
                  showRefundDialog={this.props.showRefundDialog}
                  handleGetReturn={this.props.handleGetReturn}
                  handleCancel={this.handleCancel.bind(this)}
                  storeCreditType={this.props.storeCreditType}
                />
              </Modal>
            )
            : null
        }
      </React.Fragment>
    )
  }
}

export default ReturnDetailsRefundButton;