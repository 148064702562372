/**
 * Component to build a redirect URL for the Shopify permission dialog.
 * This includes things like access-scopes we require. Then redirects
 * the user to that URL. If the user has already given this permission
 * they are immediately redirected back to use.
 * 
 * Implementation applies to a Shopify Custom-App.
 * 
 * @component
 */

import React from "react";
import { message } from "antd";
import AuthenticationLoadingScreen from "../../../../../components/AuthenticationLoadingScreen"
import { getAppInfo } from "../../../../../helpers/appInfo";

class ShopifyStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
  }

  async componentDidMount() {
    const nonceToken = "123456";
    const url = window.location.href;
    const urlObject = new URL(url);
    const shopIdentifier = urlObject.searchParams.get("shop") || urlObject.searchParams.get("store");
    const clientId = urlObject.searchParams.get("client_id");
    const urlProtocol = urlObject.protocol;
    const urlHostname = urlObject.host;
    const oAuthRedirectUrlShopify = `${urlProtocol}//${urlHostname}/dashboard/connect/shopifycustom/confirm`;

    let isProductionApp = false;
    let env = null;
    try {
      const info = await getAppInfo();
      env = info.env,
      isProductionApp = info.isProductionApp
    } catch (err) {
      message.error("error fetching clientId of application");
    }

    let scope = "read_orders,read_all_orders,read_products,read_customers,read_draft_orders,write_orders,write_draft_orders,write_inventory,read_gift_cards,write_gift_cards,read_returns,write_returns";

    // adjust access scope for local development
    // Shopify does not allow access to scope 'read_all_orders' on sandbox apps
    if (env === 'development' || !isProductionApp) {
      scope = "read_orders,read_products,read_customers,read_draft_orders,write_orders,write_draft_orders,write_inventory,read_gift_cards,write_gift_cards,read_returns,write_returns";
    }

    const shopifyUrl =
      "https://"
      + shopIdentifier
      + `/admin/oauth/authorize?client_id=${clientId}`
      + `&scope=${scope}`
      + `&redirect_uri=${oAuthRedirectUrlShopify}`
      + `&state=${nonceToken}`

    return window.location.href = shopifyUrl;
  }

  render() {
    return (
      <AuthenticationLoadingScreen />
    );
  }
}

export default ShopifyStore;
