/**
 * Component for our Report showing all Return Reasons
 * selected by customers for recent Returns.
 * 
 * @component
 */

import React from "react";
import Report from "../Report";

const columns = [
  {
    title: "Order Name",
    dataIndex: "Return.orderName",
  },
  {
    title: "Return Number",
    dataIndex: "Return.returnNumber",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
  },
  {
    title: "Variant Name",
    dataIndex: "variantName",
  },
  {
    title: "SKU",
    dataIndex: "sku",
  },
  {
    title: "Reason",
    dataIndex: "returnReason",
  },
];

const exportHeaders = [
  {
    label: "Order Name",
    value: "Return.orderName",
    key: "Return.orderName"
  },
  {
    label: "Return Number",
    value: "Return.returnNumber",
    key: "Return.returnNumber",
  },
  {
    label: "Product Name",
    value: "productName",
    key: "productName"
  },
  {
    label: "Variant Name",
    value: "variantName",
    key: "variantName"
  },
  {
    label: "SKU",
    value: "sku",
    key: "sku"
  },
  {
    label: "Reason",
    value: "returnReason",
    key: "returnReason"
  },
];

class AllReasons extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Report
        url="/api/reports/reasons/all"
        title="All Reasons from Recent Returns"
        tooltip="This report lists all return reasons for products from recent returns in descending order within the selected date range."
        columns={columns}
        reportName="all-reasons"
        exportHeaders={exportHeaders}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        updateDate={this.props.updateDate}
      />
    );
  }
}

export default AllReasons;
