/**
 * Component for Customer Incentives settings
 * that allows merchants to provide incentives to their customers
 * either store credit or discount codes
 *
 * @component
 */

import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
} from 'antd';
import axios from 'axios';
import React from 'react';
import { PageButtonHeader } from '../../../../../components/CustomHeader';
import { IconGift, IconTray } from '../../../../../components/CustomReturnsIcons';
import { SubscriptionContext } from '../../../../../contexts/SubscriptionContext';
import '../../../../../styles/App.css';
import Incentives from './Incentives';
import { trackFSEvent } from '../../../../../helpers/fullstory';
const { Option } = Select;
const FormItem = Form.Item;
class CustomerIncentives extends React.Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      discountCodeResolution: 'storeCredit',
      discountCodeType: 'fixed',
      bonusCreditType: 'fixed',
      bonusCreditValue: 0.0,
      discountCodeValue: 0.0,
      bonusCreditResolution: 'exchangeItem',
      currentIncentive: {},
      data: [],
      resolutionOptions: {
        exchangeItem: 'Exchange',
        storeCredit: 'Store credit',
        storeWideExchange: 'Store-Wide Exchange',
        advancedExchange: 'Advanced Exchange',
      },
      enableCustomerIncentives: false,
      loading: true,
    };
  }

  componentDidMount = async () => {
    this.initializeComponent();
  };

  initializeComponent = async () => {
    try {
      await this.getIncentives();
      await this.getReturnSettings();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error loading customer incentives. Please try again.', 4);
    }
  };

  getReturnSettings = async () => {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data == null) {
        return;
      }
      this.setState({
        enableCustomerIncentives: response.data.enableCustomerIncentives,
      });
    } catch (err) {
      message.error('Error retrieving your return settings. Try again.', 5);
    }
  };

  getIncentives = async () => {
    try {
      const response = await axios.get('/api/returnSettings/getIncentives');
      if (response.data == null) {
        return;
      }
      this.setState({
        data: response.data,
      });
    } catch (err) {
      message.error('Error retrieving your return settings. Try again.', 5);
    }
  };

  getResoulutions = (skipState) => {
    let resolutionOptions = {
      exchangeItem: 'Exchange',
      storeCredit: 'Store Credit',
    };

    if (this.context.featureStoreWideExchange) {
      resolutionOptions = {
        ...resolutionOptions,
        storeWideExchange: 'Store-Wide Exchange',
      };
    }
    if (this.context.featureAdvancedExchanges) {
      resolutionOptions = {
        ...resolutionOptions,
        advancedExchange: 'Advanced Exchange',
      };
    }
    if (!skipState) {
      this.setState({
        resolutionOptions,
      });
    }

    const options = Object.entries(resolutionOptions).map(([key, value]) => (
      <Option key={key} value={key}>
        {value}
      </Option>
    ));
    return options;
  };
  toggleEditMode = (e, record, toggle) => {
    if (!toggle) {
      this.getIncentives();
    }
    this.setState({ currentIncentive: record, editIncentive: toggle });
  };

  deleteIncentive = async (record) => {
    try {
      trackFSEvent('Delete an Incentive', record);
      const response = await axios.post('/api/returnSettings/deleteIncentive', {
        id: record.id,
      });
      if (response.error) {
        return message.error('Error deleting the incentive. Try again.', 3);
      }
      this.getIncentives();
    } catch (err) {
      message.error('Error deleting the incentive. Try again.', 2);
    }
  };

  updateSettings = async () => {
    try {
      const response = await axios.post('/api/returnSettings/update', {
        enableCustomerIncentives: this.state.enableCustomerIncentives,
      });
      if (response.data.status === 'error') {
        return message.error(
          'Error updating your customer incentives. Please try again-----.',
          3
        );
      }
      if (!this.state.enableCustomerIncentives)
        return message.warning('Incentives Disabled', 3);
    } catch (err) {
      message.error('Error while updating customer incentives', 3);
    }
  };

  handleChange = (e) => {
    const target = e.target.name;
    this.setState(
      {
        [target]: e.target.checked,
      },
      () => this.updateSettings()
    );
  };

  render() {
    const columns = [
      {
        title: 'Incentive Type',
        dataIndex: 'incentiveType',
      },
      {
        title: 'Value Type',
        dataIndex: 'incentiveValueType',
        render: (text) => {
          return (
            <span>{text === 'fixed' ? 'Fixed Amount' : 'Percentage'}</span>
          );
        },
      },
      {
        title: 'Incentive Resolution',
        dataIndex: 'incentiveResolution',
        render: (text) => {
          return <span>{this.state.resolutionOptions[text]}</span>;
        },
      },
      {
        title: 'Incentive Value',
        dataIndex: 'incentiveValue',
        render: (text) => parseFloat(text)?.toFixed(2),
      },
      {
        title: 'Timer',
        dataIndex: 'enableTimer',
        render: (value) => {
          return value === true ? <span>ON</span> : <span>OFF</span>;
        },
      },
      {
        title: 'Edit',
        dataIndex: 'edit',
        key: (text, record) => {
          return `edit-${record.id}`;
        },
        render: (text, record) => {
          return (
            <Button
              size="small"
              onClick={(e) => this.toggleEditMode(e, record, true)}
            >
              Edit
            </Button>
          );
        },
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        key: (text, record) => {
          return `delete-${record.id}`;
        },
        render: (text, record) => {
          return (
            <Popconfirm
              title="Are you sure you want to delete this rule?"
              onConfirm={() => this.deleteIncentive(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button size="small">Delete</Button>
            </Popconfirm>
          );
        },
      },
    ];

    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <PageButtonHeader headingTitle="Incentives" />
        {this.context.featureCustomerIncentives ? (
          <React.Fragment>
            <React.Fragment>
              {!this.state.editIncentive && (
                <React.Fragment>
                  {this.context.featureCustomerIncentives && (
                    <React.Fragment>
                      <Row type="flex">
                        <Col span={24}>
                          <div className="ContentPanel">
                            <div className="flex-row flex-middle-xxs u-columnGap--md u-rowGap--lg">
                              <div>
                                <IconGift size="md"></IconGift>
                              </div>
                              <div>
                                <h3>Customer incentives</h3>
                                <p className="u-marginBottom--sm">
                                  Boost your revenue by encouraging customers to
                                  choose store credits and exchanges over
                                  refunds. Learn how to set up Customer
                                  Incentives here
                                </p>
                                <Checkbox
                                  name="enableCustomerIncentives"
                                  checked={this.state.enableCustomerIncentives}
                                  onChange={(e) => this.handleChange(e)}
                                >
                                  Enable incentives
                                </Checkbox>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                  {this.state.enableCustomerIncentives && (
                    <React.Fragment>
                      {/* <Row type="flex" justify="space-around" align="top">
                        <Col span={18}>
                          <Divider />
                        </Col>
                      </Row> */}
                      <div className="ContentPanel">
                        <div style={{ overflowX: 'auto' }}>
                          <div style={{ minWidth: 768 }}>
                            {this.state.resolutionOptions &&
                              Object.keys(this.state.resolutionOptions)
                                ?.length > 0 && (
                              <Table
                                rowKey={(_, index) => index}
                                columns={columns}
                                dataSource={this.state.data}
                                locale={{
                                  emptyText: (
                                    <div className="flex-row flex-middle-xxs flex-center-xxs">
                                      <div className="flex-col-xxs-12">
                                        <IconTray size="md" />
                                      </div>
                                      <div>
                                        <p className="TextBody TextBody--xxxs">No Data</p>
                                      </div>
                                    </div>
                                  ),
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <Row
                        type="flex"
                        justify="end"
                        className="u-marginTop--lg"
                      >
                        <Col>
                          <Button
                            type="primary"
                            onClick={() =>
                              this.setState({ editIncentive: true })
                            }
                          >
                            {' '}
                            Add Incentive
                          </Button>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {this.state.editIncentive && (
                <Incentives
                  currentIncentive={this.state.currentIncentive}
                  getResoulutions={this.getResoulutions}
                  toggleEditMode={this.toggleEditMode}
                />
              )}
            </React.Fragment>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>
              Boost revenue by encouraging customers to choose store credits and
              exchanges instead of refunds
            </p>
            <Row type="flex" justify="space-around" align="top">
              <Col span={24}>
                <Alert
                  message="Already on our Premium or Plus Plan?"
                  description={
                    <React.Fragment>
                      <span>
                        Please reach out to us directly in order to have this
                        feature enabled and set up for your account.
                      </span>
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default CustomerIncentives;
