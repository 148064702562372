/**
 * Component for our Automatic Shipping-Labels
 * for users to view, track and resend them.
 *
 * @component
 */

import {
  Badge,
  Button,
  Dropdown,
  Icon,
  Menu,
  message,
  Popconfirm,
  Tooltip
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import EditCustomerDetails from './ReturnDetailsCustomer';

class ReturnDetailsLabelAutomatic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingRecreateLabel: false,
    };
  }
  recreatePrepaidLabel = async (returnObject) => {
    try {
      this.setState({
        loadingRecreateLabel: true,
      });

      const response = await axios.post('/api/returns/recreateLabel', {
        returnObject,
      });

      if (response.data.status === 'error') {
        this.setState({
          loadingRecreateLabel: false,
        });

        return message.error(
          'Error while attempting to create a new label. Try again.',
          4
        );
      }

      this.setState({
        loadingRecreateLabel: false,
      });
      this.props.handleGetReturn();

      return message.success('Label created successfully.', 5);
    } catch (err) {
      this.setState({
        loadingRecreateLabel: false,
      });
      return message.error(
        'Error while attempting to create a new label. Try again.',
        2
      );
    }
  };

  resendLabelEmail = async (returnObject) => {
    const returnId = returnObject.id;
    try {
      const response = await axios.post('/api/returns/resendLabelEmail', {
        returnId,
      });

      const errorStatus = response.data.status === 'error' ? true : false;
      if (errorStatus) {
        return message.error(
          'There was an error resending the label. Please check the error message and try again.',
          5
        );
      }

      return message.success('Label sent successfully.', 5);
    } catch (err) {
      return message.error(
        'There was an error resending the label. Please check the error message and try again.',
        5
      );
    }
  };
  render() {
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <a href={this.props.returnObject.labelLabelUrl} target="_blank">
            Printable Label
          </a>
        </Menu.Item>
        {this.props.returnObject.labelCommercialInvoiceUrl ? (
          <Menu.Item key="2">
            <a
              href={this.props.returnObject.labelCommercialInvoiceUrl}
              target="_blank"
            >
              Show commercial invoice
            </a>
          </Menu.Item>
        ) : null}
        {this.props.returnObject.labelQRcodeUrl ? (
          <Menu.Item key="3">
            <a
              size="small"
              href={this.props.returnObject.labelQRcodeUrl}
              target="_blank"
            >
              QR-Code Label
            </a>
          </Menu.Item>
        ) : null}
      </Menu>
    );
    return (
      <React.Fragment>
        {this.props.returnObject.labelStatus === 'SUCCESS' ? (
          <React.Fragment>
            <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
              <div className="flex-col-xxs-12">
                <div
                  className="flex-row flex-row--noMargin flex-middle-xxs"
                  style={{ gap: 5 }}
                >
                  <strong>Prepaid-label</strong>
                  <Tooltip
                    placement="bottom"
                    title="Label created & sent to customer"
                  >
                    <Badge status="success" />
                  </Tooltip>
                </div>
              </div>
              <div className="flex-col-xxs-12" style={{ padding: 0 }}>
                <div
                  className="flex-row flex-row--noMargin flex-middle-xxs"
                  style={{ rowGap: 10 }}
                >
                  {this.props.returnObject.labelCommercialInvoiceUrl ||
                  this.props.returnObject.labelQRcodeUrl ? (
                      <div className="flex-col-sm-6 flex-col-xxs-12">
                        <Dropdown overlay={menu}>
                          <Button
                            size="small"
                            style={{
                              padding: 'var(--input-padding)',
                              width: '100%',
                            }}
                            type="ghost"
                          >
                          Download <Icon type="down" />
                          </Button>
                        </Dropdown>
                      </div>
                    ) : (
                      <div className="flex-col-sm-6 flex-col-xxs-12">
                        <Button
                          size="small"
                          href={this.props.returnObject.labelLabelUrl}
                          target="_blank"
                          style={{
                            padding: 'var(--input-padding)',
                            width: '100%',
                          }}
                          type="ghost"
                        >
                        Show label
                        </Button>
                      </div>
                    )}
                  <div className="flex-col-sm-6 flex-col-xxs-12">
                    <Button
                      size="small"
                      href={this.props.returnObject.labelTrackingUrlProvider}
                      target="_blank"
                      style={{ padding: 'var(--input-padding)', width: '100%' }}
                      type="ghost"
                    >
                      Track shipment
                    </Button>
                  </div>
                  <div className="flex-col-sm-6 flex-col-xxs-12">
                    <Popconfirm
                      title="Resend your default email template to the customer that includes the prepaid-label?"
                      onConfirm={() =>
                        this.resendLabelEmail(this.props.returnObject)
                      }
                      okText="Yes"
                      cancelText="Cancel"
                    >
                      <Button
                        size="small"
                        type="ghost"
                        style={{
                          padding: 'var(--input-padding)',
                          width: '100%',
                        }}
                      >
                        Resend label
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        {this.props.returnObject.labelStatus === 'ERROR' ? (
          <React.Fragment>
            <div className="flex-row flex-row--noMargin" style={{ gap: 10 }}>
              <div className="flex-col-xxs-12">
                <div
                  className="flex-row flex-row--noMargin flex-middle-xxs"
                  style={{ gap: 5 }}
                >
                  <strong>Prepaid-label</strong>
                  <Tooltip placement="bottom" title="Error creating label">
                    <Badge status="error" />
                  </Tooltip>
                </div>
              </div>
              <div className="flex-col-xxs-12" style={{ padding: 0 }}>
                <div
                  className="flex-row flex-row--noMargin flex-middle-xxs"
                  style={{ rowGap: 10 }}
                >
                  <div className="flex-col-sm-6 flex-col-xxs-12">
                    <Tooltip
                      placement="bottom"
                      title={
                        this.props.returnObject.labelErrorTransactionSource
                          ? this.props.returnObject
                            .labelErrorTransactionSource +
                            ': ' +
                            this.props.returnObject.labelErrorTransactionText +
                            ' (label could not be created & sent to customer)'
                          : this.props.returnObject.labelErrorTransactionText +
                            ' (label could not be created & sent to customer)'
                      }
                    >
                      <Button
                        size="small"
                        style={{
                          padding: 'var(--input-padding)',
                          width: '100%',
                        }}
                      >
                        View details
                      </Button>
                    </Tooltip>
                  </div>
                  <div className="flex-col-sm-6 flex-col-xxs-12">
                    <Tooltip
                      placement="bottom"
                      title="This will attempt to generate a new label and send the Approved-Email including the label to the customer"
                    >
                      <Popconfirm
                        title="Create a new label and send it to the customer?"
                        onConfirm={() =>
                          this.recreatePrepaidLabel(this.props.returnObject)
                        }
                        okText="Yes"
                        cancelText="Cancel"
                      >
                        <Button
                          style={{
                            padding: 'var(--input-padding)',
                            width: '100%',
                          }}
                          size="small"
                          disabled={this.state.loadingRecreateLabel}
                          loading={this.state.loadingRecreateLabel}
                        >
                          Create new label
                        </Button>
                      </Popconfirm>
                    </Tooltip>
                  </div>
                  <div className="flex-col-sm-6 flex-col-xxs-12">
                    <EditCustomerDetails
                      buttonType="ghost"
                      buttonText="Update address"
                      returnObject={this.props.returnObject}
                      handleGetReturn={this.props.handleGetReturn}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : null}
        {this.props.returnObject.labelRateAmount ? (
          <div className="flex-row flex-row--noMargin">
            <div className="flex-col-xxs-12">
              <div
                className="flex-row flex-row--noMargin"
                style={{ columnGap: 10 }}
              >
                <strong>Actual label cost:</strong>
                {`${this.props.returnObject.labelRateAmount} ${this.props.returnObject.labelRateCurrency}`}
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ReturnDetailsLabelAutomatic;
