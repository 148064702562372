/**
 * Component to update the Shopify OAauth connection on our Connections
 * Page. Component retrieves a redirect URL from the backend and
 * redirects to the user to Shopif's permission dialog.
 * 
 * Usage: whenever we need to update the OAuth connection in terms of
 * additional access scopes. We'd then ask the user to click the relevant
 * button and start the authorization process.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { message } from "antd";
import AuthenticationLoadingScreen from "../../../../../components/AuthenticationLoadingScreen"

class Shopify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);

    const code = urlObject.searchParams.get("code");
    const hmac = urlObject.searchParams.get("hmac");
    const shop = urlObject.searchParams.get("shop");
    const state = urlObject.searchParams.get("state");
    const timestamp = urlObject.searchParams.get("timestamp");
    const host = urlObject.searchParams.get("host");

    if (code == null) {
      return;
    }

    try {
      await axios.post("/api/shop/shopify/auth", {
        code,
        hmac,
        host,
        shop,
        state,
        timestamp,
      });
      this.setState({
        redirect: true,
      });
    } catch (err) {
      message.error("Error connecting your Shopify account.");
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard/connect/platform" />;
    }
    return(
      <AuthenticationLoadingScreen />
    );
  }
}

export default Shopify;
