import React from "react";
import { Route, Switch } from "react-router-dom";

import NewReturn from "../pages/portal/1-NewReturn";
import Order from "../pages/portal/2-Order";
import PhotoUpload from "../pages/portal/3-PhotoUpload";
import AdditionalCustomerInput from "../pages/portal/4-AdditionalInput";
import Method from "../pages/portal/5-ShippingMethod";
import Status from "../pages/portal/6-Status";
import Payment from "../pages/portal/payment";
import PaymentCancel from "../pages/portal/paymentCancel";

//styles
import "../styles/Visitor.css";

import ErrorRedirect from "./ErrorRedirect";

class ReturnPortalRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/return/new"
          render={(props) => (
            <NewReturn
              updateBrandSettings={this.props.updateBrandSettings.bind(this)}
              {...props}
              {...this.props}
            />
          )}
        />
        <Route
          exact={true}
          path="/return/order"
          render={(props) => (
            <Order
              updateBrandSettings={this.props.updateBrandSettings.bind(this)}
              {...this.props}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/return/resolution"
          render={(props) => (
            <PhotoUpload
              updateBrandSettings={this.props.updateBrandSettings.bind(this)}
              {...this.props}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/return/method"
          render={(props) => (
            <Method
              updateBrandSettings={this.props.updateBrandSettings.bind(this)}
              {...this.props}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/return/payment"
          render={(props) => (
            <Payment
              updateBrandSettings={this.props.updateBrandSettings.bind(this)}
              {...this.props}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/return/payment/cancel"
          render={(props)=>
            <PaymentCancel 
              {...this.props}
              {...props}
            />
          }
        />
        <Route
          exact={true}
          path="/return/status"
          render={(props) => (
            <Status
              updateBrandSettings={this.props.updateBrandSettings.bind(this)}
              {...this.props}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/return/info"
          render={(props) => (
            <AdditionalCustomerInput
              updateBrandSettings={this.props.updateBrandSettings.bind(this)}
              {...this.props}
              {...props}
            />
          )}
        />
        <Route path="*" component={ErrorRedirect} /> 
      </Switch>
    );
  }
}

export default ReturnPortalRouter;
