/**
 * Component to welcome the user after they granted us
 * access in Shopify's permission dialog. We then try to
 * initiate OAuth/authentication process and redirect
 * the user to the main Dashboard if successful.
 * 
 * Implementation applies to our Shopify Public-App in the App-Store.
 * 
 * @component
 */

import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Button, Row, Col, message, Card, Result} from 'antd';
import AuthenticationLoadingScreen from "../../../../../components/AuthenticationLoadingScreen"

class AppHubSignin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectAppStore: false,
      subscriptionPending: false,
      redirectionURL: "https://dash.reviews.io/subscription",
      apphubApp: "",
    };
  }

  async componentDidMount() {

    const url = window.location.href;
    const urlObject = new URL(url);
    const email = urlObject.searchParams.get("email");
    const digest = urlObject.searchParams.get("digest");
    const timestamp = urlObject.searchParams.get("timestamp");
    const myshopify_url = urlObject.searchParams.get("myshopify_url");
    const origin_app = urlObject.searchParams.get("origin_app");
    const store_key = urlObject.searchParams.get("store_key")
    const subscriptionPending = urlObject.searchParams.get("subscriptionPending")

    try {
      if (subscriptionPending && origin_app === "reviews-io") {
        return this.setState({subscriptionPending: true, apphubApp: "Reviews.io"})
      }
      const response = await axios.post("/api/integrations/apphub/boltOns/login", {
        email,
        shop: myshopify_url,
        originApp: origin_app,
        timestamp,
        store_key,
      }, {
        headers: {
          'apphub-signature': digest,
          'Content-Type': "application/json",
        },
      });
      if (response.data.message === "subscription-expired") {
        this.setState({
          subscriptionPending: true,
          apphubApp: response.data.originApp
        })
      } else if (response.status === 200) {
        return this.setState({
          redirectAppStore: true,
        });
      }
      
    } catch (err) {
      message.error("Error connecting your Shopify account.", err);
    }
  }

  render() {
    if (this.state.redirectAppStore) {
      return <Redirect to="/dashboard" />;
    }
    if (this.state.subscriptionPending) {
      return(
        <React.Fragment>
          <Card
            title="Pending Subscription"
            className="textCenter"
          >
            <Row type="flex" justify="center" align="top" className="textCenter">
              <Col>
                {
                  <Result
                    status="warning"
                    title={`Please update your subscription from ${this.state.apphubApp}`}
                  />
                }
              </Col>
            </Row>
            <a href={this.state.redirectionURL} target="_blank" style={{color: "white"}}>
              <Button
                type="primary"
                style={{ backgroundColor: this.props.brandColorButton }}
              >
              Proceed
              </Button>
            </a>
          </Card>
        </React.Fragment>
      )
    }
    return (
      <AuthenticationLoadingScreen />
    )
  }
}

export default AppHubSignin;
