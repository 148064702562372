/**
 * Component for our Report showing most-exchanged-from
 * Variants.
 * 
 * @component
 */

import React from "react";
import Report from "../Report";

const columns = [
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName"
  },
  {
    title: "Variant Name",
    dataIndex: "variantName",
    key: "variantName"
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku"
  },
  {
    title: "Count",
    dataIndex: "variantCount",
    key: "variantCount"
  },
];

const exportHeaders = [
  {
    label: "Product Name",
    value: "productName",
  },
  {
    label: "Variant Name",
    value: "variantName",
  },
  {
    label: "SKU",
    value: "sku",
  },
  {
    label: "Count",
    value: "variantCount",
  },
];

class Variants extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Report
        url="/api/reports/exchanged-products/from-variant"
        title="Most Exchanged-From Variants"
        tooltip="This report shows how many times a variant was exchanged within the selected date range."
        columns={columns}
        reportName="most-exchanged-variants"
        exportHeaders={exportHeaders}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        updateDate={this.props.updateDate}
      />
    );
  }
}

export default Variants;
