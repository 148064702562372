/**
 * Component to build a redirect URL for the Shopify permission dialog for Apphub bolt on apps.
 * This includes things like access-scopes we require.
 * If the user has already given this permission
 * they are immediately redirected back to use.
 * 
 * @component
 */

import React from "react";
import AuthenticationLoadingScreen from "../../../../../components/AuthenticationLoadingScreen"
import { getAppInfo } from "../../../../../helpers/appInfo";
class AppHubSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }
  // Here's the url at which Bolt-on app will redirect their user along with the mentioned paramaters
  // https://returns.richcommerce.co/dashboard/connect/apphub/signup?myshopify_url=<SHOPIFY_URL>&email=<USER_EMAIL>&domain=<DOMAIN>&company_name=<COMPANY_NAME>&store_key=<STORE_KEY>
  async componentDidMount() {
    const url = window.location.href;
    const urlObject = new URL(url);
    const myshopify_url = urlObject.searchParams.get("myshopify_url")
    const originApp = urlObject.searchParams.get("origin_app")
    const urlProtocol = urlObject.protocol;
    const urlHostname = urlObject.host;
    const oAuthRedirectUrlShopify = `${urlProtocol}//${urlHostname}/dashboard/connect/apphub/confirm`;
    const email = urlObject.searchParams.get("email")
    const company_name = urlObject.searchParams.get("company_name")
    const store_key = urlObject.searchParams.get("store_key")

    // Creating hash for users data and sending it in state variable of the auth url.
    // In this way we can get this data back in the next step.
    const userData = btoa(JSON.stringify({email, company_name, store_key, originApp}))
    localStorage.setItem(store_key,userData)
    const nonceToken = store_key

    // Default to Public-App
    let clientId = "efdd8904c3999b79312362f88fc31059";
    let isProductionApp = false;
    let env = null;

    try {
      const info = await getAppInfo();
      env = info.env,
      isProductionApp = info.isProductionApp
      clientId = info.clientId
    } catch (err) {
      message.error("error fetching clientId of application");
    }

    let scope = "read_orders,read_all_orders,read_products,read_customers,read_draft_orders,write_orders,write_draft_orders,write_inventory,write_price_rules,read_price_rules,write_discounts,read_discounts,read_gift_cards,write_gift_cards,read_returns,write_returns";

    // adjust access scope for local development
    // Shopify does not allow access to scope 'read_all_orders' on sandbox apps
    if (env === 'development' || !isProductionApp) {
      scope = "read_orders,read_products,read_customers,read_draft_orders,write_orders,write_draft_orders,write_inventory,read_returns,write_returns";
    }

    try {

    } catch (error) {
      message.error("error fetching clientId of application");
    }

    // in Shopify app using partners account for local setup
    
    const shopifyUrl =
      "https://"
      + myshopify_url
      + `/admin/oauth/authorize?client_id=${clientId}&scope=${scope}&redirect_uri=`
      + oAuthRedirectUrlShopify
      + "&state="
      + nonceToken

    return window.location.href = shopifyUrl;
  }

  render() {
    return (
      <AuthenticationLoadingScreen />
    );
  }
}

export default AppHubSignup;
