/**
 * Component for our Report showing all Comments
 * by customers for recent Returns.
 * 
 * @component
 */

import React from "react";
import Report from "../Report";

const columns = [
  {
    title: "Order Name",
    dataIndex: "Return.orderName",
    key: "Return.orderName"
  },
  {
    title: "Return Number",
    dataIndex: "Return.returnNumber",
    key: "Return.returnNumber"
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName"
  },
  {
    title: "Variant Name",
    dataIndex: "variantName",
    key: "variantName"
  },
  {
    title: "SKU",
    dataIndex: "sku",
    key: "sku"
  },
  {
    title: "Comment",
    dataIndex: "notes",
    key: "notes"
  },
];

const exportHeaders = [
  {
    label: "Order Name",
    value: "Return.orderName",
  },
  {
    label: "Return Number",
    value: "Return.returnNumber",
  },
  {
    label: "Product Name",
    value: "productName",
  },
  {
    label: "Variant Name",
    value: "variantName",
  },
  {
    label: "SKU",
    value: "sku",
  },
  {
    label: "Comment",
    value: "notes",
  },
];

class AllComments extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Report
        url="/api/reports/comments/all"
        title="All Comments from Recent Returns"
        tooltip="This report shows customer comments for returned products from recent returns in descending order within the selected date range."
        columns={columns}
        reportName="all-comments"
        exportHeaders={exportHeaders}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        updateDate={this.props.updateDate}
      />
    );
  }
}

export default AllComments;
