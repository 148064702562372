/**
 * Component to manage our  Return Reasons Languages
 *
 * @component
 */

import React, { Component } from 'react'
import {
  Modal,
  Button,
  Divider,
  message,
  Col,
  Row,
  Checkbox,
  Input,
} from 'antd'
import axios from 'axios'
import { trackFSEvent } from '../../../../../helpers/fullstory'

class ReturnNoteCusomize extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      customizeNote: {
        isRequire: false,
        title: '',
      },
    }
  }

  getReturnSetting = async () => {
    try {
      const response = await axios.get('/api/returnSettings')
      if (response?.data) {
        const defaultCustomize = this.state.customizeNote
        this.setState({
          customizeNote: {
            ...defaultCustomize,
            ...response.data.customizeNote,
          },
        })
      }
    } catch (err) {
      return message.error('Something went wrong please try again', 5)
    }
  }

  onChangeRequire = (e) => {
    const customizeNote = {
      ...this.state.customizeNote,
      isRequire: e.target.checked,
    }
    this.setState({
      customizeNote: customizeNote,
    })
  }

  onChangeTitle = (e) => {
    const customizeNote = {
      ...this.state.customizeNote,
      title: e.target.value,
    }
    this.setState({
      customizeNote: customizeNote,
    })
  }

  onSave = async () => {
    const payload = {
      customizeNote: this.state.customizeNote,
    }
    try {
      await axios.post(`/api/returnSettings/updateCustomizeQuery`, payload);
      trackFSEvent('Customize customer journey', payload);
      message.success('Saved successfully', 5)
    } catch (e) {
      return message.error('Something went wrong please try again', 5)
    }
  }

  componentDidMount = async () => {
    this.getReturnSetting()
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          width={this.props.featureMultilangReturnReasons ? 1100 : 720}
          title="Customize customer note"
          visible={this.props.visible}
          // onOk={this.props.onOk}
          onCancel={this.props.onCancel}
          footer={null}
        >
          <Row>
            <Col>
              <Checkbox
                checked={this.state?.customizeNote?.isRequire}
                onChange={this.onChangeRequire}
                defaultValue={true}
              >Require your customer to fill out the notes field.</Checkbox>
            </Col>
          </Row>
          <Row className="u-marginTop--md">
            <p>Change the title of the field</p>
          </Row>
          <Row>
            <Input
              value={this.state?.customizeNote?.title}
              onChange={this.onChangeTitle}
            />
          </Row>
          <Row span={24} type="flex" justify="end" className="u-marginTop--md">
            <Button type="primary" onClick={this.onSave}>
              Save changes
            </Button>
          </Row>
        </Modal>
      </React.Fragment>
    )
  }
}

export default ReturnNoteCusomize
