import React from 'react';

class Page extends React.Component {
  render() {
    const PageComponent = this.props.component;
    document.title = `${this.props.title} | Rich Returns`;
    return <PageComponent {...this.props} defaultActiveKey={this.props.defaultActiveKey}/>;
  }
}

export default Page;
