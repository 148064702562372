/**
 * This component renders a modal that prompts the user to select an industry
 * they operate in upon login. The user can either make a selection or choose
 * to be reminded later.
 * 
 * @component
 */

import { Button, Modal, Select, message } from "antd";
import axios from "axios";
import { Component } from "react";
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';

const { Option } = Select;

class LoginModal extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  handleModalSubmit =  async () => {
    try {
      const { selectedItem } = this.state;

      if (!selectedItem) {
        return message.error("Please make a selection before submitting.", 5);
      }
      const companyId = this.context.companyId
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post("/api/returns/saveStoreIndustry", {
        companyId,
        selectedItem,
      });
      this.props.onClose();
    } catch (error) {
      return message.error("An error occurred while saving your selection.", 5);
    }
  };

  handleModalCancel = () => {
    this.props.onClose();
  };

  onSelectItem = (value) => {
    this.setState({ selectedItem: value });
  };

  render() {
    const { visible } = this.props;
    
    return (
      <Modal
        title={<strong>Welcome Back! <br/> Tell us about your business.</strong>}
        visible={visible}
        onOk={this.handleModalSubmit}
        onCancel={this.handleModalCancel}
        footer={[
          <Button key="remind" onClick={this.handleModalCancel}>
            Remind me later
          </Button>,
          <Button key="submit" type="primary" onClick={this.handleModalSubmit}>
            Submit
          </Button>,
        ]}
      >
        <p>
          Select below the industry segment that best describes your company's main products. This information will help us develop more return flow solutions for you!
        </p>
        <br/>
        <Select
          style={{ width: "100%" }}
          placeholder="Select an industry"
          onChange={this.onSelectItem}
        >
          <Option value="arts-crafts-supplies">Arts & Crafts Supplies</Option>
          <Option value="automotive-parts-accessories">Automotive Parts & Accessories</Option>
          <Option value="baby-kids">Baby & Kids</Option>
          <Option value="beauty-cosmetics">Beauty & Cosmetics</Option>
          <Option value="courses-education">Courses & Education</Option>
          <Option value="books">Books</Option>
          <Option value="electronics-accessories">Electronics & Accessories</Option>
          <Option value="event-tickets">Event Tickets</Option>
          <Option value="fashion-clothing">Fashion Clothing</Option>
          <Option value="fashion-footwear">Fashion Footwear</Option>
          <Option value="fashion-accessories">Fashion Accessories</Option>
          <Option value="furniture">Furniture</Option>
          <Option value="gaming">Gaming</Option>
          <Option value="groceries-food">Groceries & Food</Option>
          <Option value="health-wellness-products">Health & Wellness Products</Option>
          <Option value="home-garden">Home & Garden</Option>
          <Option value="industrial-supplies">Industrial Supplies</Option>
          <Option value="jewelry-watches">Jewelry & Watches</Option>
          <Option value="office-supplies">Office Supplies</Option>
          <Option value="pet-supplies">Pet Supplies</Option>
          <Option value="software-apps">Software & Apps</Option>
          <Option value="sporting-goods">Sporting Goods</Option>
          <Option value="subscription-boxes">Subscription Boxes</Option>
          <Option value="toys-games">Toys & Games</Option>
          <Option value="travel-accommodation">Travel & Accommodation</Option>
        </Select>
      </Modal>
    );
  }
}

export default LoginModal;